<template>
  <main
    class="relative mx-auto max-w-7xl flex-1 py-12 px-4 sm:px-6 lg:py-16 lg:px-8"
  >
    <ComponentHeader component="tags" />
    <div class="mb-12 flex items-end space-x-2">
      <h1
        class="flex items-center space-x-2 text-3xl font-extrabold leading-7 text-kta-blue-900"
      >
        <span>Tag</span>
        <div class="h-4 w-4 text-yellow-500">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              d="M6.26087 0L12.5217 12L6.26087 24H0L6.26087 12L0 0H6.26087ZM11.4783 0L17.7391 12L11.4783 24H17.7391L24 12L17.7391 0H11.4783Z"
              fill="currentColor"
            />
          </svg>
        </div>
        <span>{{ tag.name }}</span>
      </h1>
    </div>
    <div class="flex-1 gap-16 md:grid lg:grid-cols-2 lg:gap-x-5 lg:gap-y-12">
      <article v-for="(post, index) in posts" :key="index" class="mb-6 md:mb-0">
        <router-link :to="{ path: `/posts/${post.slug}` }" class="block">
          <p class="text-xl font-bold text-kta-blue-900">
            {{ post.title }}
          </p>
        </router-link>
        <p class="text-sm font-medium text-kta-blue-500">
          <time datetime="2020-03-16">{{ post.published }}</time>
        </p>
        <BodyText
          class="mt-3 text-base text-gray-600"
          :content="post.summary ? post.summary : post.body"
        />
        <div class="mt-3">
          <router-link
            :to="{ path: `/posts/${post.slug}` }"
            class="text-sm font-semibold text-kta-blue-500 underline hover:text-kta-blue-600"
          >
            Read More
          </router-link>
        </div>
        <ul class="mt-4 flex flex-wrap items-center space-x-2">
          <li
            v-for="(tag, index) in post.tags"
            :key="index"
            class="rounded-full bg-kta-blue-900 px-2 py-1 text-xs uppercase tracking-wider text-white md:px-4 md:py-1 md:font-bold"
          >
            <router-link :to="{ path: `/tag/${$_.kebabCase(tag.name)}` }">
              {{ tag.name }}
            </router-link>
          </li>
        </ul>
      </article>
      <PaginationControls
        @pageChange="update"
        component="Tags"
        getter="tag"
        class="col-span-2"
      />
    </div>
  </main>
</template>
<script>
import ComponentHeader from '@/clientInterface/components/layout/ComponentHeader'
import PaginationControls from '@/clientInterface/components/PaginationControls.vue'
export default {
  name: 'Tag',
  components: { ComponentHeader, PaginationControls },
  async created() {
    await this.$store.dispatch(
      'Tags/getTag',
      this.$_.startCase(this.$route.params.tag)
    )
  },
  async beforeRouteUpdate(to, from, next) {
    await this.$store.dispatch('Tags/getTag', this.$_.startCase(to.params.tag))
    this.$store.commit('Tags/setCurrentPage', { component: 'tag', page: 1 })
    next()
  },
  computed: {
    tag() {
      return this.$store.getters['Tags/tag']
    },
    posts() {
      return this.$_.cloneDeep(this.tag.posts).map((post) => {
        post.published = this.$date.format(new Date(post.published), 'PP')
        return post
      })
    },
    page() {
      return this.$store.getters['Tags/tag'].page
    }
  },
  methods: {
    update() {
      this.$store.dispatch(
        'Tags/getTag',
        this.$_.startCase(this.$route.params.tag)
      )
    }
  }
}
</script>
