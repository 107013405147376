<template>
  <div class="flex items-center space-x-2 md:mb-12">
    <h1 class="text-3xl font-extrabold leading-7 text-kta-blue-900">
      Latest News
    </h1>
    <div class="h-5 w-5 text-yellow-500">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          d="M6.26087 0L12.5217 12L6.26087 24H0L6.26087 12L0 0H6.26087ZM11.4783 0L17.7391 12L11.4783 24H17.7391L24 12L17.7391 0H11.4783Z"
          fill="currentColor"
        />
      </svg>
    </div>
  </div>
  <ul>
    <PinnedPost />
    <li v-for="(post, index) in posts" :key="index" class="mb-16">
      <article class="md:flex md:space-x-4">
        <div
          class="mb-2 flex items-center justify-between md:mb-0 md:block md:w-1/4 md:text-right"
        >
          <div>
            <router-link :to="{ path: `/posts/${post.slug}` }">
              <h2 class="text-2xl font-bold text-kta-blue-900">
                {{ post.title }}
              </h2>
            </router-link>
            <div class="font-semibold text-kta-blue-500">
              {{ post.published }}
            </div>
          </div>
          <span
            class="rounded-sm bg-red-600 px-2 py-1 text-xs font-medium uppercase text-white"
            v-show="post.important"
            >Important</span
          >
        </div>
        <div class="flex flex-1">
          <div>
            <div
              class="content text-gray-800"
              v-html="post.summary ? post.summary : post.body"
            ></div>
            <router-link
              v-if="post.summary"
              :to="{ path: `/posts/${post.slug}` }"
              class="my-4 inline-block text-sm font-semibold tracking-wide text-kta-blue-500 underline"
            >
              Read More
            </router-link>
            <div class="mt-4 flex flex-wrap items-center">
              <router-link
                :to="{ path: `/tag/${$_.kebabCase(tag.name)}` }"
                v-for="(tag, index) in post.tags"
                :key="index"
                class="mr-2 mb-2 rounded-full bg-kta-blue-900 px-2 py-1 text-xs uppercase tracking-wider text-white focus:ring-2 focus:ring-kta-blue-500 focus-visible:outline-none md:px-4 md:py-1 md:font-bold"
                >{{ tag.name }}</router-link
              >
            </div>
          </div>
        </div>
      </article>
    </li>
  </ul>
</template>
<script>
import PinnedPost from './PinnedPost'

export default {
  name: 'LatestNews',
  components: { PinnedPost },
  computed: {
    latest() {
      return this.$store.getters['Posts/latest']
    },
    posts() {
      return this.latest
        ? this.$_.cloneDeep(this.latest).map((post) => {
            post.published = this.$date.format(new Date(post.published), 'PP')
            return post
          })
        : []
    }
  }
}
</script>
