export default {
  set(state, tags) {
    state.tags = tags
  },
  setTag(state, data) {
    state.tag.name = data.name
    state.tag.posts = data.posts
    state.tag.count = data.itemCount
  },
  setCurrentPage(state, data) {
    if (!data.component) return

    if (data.page) {
      sessionStorage.setItem(`cp-tag`, data.page)
      state[data.component].offset =
        state[data.component].limit * (data.page - 1)
      return (state[data.component].page = data.page)
    }

    if (!state[data.component].page) {
      state[data.component].page = sessionStorage.getItem(`cp-tag`)
        ? +sessionStorage.getItem(`cp-tag`)
        : 1
      state[data.component].offset =
        state[data.component].limit * (state[data.component].page - 1)
    }
  },
  nextPage(state, component) {
    state[component].page++
    state[component].offset += state[component].limit
    sessionStorage.setItem(`cp-${component}`, state[component].page)
  },
  prevPage(state, component) {
    state[component].page--
    state[component].offset -= state[component].limit
    sessionStorage.setItem(`cp-${component}`, state[component].page)
  }
}
