export default {
  set(state, accordions) {
    state.accordions = accordions
  },
  setActive(state, accordion) {
    state.active = accordion
  },
  addSection(state) {
    state.active.content.push({
      title: '',
      body: ''
    })
  },
  removeSection(state, index) {
    state.active.content.splice(index, 1)
  }
  // setFilter(state, filter) {
  //   state.filter = filter === 'all' ? null : filter
  // },
  // setOrder(state, order) {
  //   state.order = order
  // },
  // setOrderBy(state, order) {
  //   state.orderBy = order
  // },
  // nextPage(state) {
  //   state.page++
  //   state.offset += state.limit
  // },
  // prevPage(state) {
  //   state.page--
  //   state.offset -= state.limit
  // },
  // setLimit(state, value) {
  //   state.limit = value
  // }
}
