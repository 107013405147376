import axios from 'axios'

export default {
  async getAll({ commit, rootGetters }) {
    try {
      const { data } = await axios.get(
        `${process.env.VUE_APP_API_URL}/directory`,
        rootGetters['User/config']
      )
      commit('set', data)
    } catch (error) {
      commit('handleErrors', error.response.data, { root: true })
    }
  }
}
