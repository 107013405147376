<template>
  <div>
    <h3
      class="test-sm mb-4 font-bold uppercase tracking-wide text-kta-blue-900"
    >
      Archives
    </h3>
    <ul class="space-y-2">
      <li v-for="(archive, index) in archives" :key="index" class="">
        <button
          class="flex cursor-pointer items-center space-x-1"
          @click="toggleArchive(archive)"
        >
          <span class="block text-yellow-500" v-if="active === archive">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-4 w-4"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="4"
                d="M20 12H4"
              />
            </svg>
          </span>
          <span class="block text-yellow-500" v-else>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-4 w-4"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="4"
                d="M12 4v16m8-8H4"
              />
            </svg>
          </span>
          <span
            class="font-medium text-kta-blue-900 outline-none focus:outline-none"
          >
            {{ archive.year }}
            <span class="text-sm font-normal text-kta-blue-500"
              >({{ archive.total }})</span
            >
          </span>
        </button>
        <div v-show="active === archive">
          <ul class="space-y-2 py-2 pl-3">
            <li v-for="(month, i) in archive.months" :key="i">
              <router-link
                :to="{
                  name: 'Archive',
                  params: { year: archive.year, month: month.month }
                }"
                class="flex items-center space-x-1"
              >
                <span class="inline-block text-yellow-500">
                  <svg
                    class="h-3 w-3"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 13 24"
                  >
                    <path
                      d="M6.26087 0L12.5217 12L6.26087 24H0L6.26087 12L0 0H6.26087Z"
                    />
                  </svg>
                </span>
                <span class="text-kta-blue-900">{{
                  months[month.month - 1]
                }}</span>
                <span class="text-sm text-kta-blue-500"
                  >({{ month.count }})</span
                >
              </router-link>
            </li>
          </ul>
        </div>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  name: 'PostsArchiveMenu',
  created() {
    this.$store.dispatch('Posts/fetchArchives')
  },
  data: () => ({
    active: null
  }),
  methods: {
    toggleArchive(archive) {
      this.active = this.active === archive ? null : archive
    }
  },
  computed: {
    archives() {
      return this.$store.getters['Posts/archives']
    },
    months() {
      return this.$store.getters.months
    }
  }
}
</script>
