<template>
  <nav class="sticky top-4 mr-6 text-kta-blue-700" ref="policyNav">
    <h3 class="mb-4 font-bold uppercase tracking-wide text-kta-blue-900">
      Sections
    </h3>
    <ol class="list-inside list-decimal">
      <li v-for="section in sections" :key="section.sectionId">
        <router-link
          :to="{
            name: 'Policies',
            params: { policy: `${$_.kebabCase(section.name.toLowerCase())}` }
          }"
          >{{ section.name }}</router-link
        >
        <ul class="my-2 ml-4 space-y-2 border-l pl-4 text-sm">
          <li v-for="policy in section.policy" :key="policy.id">
            <router-link
              :to="{
                name: 'Policies',
                params: {
                  policy: `${$_.kebabCase(section.name.toLowerCase())}`
                },
                hash: `\#${section.sectionId}-${policy.article}-${policy.paragraph}`
              }"
              :class="
                currentHash ===
                `\#${section.sectionId}-${policy.article}-${policy.paragraph}`
                  ? 'font-bold'
                  : 'font-normal'
              "
              >{{ policy.title }}</router-link
            >
          </li>
        </ul>
      </li>
    </ol>
  </nav>
</template>
<script>
export default {
  name: 'PolicyMenu',
  props: {
    currentHash: {
      type: String
    }
  },
  computed: {
    sections() {
      return this.$store.getters['Policies/sections']
    },
    section() {
      return this.$store.getters['Policies/section']
    },
    hash() {
      return this.$route.hash
    }
  }
}
</script>
