<template>
  <div class="pt-2 pb-6 md:py-6">
    <div class="mx-auto mb-6 max-w-7xl px-4 sm:px-6 md:px-8">
      <h1 class="text-2xl font-semibold text-gray-900">Users</h1>
    </div>
    <div class="mx-auto mb-6 max-w-7xl px-4 sm:px-6 md:px-8">
      <div class="overflow-hidden bg-white shadow sm:rounded-md">
        <ul class="divide-y divide-gray-200 text-sm">
          <li v-for="user in users" :key="user.id">
            <router-link
              :to="{ name: 'EditUser', params: { id: user.id } }"
              class="flex items-center p-4 hover:bg-gray-50"
            >
              <div class="mr-4 shrink-0">
                <img
                  class="h-20 w-20 rounded-full border-gray-200 bg-gray-100"
                  :src="`/employee-images/${user.id}.jpg`"
                  alt="kta-logo"
                  @error="setAltImg"
                />
              </div>
              <div>
                <div class="font-semibold">
                  {{ user.firstName }} {{ user.lastName }}
                </div>
                <div class="text-xs italic text-gray-600">
                  ID# {{ user.id }}
                </div>
                <div v-if="user.ktaEmail">
                  {{ user.ktaEmail }}
                  <span class="text-xs text-gray-600">(KTA Email)</span>
                </div>
                <div v-if="user.personalEmail">
                  {{ user.personalEmail }}
                  <span class="text-xs text-gray-600">(Personal Email)</span>
                </div>
                <div v-if="user.ktaMobile">
                  {{ formatPhone(user.ktaMobile) }}
                  <span class="text-xs text-gray-600">(KTA Mobile)</span>
                </div>
                <div v-if="user.personalMobile">
                  {{ formatPhone(user.personalMobile) }}
                  <span class="text-xs text-gray-600">(Personal Mobile)</span>
                </div>
              </div>
            </router-link>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  title: 'Users - iKTA',
  name: 'Users',
  async created() {
    if (!this.$store.getters['AdminUsers/get'].length)
      this.$store.dispatch('AdminUsers/fetchAll')
  },
  computed: {
    users() {
      return this.$store.getters['AdminUsers/get']
    },
    errors() {
      return this.$store.getters.getErrors
    }
  },
  methods: {
    setAltImg(event) {
      event.target.src = `/kta.svg`
    },
    formatPhone(number) {
      const parts = number.match(/^(\d{3})(\d{3})(\d{4})$/)
      return '(' + parts[1] + ') ' + parts[2] + '-' + parts[3]
    }
  }
}
</script>
