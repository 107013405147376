<template>
  <main
    class="relative z-10 mx-auto w-full max-w-7xl flex-1 py-12 px-4 sm:px-6 lg:py-16 lg:px-8"
  >
    <ComponentHeader component="directory" />
    <div class="flex items-start">
      <div class="sticky top-16 mr-4 pt-2">
        <ul
          class="space-y-1 rounded-full bg-gray-100 px-1 pt-2 pb-1 text-sm text-gray-700 shadow-inner"
        >
          <li v-for="letter in alphabet" :key="letter" class="text-center">
            <button
              @click="scrollTo(letter)"
              class="flex h-6 w-6 items-center justify-center rounded-full focus:outline-none"
              :class="[
                letter === currentLetter
                  ? 'bg-kta-blue-600 text-white shadow-md'
                  : '',
                !activeLetters.includes(letter)
                  ? 'cursor-not-allowed text-gray-400'
                  : ''
              ]"
            >
              {{ letter.toUpperCase() }}
            </button>
          </li>
        </ul>
      </div>
      <div class="flex-1">
        <div class="sticky top-14 bg-gray-50 py-2 ring-8 ring-gray-50">
          <div
            class="sticky top-0 flex items-center bg-white px-4 py-4 shadow sm:rounded-md sm:px-6"
          >
            <DirectorySearch :items="directory" @goto="gotoUser" />
            <SortFilter
              @sortBy="setSortBy"
              :directory="directory"
              @filterBy="setFilterBy"
            />
            <div class="ml-2">
              {{ sorted.length }} <span class="text-xs">Records</span>
            </div>
          </div>
        </div>
        <div
          class="mt-4 flex w-full justify-center rounded-md bg-white shadow"
          v-if="!directory.length"
        >
          <LoadAnimation />
        </div>
        <div
          class="mt-4 flex w-full justify-center rounded-md bg-white p-4 italic text-gray-600 shadow"
          v-if="directory.length && !filtered.length"
        >
          No results were found that match your filter criteria.
        </div>
        <div class="mt-2 overflow-hidden bg-white shadow sm:rounded-md" v-else>
          <ul class="divide-y divide-gray-200" v-if="sorted">
            <li ref="scrollIndicator" id="scroll-indicator" class=""></li>
            <li v-for="item in sorted" :key="item.id" :id="item.id">
              <div class="block hover:bg-gray-50">
                <div class="flex items-center px-4 py-4 sm:px-6">
                  <div class="flex min-w-0 flex-1 items-center">
                    <div class="shrink-0">
                      <img
                        class="h-20 w-20 rounded-full border-gray-200 bg-gray-100"
                        :src="`/employee-images/${item.id}.jpg`"
                        alt="kta-logo"
                        @error="setAltImg"
                      />
                    </div>
                    <div
                      class="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4"
                    >
                      <div class="flex flex-col justify-center space-y-1">
                        <p
                          class="truncate text-sm font-medium text-kta-blue-600"
                        >
                          {{ item.firstName }} {{ item.lastName }}
                        </p>
                        <p class="flex items-center text-sm text-gray-500">
                          {{ item.title }}
                        </p>
                        <p class="flex items-center text-sm text-gray-500">
                          {{ item.department }} | {{ item.location }}
                        </p>
                        <p
                          class="flex items-center text-sm text-gray-500"
                          v-if="item.supervisor"
                        >
                          <span
                            @click="gotoSupervisor(item.supervisorId)"
                            class="cursor-pointer text-kta-blue-600"
                            >{{ item.supervisor.firstName }}
                            {{ item.supervisor.lastName }}</span
                          >
                          <span class="ml-1 text-xs">(Supervisor)</span>
                        </p>
                      </div>
                      <div class="block">
                        <div>
                          <p
                            title="extension"
                            class="mt-1 flex items-center text-sm text-gray-500"
                            v-if="item.extension"
                          >
                            <PhoneMissedCallIcon
                              class="mr-1.5 h-5 w-5 shrink-0 text-gray-400"
                              aria-hidden="true"
                            />
                            <span class="truncate">{{ item.extension }}</span>
                          </p>
                          <a
                            :href="`mailto:${item.email}`"
                            target="_blank"
                            title="email"
                            class="mt-1 flex items-center text-sm text-gray-500"
                            v-if="item.email"
                          >
                            <MailIcon
                              class="mr-1.5 h-5 w-5 shrink-0 text-gray-400"
                              aria-hidden="true"
                            />
                            <span class="truncate">{{ item.email }}</span>
                          </a>
                          <a
                            :href="`tel:${item.direct}`"
                            title="direct phone"
                            class="mt-1 flex items-center text-sm text-gray-500"
                            v-if="item.direct"
                          >
                            <PhoneIcon
                              class="mr-1.5 h-5 w-5 shrink-0 text-gray-400"
                              aria-hidden="true"
                            />
                            <span class="truncate">{{ item.direct }}</span>
                          </a>
                          <a
                            :href="`tel:${item.mobile}`"
                            title="mobile phone"
                            class="mt-1 flex items-center text-sm text-gray-500"
                            v-if="item.mobile"
                          >
                            <DeviceMobileIcon
                              class="mr-1.5 h-5 w-5 shrink-0 text-gray-400"
                              aria-hidden="true"
                            />
                            <span class="truncate">{{ item.mobile }}</span>
                          </a>
                          <p
                            title="radio number"
                            class="mt-1 flex items-center text-sm text-gray-500"
                            v-if="item.radio"
                          >
                            <StatusOnlineIcon
                              class="mr-1.5 h-5 w-5 shrink-0 text-gray-400"
                              aria-hidden="true"
                            />
                            <span class="truncate">{{ item.radio }}</span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div
      class="fixed right-10 bottom-10 transition-opacity duration-200 ease-in-out"
      :class="[showScrollToTop ? 'opacity-100' : 'opacity-0']"
    >
      <button
        @click.prevent="returnToTop"
        type="button"
        class="inline-flex items-center rounded border border-transparent bg-kta-blue-100 px-2.5 py-1.5 text-xs font-medium text-kta-blue-700 shadow-md hover:bg-kta-blue-200 focus:outline-none focus:ring-2 focus:ring-kta-blue-500 focus:ring-offset-2"
      >
        BACK TO TOP
      </button>
    </div>
  </main>
</template>
<script>
/* eslint-disable vue/no-side-effects-in-computed-properties */
import {
  MailIcon,
  PhoneIcon,
  PhoneMissedCallIcon,
  DeviceMobileIcon,
  StatusOnlineIcon
} from '@heroicons/vue/solid'
import DirectorySearch from '@/clientInterface/components/directory/DirectorySearch'
import SortFilter from '@/clientInterface/components/directory/SortFilter'
import ComponentHeader from '@/clientInterface/components/layout/ComponentHeader'
export default {
  name: 'Directory',
  title() {
    return 'KTA - Employee Directory'
  },
  created() {
    this.$store.dispatch('Directory/getAll')
    this.observer = new IntersectionObserver(this.observeAction, {})
  },
  data: () => ({
    currentLetter: 'a',
    showScrollToTop: false,
    sortBy: 'lastName',
    searchInput: '',
    filterBy: {
      title: [],
      department: [],
      location: []
    }
  }),
  components: {
    ComponentHeader,
    DirectorySearch,
    DeviceMobileIcon,
    MailIcon,
    PhoneIcon,
    PhoneMissedCallIcon,
    StatusOnlineIcon,
    SortFilter
  },
  computed: {
    images() {
      return []
    },
    directory() {
      return this.$store.getters['Directory/formatted']
    },
    alphabet() {
      return 'abcdefghijklmnopqrstuvwxyz'.split('')
    },
    sorted() {
      return this.filtered.sort((a, b) =>
        a[this.sortBy] > b[this.sortBy] ? 1 : -1
      )
    },
    filtered() {
      const hasDepartments = this.filterBy.department.length
      const hasLocations = this.filterBy.location.length
      const hasTitles = this.filterBy.title.length
      return this.directory.filter((item) => {
        let dept = true
        let location = true
        let title = true

        if (hasDepartments) {
          // NEEDS IMPROVEMENTS
          dept =
            this.filterBy.department.includes(item.department) ||
            (this.filterBy.department.includes('Maintenance') &&
              item.department.includes('Maintenance')) ||
            (this.filterBy.department.includes('Roadside Maintenance') &&
              item.department.includes('Roadside Maintenance')) ||
            (this.filterBy.department.includes('Shop') &&
              item.department.includes('Shop')) ||
            (this.filterBy.department.includes('Striping') &&
              item.department.includes('Striping')) ||
            (this.filterBy.department.includes('Structures') &&
              item.department.includes('Structures')) ||
            (this.filterBy.department.includes('Utilities') &&
              item.department.includes('Utilities'))
        }
        if (hasLocations) {
          location = this.filterBy.location.includes(item.location)
        }
        if (hasTitles) {
          title = this.filterBy.title.includes(item.title)
        }
        if (dept && location && title) {
          return item
        }
      })
    },
    ids() {
      return this.filtered
        ? this.alphabet
            .map((letter) => {
              const id = this.sorted.find(
                (item) => item[this.sortBy][0] == letter.toUpperCase()
              )?.id
              if (id) {
                return { letter, id }
              }
            })
            .filter((item) => item)
        : []
    },
    activeLetters() {
      return this.ids.map((item) => {
        return item.letter
      })
    },
    anchorIds() {
      return this.ids.map((item) => {
        return item.id
      })
    }
  },
  methods: {
    setAltImg(event) {
      event.target.src = 'kta.svg'
    },
    returnToTop() {
      document.querySelector('header').scrollIntoView({ behavior: 'smooth' })
    },
    scrollTo(letter) {
      if (this.activeLetters.includes(letter)) {
        this.currentLetter = letter
        const { id } = this.ids.find((item) => item.letter == letter)
        const el = document.getElementById(id)
        const offset = 116
        window.scroll({
          top: el.offsetTop - offset,
          left: 0,
          behavior: 'smooth'
        })
      }
    },
    gotoSupervisor(id) {
      this.clearFilters()
      this.$nextTick(() => {
        const el = document.getElementById(id)
        const offset = 116
        window.scroll({
          top: el.offsetTop - offset,
          left: 0,
          behavior: 'smooth'
        })
      })
    },
    gotoUser(id) {
      this.filterBy = {
        title: [],
        department: [],
        location: []
      }
      this.$nextTick(() => {
        const el = document.getElementById(id)
        const offset = 116
        window.scroll({
          top: el.offsetTop - offset,
          left: 0,
          behavior: 'smooth'
        })
      })
    },
    observeAction(entries) {
      entries.forEach((entry) => {
        if (entry.target.id == this.$refs.scrollIndicator.id) {
          this.showScrollToTop = !entry.isIntersecting
        }
        if (entry.isIntersecting) {
          this.currentLetter = this.ids.find(
            (item) => item.id == entry.target.id
          )?.letter
        }
      })
    },
    addObservers() {
      this.$nextTick(() => {
        const elements = this.anchorIds.map((id) => document.getElementById(id))
        elements.push(this.$refs.scrollIndicator)
        elements.forEach((element) => this.observer.observe(element))
      })
    },
    removeObservers() {
      this.$nextTick(() => {
        for (const ref in this.$refs) {
          this.observer.unobserve(this.$refs[ref])
        }
      })
    },
    setSortBy(value) {
      this.sortBy = value
    },
    setFilterBy(value) {
      this.filterBy = value
      this.$forceUpdate()
    },
    clearFilters() {
      this.filterBy = {
        title: [],
        department: [],
        location: []
      }
      this.$forceUpdate()
    }
  },
  watch: {
    directory() {
      this.addObservers()
    }
  },
  beforeUnmount() {
    this.observer.disconnect()
  }
}
</script>
