import { isEqual } from 'lodash'

export default {
  getMenus(state) {
    return state.menus
  },
  getActive(state) {
    return state.active
  },
  isEdited(state) {
    return !isEqual(state.active, state.original)
  },
  activeItem(state) {
    return state.activeItem
  }
}
