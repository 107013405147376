export default {
  set(state, positions) {
    state.positions = positions.items
    state.count = positions.count
  },
  add(state, position) {
    state.positions.push(position)
  },
  update(state, update) {
    let index = state.positions.findIndex(
      (position) => position.id === update.id
    )
    state.positions.splice(index, 1, update)
  },
  remove(state, id) {
    let index = state.positions.findIndex((position) => position.id === id)
    state.positions.splice(index, 1)
  },
  setFilter(state, filter) {
    state.filter = filter === 'all' ? null : filter
  },
  setFilterBy(state, filter) {
    state.filterBy = filter !== 'All' ? filter : null
  },
  setOrder(state, order) {
    state.order = order
  },
  setOrderBy(state, order) {
    state.orderBy = order
  },
  nextPage(state) {
    state.page++
    state.offset += state.limit
  },
  prevPage(state) {
    state.page--
    state.offset -= state.limit
  },
  setLimit(state, value) {
    state.limit = value
  }
}
