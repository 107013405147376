import axios from 'axios'
import router from '@/router'

export default {
  // async getAll({ commit, rootGetters }) {
  //   try {
  //     const { data } = await axios.get(`${process.env.VUE_APP_API_URL}/pages/published`, rootGetters['User/config'])
  //     commit('set', data)
  //   } catch (error) {
  //     commit('handleErrors', error.response.data, { root: true })
  //   }
  // },
  async get({ commit, rootGetters }, slug) {
    try {
      const { data } = await axios.get(
        `${process.env.VUE_APP_API_URL}/pages/${slug}`,
        rootGetters['User/config']
      )
      commit('add', data)
      return data
    } catch (error) {
      if (error.response.status === 404) {
        router.push({ name: 'PageNotFound', params: { url: slug } })
      }
      commit('handleErrors', error.response.data, { root: true })
    }
  }
}
