<template>
  <div class="inline-flex space-x-6">
    <a
      href="https://www.facebook.com/KansasTurnpike"
      class="text-kta-blue-400 hover:text-kta-blue-200"
    >
      <span class="sr-only">Facebook</span>
      <svg
        class="h-6 w-6"
        fill="currentColor"
        viewBox="0 0 24 24"
        aria-hidden="true"
      >
        <path
          fill-rule="evenodd"
          d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
          clip-rule="evenodd"
        />
      </svg>
    </a>

    <a
      href="http://twitter.com/KansasTurnpike"
      class="text-kta-blue-400 hover:text-kta-blue-200"
    >
      <span class="sr-only">Twitter</span>
      <svg
        class="h-6 w-6"
        fill="currentColor"
        viewBox="0 0 24 24"
        aria-hidden="true"
      >
        <path
          d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84"
        />
      </svg>
    </a>

    <a
      href="http://www.youtube.com/kansasturnpike"
      class="text-kta-blue-400 hover:text-kta-blue-200"
    >
      <span class="sr-only">Youtube</span>
      <svg
        class="h-6 w-6"
        fill="currentColor"
        viewBox="0 0 24 24"
        aria-hidden="true"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M20.5952 4.47768C21.5418 4.74052 22.2872 5.515 22.5403 6.49842C23 8.28101 23 12 23 12C23 12 23 15.719 22.5403 17.5018C22.2872 18.485 21.5418 19.2595 20.5952 19.5224C18.8796 20 12 20 12 20C12 20 5.1204 20 3.40478 19.5224C2.45819 19.2595 1.71269 18.4851 1.45975 17.5017C1 15.7191 1 12 1 12C1 12 1 8.28101 1.45975 6.49842C1.71269 5.515 2.45819 4.74052 3.40478 4.47768C5.1204 4 12 4 12 4C12 4 18.8796 4 20.5952 4.47768ZM15 12.0001L10 15V9L15 12.0001Z"
        />
      </svg>
    </a>

    <a
      href="http://www.flickr.com/photos/ksturnpike"
      class="text-kta-blue-400 hover:text-kta-blue-200"
    >
      <span class="sr-only">Flickr</span>
      <svg
        class="h-6 w-6"
        fill="currentColor"
        viewBox="0 0 24 24"
        aria-hidden="true"
      >
        <circle cx="6" cy="12" r="4" />
        <circle cx="18" cy="12" r="4" />
      </svg>
    </a>
  </div>
</template>
