import axios from 'axios'
import router from '@/router'
export default {
  async getAll({ rootGetters, commit }) {
    try {
      const { data } = await axios.get(
        `${process.env.VUE_APP_API_URL}/opportunities`,
        rootGetters['User/config']
      )
      commit('set', data)
      return data
    } catch (error) {
      commit('handleErrors', error.response.data, { root: true })
    }
  },
  async apply({ rootGetters, commit }, formData) {
    try {
      await axios.post(
        `${process.env.VUE_APP_API_URL}/opportunities/apply`,
        formData,
        rootGetters['User/config']
      )
      commit(
        'displayAlert',
        {
          position: 'top',
          level: 'success',
          title: 'Application submitted.',
          timer: 3000
        },
        { root: true }
      )
      router.push({ name: 'Opportunities' })
    } catch (error) {
      commit('handleErrors', error.response.data, { root: true })
    }
  }
  // async getPdf({ commit, rootGetters }, id) {
  //   try {
  //     const { data } = await axios.get(
  //       `${process.env.VUE_APP_API_URL}/opportunities/${id}/pdf`,
  //       { ...rootGetters['User/config'], responseType: 'blob' }
  //     )
  //     return URL.createObjectURL(new Blob([data]))
  //   } catch (error) {
  //     commit('handleErrors', error.response.data, { root: true })
  //   }
  // }
}
