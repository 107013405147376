<template>
  <div class="overflow-hidden bg-white shadow sm:rounded-md">
    <div class="p-4 italic text-gray-500" v-if="policies.length < 1">
      No policies found.
    </div>
    <ul v-else>
      <li
        v-for="(policy, index) in policies"
        :key="index"
        :class="[index > 0 ? 'border-t border-gray-200' : '']"
      >
        <router-link
          :to="{ name: 'PoliciesEdit', params: { id: policy.id, policy } }"
          class="flex items-center justify-between transition duration-150 ease-in-out hover:bg-gray-50 focus:bg-gray-50 focus:outline-none"
        >
          <div
            class="block w-0 flex-1 items-center justify-between px-4 py-3 text-sm leading-6 text-gray-700 sm:px-6 lg:flex"
          >
            <div>
              <div>
                <span class="mr-2 text-xs font-light uppercase text-gray-400"
                  >Title</span
                >
                <span class="font-semibold text-gray-900">{{
                  policy.title
                }}</span>
              </div>
              <div class="leading-5">
                <span class="mr-2 text-xs font-light uppercase text-gray-400"
                  >Section Name</span
                >
                <span
                  class="inline-block w-full truncate lg:inline"
                  v-if="policy.sectionId"
                  >{{ policy.section.name }}</span
                >
              </div>
            </div>
            <div class="lg:text-right">
              <div class="flex items-center">
                <span class="mr-2 text-xs font-light uppercase text-gray-400"
                  >Policy ID</span
                >
                <span>
                  <span v-if="policy.sectionId"
                    >{{ policy.section.sectionId }}-{{ policy.article }}-{{
                      policy.paragraph
                    }}</span
                  >
                  <span class="text-yellow-500" v-else>
                    <svg
                      class="h-5 w-5"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </span>
                </span>
              </div>
              <div>
                <span class="mr-2 text-xs font-light uppercase text-gray-400"
                  >Effective Date</span
                >
                <span
                  ><time :datetime="policy.effective">{{
                    policy.effective
                  }}</time></span
                >
              </div>
            </div>
          </div>
          <div class="pr-4">
            <svg
              class="h-5 w-5 text-gray-400"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fill-rule="evenodd"
                d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                clip-rule="evenodd"
              />
            </svg>
          </div>
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    policies: {
      type: Array,
      required: true
    }
  }
}
</script>

<style></style>
