<template>
  <footer class="bg-kta-blue-900 py-8" aria-labelledby="footerHeading">
    <h2 id="footerHeading" class="sr-only">Footer</h2>
    <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
      <div class="text-center">
        <div class="mb-5 inline-block w-28">
          <svg
            viewBox="0 0 160 42"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M86.2717 0L71.7955 13.3408L74.1905 0H60.4239L53.0262 41.2287H66.7927L69.1345 28.1717L73.0019 24.7833L80.1512 41.2287H96.6498L84.3025 15.5228L100.783 0H86.2717Z"
              fill="white"
            />
            <path
              d="M143.005 12.4183L144.779 27.1783H136.086L143.005 12.4183ZM138.357 0L115.897 41.2287H129.38L131.935 35.7824H145.63L146.287 41.2287H160L152.354 0H138.357Z"
              fill="white"
            />
            <path
              d="M98.6002 0L87.885 10.8216H104.117L98.6534 41.2287H112.42L117.884 10.8216H126.985L132.201 0H98.6002Z"
              fill="white"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M6.47552 40.884C4.06282 23.2855 19.834 14.433 24.5885 11.5591L24.7304 11.6833C18.4503 17.236 9.4559 27.5077 14.7071 40.884H6.47552V40.884Z"
              fill="white"
            />
            <path
              d="M0 33.237V11.6824C0 5.68619 4.86087 0.825317 10.8571 0.825317H50.2231C23.5947 4.32018 4.68347 17.6432 0 33.237Z"
              fill="white"
            />
            <path
              d="M39.4904 20.0027L50.4539 41.2557H23.7724C20.9339 38.0802 19.4792 34.4789 19.4792 30.4873C19.4792 20.1446 32.749 7.17638 50.0282 0.825317L39.4904 20.0027Z"
              fill="white"
            />
          </svg>
        </div>
        <p class="mx-auto mb-3 w-full max-w-2xl text-sm text-kta-blue-200">
          <span class="block font-bold text-kta-blue-100">MISSION</span> KTA’s
          mission is to move Kansas forward by operating a safe, reliable and
          customer-valued turnpike system in a fiscally responsible,
          businesslike manner.
        </p>
        <p class="mx-auto mb-5 w-full max-w-2xl text-sm text-kta-blue-200">
          <span class="block font-bold text-kta-blue-100">VISION</span> KTA is
          commited to advancing transportation in Kansas through leadership,
          innovation and partnerships.
        </p>
        <SocialIcons />
      </div>
      <div class="mt-6 border-t border-kta-blue-300 pt-6">
        <p class="text-center text-sm text-kta-blue-400">
          &copy; {{ new Date().getFullYear() }} Kansas Turnpike Authority. All
          rights reserved.
        </p>
      </div>
    </div>
  </footer>
</template>
<script>
import SocialIcons from '@/clientInterface/components/SocialIcons'

export default {
  name: 'Footer',
  components: { SocialIcons }
}
</script>
