export default {
  sections(state) {
    return state.sections
  },
  section(state) {
    return state.sections.find(
      (section) => section.name.toLowerCase() === state.section
    )
  }
}
