<template>
  <div class="mb-5">
    <div class="mb-2 flex items-center">
      <label class="block">{{ label }}</label>
      <Switch
        v-if="includeSwitch"
        v-model="enabled"
        :class="[
          enabled ? 'bg-gray-700' : 'bg-gray-200',
          'relative ml-2 inline-flex h-6 w-11 shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2'
        ]"
      >
        <span class="sr-only">Active</span>
        <span
          aria-hidden="true"
          :class="[
            enabled ? 'translate-x-5' : 'translate-x-0',
            'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
          ]"
        />
      </Switch>
    </div>
    <v-date-picker
      v-model="date"
      :mode="dateMode"
      v-if="(includeSwitch && enabled) || !includeSwitch"
    >
      <template v-slot="{ inputValue, inputEvents }">
        <input
          type="text"
          class="block w-full rounded-md border-gray-300 bg-white text-gray-700 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
          :value="inputValue"
          v-on="inputEvents"
        />
      </template>
    </v-date-picker>
  </div>
</template>

<script>
import { Switch } from '@headlessui/vue'

export default {
  components: { Switch },
  props: {
    modelValue: {
      type: [String, Date],
      required: false
    },
    label: {
      type: String,
      required: false
    },
    includeSwitch: {
      type: Boolean,
      default: false
    },
    includeTime: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({}),
  computed: {
    date: {
      get() {
        return this.modelValue ? new Date(this.modelValue) : new Date()
      },
      set(value) {
        this.$emit('update:modelValue', value)
      }
    },
    enabled: {
      get() {
        return this.modelValue ? true : false
      },
      set(value) {
        value
          ? this.$emit('update:modelValue', new Date())
          : this.$emit('update:modelValue', null)
      }
    },
    dateMode() {
      return this.includeTime ? 'dateTime' : 'date'
    }
  }
}
</script>
