/* eslint-disable no-unused-vars */
import axios from 'axios'

export default {
  async getMenus({ state, commit, rootGetters }) {
    try {
      const { data } = await axios.get(
        `${process.env.VUE_APP_API_URL}/menus`,
        rootGetters['User/config']
      )
      const menus = data.map((menu) => {
        menu.items = menu.items ? JSON.parse(menu.items) : []
        return menu
      })
      commit('setMenus', menus)
      if (state.active) {
        commit('setActive', state.active)
      }
    } catch (error) {
      commit('handleErrors', error.response.data, { root: true })
    }
  },

  async create({ commit, rootGetters, state }) {
    try {
      const { name, items } = state.active
      await axios.post(
        `${process.env.VUE_APP_API_URL}/menus`,
        {
          name,
          items: JSON.stringify(items)
        },
        rootGetters['User/config']
      )
      commit(
        'displayAlert',
        {
          level: 'success',
          title: 'Menu saved.',
          text: '',
          timer: 3000
        },
        { root: true }
      )
    } catch (error) {
      commit('handleErrors', error.response.data, { root: true })
    }
  },

  async update({ commit, dispatch, rootGetters, state }) {
    try {
      const { name, items, id } = state.active
      await axios.patch(
        `${process.env.VUE_APP_API_URL}/menus/${id}`,
        {
          name,
          items: JSON.stringify(items)
        },
        rootGetters['User/config']
      )
      commit(
        'displayAlert',
        {
          level: 'success',
          title: 'Menu saved.',
          text: '',
          timer: 3000
        },
        { root: true }
      )
      dispatch('getMenus')
    } catch (error) {
      commit('handleErrors', error.response.data, { root: true })
    }
  },

  async remove({ commit, rootGetters, state }) {
    if (state.active.id) {
      try {
        await axios.delete(
          `${process.env.VUE_APP_API_URL}/menus/${state.active.id}`,
          rootGetters['User/config']
        )
      } catch (error) {
        commit('handleErrors', error.response.data, { root: true })
      }
    }
    commit('remove')
    commit(
      'displayAlert',
      {
        level: 'success',
        title: 'Menu removed.',
        text: '',
        timer: 3000
      },
      { root: true }
    )
  }
}
