import { createRouter, createWebHistory } from 'vue-router'
import store from '@/store'
import PageNotFound from '@/clientInterface/views/PageNotFound.vue'

import { adminRoutes } from './admin'
import { clientRoutes } from './client'
const routes = [
  ...adminRoutes,
  ...clientRoutes,
  {
    path: '/:url(.*)',
    name: 'PageNotFound',
    component: PageNotFound,
    meta: { auth: false }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  // eslint-disable-next-line no-unused-vars
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {
        el: to.hash,
        behavior: 'smooth',
        top: 16
      }
    }
  },
  routes
})

router.beforeEach(async (to, from, next) => {
  store.commit('removeErrors')
  if (!store.state.accessToken) {
    const accessToken = localStorage.getItem('accessToken')
    const refreshToken = localStorage.getItem('refreshToken')
    if (accessToken != 'null') {
      store.commit('User/setTokens', { accessToken, refreshToken })
    }
  }
  if (
    to.meta.auth &&
    !store.getters['User/loggedIn'] &&
    store.getters['User/refreshable']
  ) {
    await store.dispatch('User/refreshTokens')
  }
  if (to.meta.auth && !store.getters['User/loggedIn'] && to.name !== 'Login') {
    sessionStorage.setItem('from', to.path)
    store.commit('setHeaderInfo', {
      image: '',
      title: '',
      subtitle: '',
      ctaButtonText: '',
      ctaLink: ''
    })
    store.commit('User/logout')
    next('/login')
  } else if (
    store.getters['User/loggedIn'] &&
    store.getters['User/profileOnly'] &&
    to.name !== 'User'
  ) {
    next('/profile')
  } else if (store.getters['User/loggedIn'] && to.name === 'Login') {
    next('/')
  } else {
    next()
  }
})

export default router
