export default {
  set(state, pages) {
    state.pages = pages
  },
  add(state, page) {
    // state.pages.push(page)
    state.current = page
  },
  setCurrent(state, page) {
    state.current = page
  }
}
