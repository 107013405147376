import axios from 'axios'

export default {
  async getFolders({ commit, rootGetters }) {
    const { data } = await axios.get(
      `${process.env.VUE_APP_API_URL}/media/folders`,
      rootGetters['User/config']
    )
    commit('setFolders', data)
  },

  async getAllFiles({ commit, rootGetters }) {
    const { data } = await axios.get(
      `${process.env.VUE_APP_API_URL}/media/files`,
      rootGetters['User/config']
    )
    commit('setFiles', data)
  },

  async getFiles({ state, rootGetters, commit }) {
    if (state.activeFolder) {
      const { data } = await axios.get(
        `${process.env.VUE_APP_API_URL}/media/files/folder/${state.activeFolder}`,
        rootGetters['User/config']
      )
      commit('setFiles', data)
    }
  },
  async upload({ commit, dispatch, rootGetters }, file) {
    try {
      await axios.post(
        `${process.env.VUE_APP_API_URL}/media/files`,
        file,
        rootGetters['User/config']
      )
      commit('toggleFileUploader')
      dispatch('getFiles')
      dispatch('getAllFiles')
      commit(
        'displayAlert',
        {
          position: 'top',
          level: 'success',
          title: 'File Uploaded.',
          timer: 3000
        },
        { root: true }
      )
    } catch (error) {
      commit(
        'displayAlert',
        {
          position: 'top',
          level: 'error',
          title: 'Upload Failed.',
          text: error,
          timer: 5000
        },
        { root: true }
      )
    }
  },

  async updateFile({ commit, dispatch, rootGetters }, update) {
    try {
      await axios.patch(
        `${process.env.VUE_APP_API_URL}/media/files/${update.id}`,
        update.data,
        rootGetters['User/config']
      )
      dispatch('getFiles')
      dispatch('getAllFiles')
      commit(
        'displayAlert',
        {
          position: 'top',
          level: 'success',
          title: 'File Updated.',
          timer: 3000
        },
        { root: true }
      )
    } catch (error) {
      this.$store.commit(
        'displayAlert',
        {
          position: 'top',
          level: 'error',
          title: 'Update Failed.',
          text: error,
          timer: 5000
        },
        { root: true }
      )
    }
  },

  async createFolder({ commit, dispatch, rootGetters }, form) {
    try {
      await axios.post(
        `${process.env.VUE_APP_API_URL}/media/folders`,
        form,
        rootGetters['User/config']
      )
      dispatch('getFolders')
    } catch (error) {
      commit(
        'displayAlert',
        {
          position: 'top',
          level: 'error',
          title: error.response.data.error.message,
          timer: 5000
        },
        { root: true }
      )
      commit('handleErrors', error.response.data, { root: true })
    }
  },

  async deleteFile({ commit, dispatch, rootGetters }, id) {
    try {
      await axios.delete(
        `${process.env.VUE_APP_API_URL}/media/files/${id}`,
        rootGetters['User/config']
      )

      commit(
        'displayAlert',
        {
          position: 'top',
          level: 'success',
          title: 'File deleted.',
          timer: 3000
        },
        { root: true }
      )
      dispatch('getAllFiles')
    } catch (error) {
      commit(
        'displayAlert',
        {
          position: 'top',
          level: 'error',
          title: error.response.data.error.error,
          text: `Pages: ${error.response.data.error.message}`,
          timer: 5000
        },
        { root: true }
      )
      commit('handleErrors', error.response.data, { root: true })
    }
  },
  async deleteFiles({ commit, dispatch, rootGetters }, ids) {
    const fileIds = Object.values(ids)
    try {
      await axios.post(
        `${process.env.VUE_APP_API_URL}/media/files/remove-multiple`,
        { ids: fileIds },
        rootGetters['User/config']
      )
      commit(
        'displayAlert',
        {
          position: 'top',
          level: 'success',
          title: 'File(s) deleted.',
          timer: 3000
        },
        { root: true }
      )
      dispatch('getAllFiles')
    } catch (error) {
      commit(
        'displayAlert',
        {
          position: 'top',
          level: 'error',
          title: error.response.data.error.message,
          timer: 5000
        },
        { root: true }
      )
      commit('handleErrors', error.response.data, { root: true })
    }
  },
  async deleteFolder({ commit, dispatch, rootGetters }, id) {
    try {
      await axios.delete(
        `${process.env.VUE_APP_API_URL}/media/folders/${id}`,
        rootGetters['User/config']
      )

      commit(
        'displayAlert',
        {
          position: 'top',
          level: 'success',
          title: 'Folder deleted.',
          timer: 3000
        },
        { root: true }
      )
      dispatch('getFolders')
    } catch (error) {
      commit(
        'displayAlert',
        {
          position: 'top',
          level: 'error',
          title: error.response.data.error.message,
          timer: 5000
        },
        { root: true }
      )
      commit('handleErrors', error.response.data, { root: true })
    }
  },
  async updateFolder({ commit, dispatch, rootGetters }, folder) {
    try {
      await axios.patch(
        `${process.env.VUE_APP_API_URL}/media/folders/${folder.id}`,
        { title: folder.title, name: folder.name },
        rootGetters['User/config']
      )
      dispatch('getFolders')
    } catch (error) {
      commit(
        'displayAlert',
        {
          position: 'top',
          level: 'error',
          title: 'Unable to update folder.',
          timer: 5000
        },
        { root: true }
      )
      commit('handleErrors', error.response.data, { root: true })
    }
  }
}
