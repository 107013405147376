<template>
  <main class="relative mx-auto max-w-7xl flex-1 py-8">
    <div class="mb-8 flex items-end space-x-2">
      <h3 class="text-2xl font-bold text-kta-blue-800">Related Posts</h3>
    </div>
    <div class="flex-1 gap-16 md:grid lg:grid-cols-2 lg:gap-x-5 lg:gap-y-12">
      <article v-for="(post, index) in posts" :key="index" class="mb-6 md:mb-0">
        <router-link :to="{ path: `/posts/${post.slug}` }" class="block">
          <p class="text-xl font-bold text-kta-blue-900">
            {{ post.title }}
          </p>
        </router-link>
        <p class="text-sm font-medium text-kta-blue-500">
          <time datetime="2020-03-16">{{ post.published }}</time>
        </p>
        <BodyText
          class="mt-3 text-base text-gray-600"
          :content="post.summary ? post.summary : post.body"
        />
        <div class="mt-3">
          <router-link
            :to="{ path: `/posts/${post.slug}` }"
            class="text-sm font-semibold text-kta-blue-500 underline hover:text-kta-blue-600"
          >
            Read More
          </router-link>
        </div>
        <ul class="mt-4 flex flex-wrap items-center">
          <li
            v-for="(tag, index) in post.tags"
            :key="index"
            class="mr-2 mt-2 rounded-full bg-kta-blue-900 px-2 py-1 text-xs uppercase tracking-wider text-white md:px-4 md:py-1 md:font-bold"
          >
            <router-link :to="{ path: `/tag/${$_.kebabCase(tag.name)}` }">
              {{ tag.name }}
            </router-link>
          </li>
        </ul>
      </article>
      <PaginationControls
        @pageChange="update"
        component="Tags"
        getter="tag"
        class="col-span-2"
      />
    </div>
  </main>
</template>
<script>
import PaginationControls from '@/clientInterface/components/PaginationControls.vue'

export default {
  name: 'Tag',
  components: { PaginationControls },
  props: {
    postsTag: {
      type: String,
      required: true
    }
  },
  async created() {
    await this.$store.dispatch('Tags/getTag', this.postsTag)
    this.$store.commit('Tags/setCurrentPage', { component: 'tag', page: 1 })
  },
  computed: {
    tag() {
      return this.$store.getters['Tags/tag']
    },
    posts() {
      return this.$_.cloneDeep(this.tag.posts).map((post) => {
        post.published = this.$date.format(new Date(post.published), 'PP')
        return post
      })
    },
    page() {
      return this.$store.getters['Tags/tag'].page
    }
  },
  methods: {
    update() {
      this.$store.dispatch('Tags/getTag', this.postsTag)
    }
  }
}
</script>
