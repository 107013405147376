export default {
  set(state, positions) {
    state.positions = positions
    state.positions.items.forEach((item) => {
      if (!state.departments.includes(item.department)) {
        state.departments.push(item.department)
      }
    })
  }
}
