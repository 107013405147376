import actions from './actions'
import mutations from './mutations'
import getters from './getters'

export default {
  namespaced: true,
  state: () => ({
    positions: [],
    count: 0,
    offset: 0,
    limit: 10,
    filter: null,
    filterBy: null,
    filterOptions: [{ key: 'department', value: 'Department', filterBy: true }],
    order: 'ASC',
    orderBy: { key: 'title', value: 'Title' },
    orderOptions: [
      { key: 'title', value: 'Title' },
      { key: 'department', value: 'Department' },
      { key: 'created', value: 'Created' },
      { key: 'updated', value: 'Updated' }
    ],
    page: 1,
    departments: [
      'Authority',
      'Business Services and Customer Relations',
      'Carpenter',
      'Customer Service Center',
      'Engineering',
      'Executive Administration',
      'Financial Services',
      'General Counsel',
      'Human Resources',
      'Incident Management Center',
      'Information Technology',
      'KTAG',
      'Maintenance Administration',
      'Maintenance',
      'Patrol',
      'Roadside Maintenance',
      'Roadway Operations',
      'Roadway Projects',
      'Shop',
      'Striping',
      'Structures',
      'Toll',
      'Utilities',
      'Video Enforcement'
    ]
  }),
  mutations,
  getters,
  actions
}
