<template>
  <div
    class="accordion mb-4 overflow-hidden rounded-md border border-gray-400 bg-white"
  >
    <div v-for="(item, key) in items" :key="key">
      <input
        type="radio"
        class="hidden checked:hidden"
        name="accordion"
        :id="item[labelProperty]"
      />
      <section class="box relative h-auto transition duration-150 ease-in-out">
        <label
          class="box-title -mt-px flex cursor-pointer items-center border-t border-b border-gray-400 bg-gray-200 px-4 py-3"
          :for="item[labelProperty]"
        >
          <span class="arrow mr-2 block text-yellow-500">
            <svg
              class="h-3 w-3"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 13 24"
            >
              <path
                d="M6.26087 0L12.5217 12L6.26087 24H0L6.26087 12L0 0H6.26087Z"
              />
            </svg>
          </span>
          {{ item[labelProperty] }}
        </label>
        <label
          class="box-close absolute inset-x-0 top-0 hidden h-12 cursor-pointer"
          for="acc-close"
        ></label>
        <div
          class="-mb-px box-content hidden border-b border-gray-400 p-4"
          v-html="item[contentProperty]"
        ></div>
      </section>
    </div>
    <input class="hidden" type="radio" name="accordion" id="acc-close" />
  </div>
</template>

<script>
export default {
  name: 'CSSAccordion',
  props: {
    items: {
      type: Array,
      required: true
    },
    labelProperty: {
      type: String,
      required: false
    },
    contentProperty: {
      type: String,
      required: false
    }
  }
}
</script>

<style scoped>
.accordion div:last-of-type .box-title {
  margin-bottom: -1px;
}

.box-content > p {
  margin-bottom: 1rem;
}

.box-content ul {
  list-style: disc;
  margin-bottom: 1rem;
  margin-left: 1rem;
}

input:checked + .box .box-content,
input:checked + .box .box-close {
  display: inline-block;
}

input:checked + section.box .box-title .arrow {
  transform: rotate(90deg);
}

.accordion {
  --link-text-color: rgba(33, 94, 159);
  --link-text-color-hover: rgba(36, 69, 130);
}

.accordion a {
  color: var(--link-text-color);
  text-decoration: underline;
}
.accordion a:hover {
  color: var(--link-text-color-hover);
}

.accordion > p {
  margin-bottom: 1rem;
}

.accordion ul {
  list-style-type: disc;
  margin-left: 2rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.accordion h1 {
  font-family: 'Cabin', sans-serif;
  line-height: 1;
  font-weight: bold;
  font-size: 1.375rem;
  margin-bottom: 0.5rem;
}
.accordion h2 {
  line-height: 1;
  margin-bottom: 0.5rem;
  font-weight: bold;
  font-size: 1rem;
}
.accordion h3 {
  font-family: 'Arimo', sans-serif;
  line-height: 1;
  margin-bottom: 0.5rem;
  font-size: 0.8125rem;
  text-transform: uppercase;
}
.accordion h4 {
  line-height: 1;
  font-weight: bold;
  margin-bottom: 0.5rem;
  font-size: 0.8125rem;
}
.accordion h5 {
  line-height: 1;
  font-weight: bold;
  margin-bottom: 0.5rem;
  font-size: 0.75rem;
}
.accordion h6 {
  line-height: 1;
  font-weight: bold;
  margin-bottom: 0.5rem;
  font-size: 0.625rem;
}

.accordion li p {
  position: inline;
}

.accordion ol {
  list-style: decimal;
  margin-left: 1.2rem;
}

.accordion table {
  font-size: 0.9rem;
}

.accordion table td {
  border: 1px solid #ccc;
  min-width: 5rem;
  padding: 0.25rem 0.5rem;
}
.accordion table th {
  border: 1px solid #333333;
  font-weight: 600;
  min-width: 5rem;
  padding: 0.25rem 0.5rem;
}
</style>
