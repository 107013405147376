export default {
  set(state, pages) {
    state.pages = pages.items
    state.count = pages.count
  },
  add(state, page) {
    state.pages.push(page)
  },
  update(state, update) {
    let index = state.pages.findIndex((page) => page.id === update.id)
    state.pages.splice(index, 1, update)
  },
  remove(state, id) {
    let index = state.pages.findIndex((page) => page.id === id)
    state.pages.splice(index, 1)
  },
  setFilter(state, filter) {
    state.filter = filter === 'all' ? null : filter
  },
  setOrder(state, order) {
    state.order = order
  },
  setOrderBy(state, order) {
    state.orderBy = order
  },
  nextPage(state) {
    state.page++
    state.offset += state.limit
  },
  prevPage(state) {
    state.page--
    state.offset -= state.limit
  },
  setLimit(state, value) {
    state.limit = value
  }
}
