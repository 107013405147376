if (
  window.screen.width < 1920 &&
  window.navigator.userAgent.includes('Windows')
) {
  document.querySelector('html').style.fontSize =
    1920 / window.screen.width > 1.25
      ? '12px'
      : `${16 / (1920 / window.screen.width)}px`
}

import { createApp } from 'vue'
import {
  cloneDeep,
  first,
  isEmpty,
  isEqual,
  kebabCase,
  last,
  range,
  startCase
} from 'lodash'
import { format } from 'date-fns'
import VCalendar from 'v-calendar'
import '@/assets/index.css'
import 'v-calendar/dist/style.css'

import App from './App.vue'
import router from './router'
import titleMixin from '@/mixins/titleMixin'
import store from './store'

import Input from '@/utilities/form/Input'
import Select from '@/utilities/form/Select'
import Textarea from '@/utilities/form/Textarea'
import Checkbox from '@/utilities/form/Checkbox'
// import Toggle from '@/utilities/form/Toggle'

import LoadAnimation from '@/utilities/LoadAnimation'
import BodyText from '@/clientInterface/components/utilities/BodyText'

const app = createApp(App)
  .use(store)
  .use(router)
  .use(VCalendar, {})
  .mixin(titleMixin)
  .component('Input', Input)
  .component('Textarea', Textarea)
  .component('Select', Select)
  .component('Checkbox', Checkbox)
  .component('LoadAnimation', LoadAnimation)
  .component('BodyText', BodyText)
// .component('Checkbox', Checkbox)

app.config.globalProperties.$_ = {
  cloneDeep,
  first,
  isEmpty,
  isEqual,
  kebabCase,
  last,
  range,
  startCase
}
app.config.globalProperties.$date = { format }
app.mount('#app')
app.config.globalProperties.$store.subscribeAction({
  before: async (action, state) => {
    if (action.type !== 'User/refreshTokens') {
      const loggedIn =
        state.User.accessToken &&
        new Date(
          JSON.parse(atob(state.User.accessToken.split('.')[1])).exp * 1000
        ) > new Date()
      const refreshable =
        state.User.refreshToken &&
        new Date(
          JSON.parse(atob(state.User.refreshToken.split('.')[1])).exp * 1000
        ) > new Date()

      if (!loggedIn && refreshable && !state.User.refreshing) {
        await app.config.globalProperties.$store.dispatch('User/refreshTokens')
      }
    }
  }
})
