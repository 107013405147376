<template>
  <main
    class="relative z-10 mx-auto w-full max-w-7xl flex-1 py-12 px-4 sm:px-6 lg:py-16 lg:px-8"
  >
    <div class="mb-6 text-kta-blue-800">
      <a
        @click="$router.go(-1)"
        class="mb-8 inline-block cursor-pointer text-sm font-semibold tracking-wide text-kta-blue-500 underline"
        >&larr; Back</a
      >
      <h1 class="text-4xl font-bold">{{ formatted.title }}</h1>
      <div class="mb-2 font-medium italic">
        Published on {{ formatted.published }}
      </div>
      <div class="flex flex-wrap items-center">
        <router-link
          :to="{ path: `/tag/${$_.kebabCase(tag.name)}` }"
          v-for="(tag, index) in post.tags"
          :key="index"
          class="mr-2 mb-2 rounded-full bg-kta-blue-900 px-2 py-1 text-xs uppercase tracking-wider text-white focus:ring-2 focus:ring-kta-blue-500 focus-visible:outline-none md:px-4 md:py-1 md:font-bold"
          >{{ tag.name }}
        </router-link>
      </div>
    </div>
    <BodyText :content="formatted.body" />
  </main>
</template>
<script>
import BodyText from '@/clientInterface/components/utilities/BodyText'
export default {
  name: 'Post',
  components: { BodyText },
  async created() {
    this.post = await this.$store.dispatch('Posts/get', this.$route.params.slug)
  },
  data: () => ({
    post: {}
  }),
  computed: {
    formatted() {
      const post = this.$_.cloneDeep(this.post)
      for (const prop in post) {
        if (prop === 'published') {
          post[prop] = this.$date.format(new Date(post.published), 'PP')
        }
      }
      return post
    }
  }
}
</script>
