import axios from 'axios'
import router from '@/router'

export default {
  async fetchAll({ rootGetters, commit }) {
    try {
      // let query = state.offset
      //   ? `?limit=${state.limit}&offset=${state.offset}`
      //   : `?limit=${state.limit}`
      // query = state.filter ? `${query}&filter=${state.filter}` : query
      // query =
      //   state.orderBy.key !== 'created' || state.order !== 'ASC'
      //     ? `${query}&order=${state.order}&orderBy=${state.orderBy.key}`
      //     : query
      // const { data } = query
      //   ? await axios.get(`${process.env.VUE_APP_API_URL}/pages${query}`, rootGetters['User/config'])
      //   : await axios.get(`${process.env.VUE_APP_API_URL}/pages`, rootGetters['User/config'])
      const { data } = await axios.get(
        `${process.env.VUE_APP_API_URL}/accordions`,
        rootGetters['User/config']
      )
      commit('set', data)
    } catch (errors) {
      commit('handleErrors', errors.response.data, { root: true })
    }
  },
  async fetchOne({ commit, rootGetters }, id) {
    try {
      const { data } = await axios.get(
        `${process.env.VUE_APP_API_URL}/accordions/${id}`,
        rootGetters['User/config']
      )
      commit('setActive', data)
    } catch (errors) {
      commit('handleErrors', errors.response.data, { root: true })
    }
  },
  async create({ state, commit, rootGetters }, html) {
    try {
      const data = {
        name: state.active.name,
        content: state.active.content,
        html
      }
      await axios.post(
        `${process.env.VUE_APP_API_URL}/accordions`,
        data,
        rootGetters['User/config']
      )
      commit(
        'displayAlert',
        {
          position: 'top',
          level: 'success',
          title: 'Accordion created.',
          timer: 3000
        },
        { root: true }
      )
      router.push({ name: 'AccordionsIndex' })
    } catch (errors) {
      commit('handleErrors', errors.response.data, { root: true })
    }
  },
  async update({ commit, rootGetters }, params) {
    try {
      await axios.patch(
        `${process.env.VUE_APP_API_URL}/accordions/${params.id}`,
        params,
        rootGetters['User/config']
      )
      commit(
        'displayAlert',
        {
          position: 'top',
          level: 'success',
          title: 'Accordion updated.',
          timer: 3000
        },
        { root: true }
      )
      router.push({ name: 'AccordionsIndex' })
    } catch (errors) {
      commit('handleErrors', errors.response.data, { root: true })
    }
  },
  async delete({ commit, rootGetters }, id) {
    try {
      await axios.delete(
        `${process.env.VUE_APP_API_URL}/accordions/${id}`,
        rootGetters['User/config']
      )
      commit(
        'displayAlert',
        {
          position: 'top',
          level: 'success',
          title: 'Accordion deleted.',
          timer: 3000
        },
        { root: true }
      )
      router.push({ name: 'AccordionsIndex' })
    } catch (errors) {
      commit('handleErrors', errors.response.data, { root: true })
    }
  }
}
