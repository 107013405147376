<template>
  <div
    class="flex flex-1 items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8"
  >
    <div class="w-full max-w-md space-y-8">
      <div>
        <h2 class="mt-6 text-center text-3xl font-extrabold text-kta-blue-900">
          Log in to your account
        </h2>
        <p class="mt-2 text-center text-lg font-semibold text-gray-700">
          Or
          <router-link
            :to="{ name: 'Registration' }"
            class="tracking-wide text-kta-blue-500 underline hover:text-kta-blue-600"
            >register a new account</router-link
          >
        </p>
      </div>
      <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          <form class="space-y-6" @submit.prevent="login">
            <Input
              v-model="form.id"
              label="Employee ID"
              :error="errors.id"
              errorKey="id"
            />
            <Input
              v-model="form.password"
              type="password"
              label="Password"
              :error="errors.password"
              errorKey="password"
            />
            <div>
              <button
                type="submit"
                class="group relative flex w-full justify-center rounded-md border border-transparent bg-kta-blue-700 py-2 px-4 text-sm font-medium text-white hover:bg-kta-blue-800 focus:outline-none focus:ring-2 focus:ring-kta-blue-500 focus:ring-offset-2"
              >
                <span class="absolute inset-y-0 left-0 flex items-center pl-3">
                  <svg
                    class="h-5 w-5 text-kta-blue-500 group-hover:text-kta-blue-400"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </span>
                Log in
              </button>
            </div>
          </form>
          <div class="mt-6 text-center text-sm">
            <router-link
              :to="{ name: 'PasswordReset' }"
              class="font-medium text-kta-blue-600 hover:text-kta-blue-500"
            >
              Forgot your password?
            </router-link>
          </div>
          <div class="mt-4 text-center text-xs text-gray-800">
            <p class="mb-2">
              Your employee ID can be found on your KTA badge. No badge? Please
              contact your supervisor for your ID.
            </p>
            <p>
              Issues registering or logging in?
              <a
                class="font-medium text-kta-blue-600 hover:text-kta-blue-500"
                href="mailto:iktasupport@ksturnpike.com"
                target="_blank"
                >Report an issue</a
              >
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Login',
  title() {
    return 'KTA - Login'
  },
  data: () => ({
    form: {
      id: '',
      password: ''
    }
  }),
  computed: {
    errors() {
      return this.$store.getters.getErrors
    }
  },
  methods: {
    login() {
      this.$store.dispatch('User/login', this.form)
    }
  },
  watch: {
    errors: {
      handler(value) {
        if (value.message) {
          this.$store.commit('displayAlert', {
            position: 'top',
            level: 'error',
            text: value.message,
            timer: 3000
          })
        }
      },
      deep: true
    }
  }
}
</script>
