<template>
  <button
    class="absolute right-4 bottom-4 z-50 flex h-16 w-16 items-center justify-center rounded-full border-r border-gray-800 bg-gray-800 text-gray-400 hover:ring-8 focus:bg-gray-900 focus:text-gray-300 focus:outline-none md:hidden"
    aria-label="toggle sidebar"
    @click="toggleMenu"
  >
    <span class="flex flex-col items-center" v-if="!isActive">
      <svg
        class="h-6 w-6"
        stroke="currentColor"
        fill="none"
        viewBox="0 0 24 24"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M4 6h16M4 12h16M4 18h7"
        />
      </svg>
      <span class="text-2xs uppercase tracking-wide">Menu</span>
    </span>
    <span class="flex flex-col items-center" v-else>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="h-6 w-6"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M6 18L18 6M6 6l12 12"
        />
      </svg>
      <span class="text-2xs uppercase tracking-wide">Close</span>
    </span>
  </button>
  <div class="h-full">
    <div
      class="fixed inset-0 z-40 flex transform transition duration-300 ease-in-out md:relative md:h-full md:shrink-0"
      :class="[
        isActive ? 'translate-x-0' : '-translate-x-full md:translate-x-0'
      ]"
    >
      <div class="fixed inset-0 flex flex-col md:relative md:h-full md:w-64">
        <div
          class="fixed inset-0 bg-gray-600 opacity-75 md:hidden"
          @click="closeMenu"
          v-show="isActive"
        ></div>
        <div class="relative h-0 w-full max-w-xs flex-1 flex-col md:flex">
          <div class="flex h-full flex-1 flex-col overflow-y-auto">
            <nav class="flex-1 space-y-1 bg-gray-800 px-2 py-4">
              <router-link
                :to="{ name: 'AdminHome' }"
                v-slot="{ navigate, href, isExactActive }"
              >
                <a
                  @click="navigate"
                  :href="href"
                  :class="[
                    isExactActive
                      ? 'bg-gray-900 text-white'
                      : 'text-gray-300 hover:bg-gray-700 hover:text-white focus:bg-gray-700'
                  ]"
                  class="group flex items-center rounded-md px-2 py-2 text-sm font-medium leading-5 transition duration-150 ease-in-out focus:outline-none"
                >
                  <svg
                    class="mr-3 h-6 w-6 text-gray-300 transition duration-150 ease-in-out group-hover:text-gray-300 group-focus:text-gray-300"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"
                    />
                  </svg>
                  Dashboard
                </a>
              </router-link>
              <router-link
                :to="{ name: 'PagesIndex' }"
                v-slot="{ isActive, navigate, href }"
                v-if="permissions.includes('pages') || isSuperAdmin"
              >
                <a
                  @click="navigate"
                  :href="href"
                  :class="[
                    isActive
                      ? 'bg-gray-900 text-white'
                      : 'text-gray-300 hover:bg-gray-700 hover:text-white focus:bg-gray-700'
                  ]"
                  class="group flex items-center rounded-md px-2 py-2 text-sm font-medium leading-5 transition duration-150 ease-in-out focus:outline-none"
                >
                  <svg
                    class="mr-3 h-6 w-6 text-gray-400 transition duration-150 ease-in-out group-hover:text-gray-300 group-focus:text-gray-300"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M7 21h10a2 2 0 002-2V9.414a1 1 0 00-.293-.707l-5.414-5.414A1 1 0 0012.586 3H7a2 2 0 00-2 2v14a2 2 0 002 2z"
                    />
                  </svg>
                  Pages
                </a>
              </router-link>
              <router-link
                :to="{ name: 'PostsIndex' }"
                v-slot="{ isActive, navigate, href }"
                v-if="permissions.includes('posts') || isSuperAdmin"
              >
                <a
                  @click="navigate"
                  :href="href"
                  :class="[
                    isActive
                      ? 'bg-gray-900 text-white'
                      : 'text-gray-300 hover:bg-gray-700 hover:text-white focus:bg-gray-700'
                  ]"
                  class="group flex items-center rounded-md px-2 py-2 text-sm font-medium leading-5 transition duration-150 ease-in-out focus:outline-none"
                >
                  <svg
                    class="mr-3 h-6 w-6 text-gray-400 transition duration-150 ease-in-out group-hover:text-gray-300 group-focus:text-gray-300"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M8 7v8a2 2 0 002 2h6M8 7V5a2 2 0 012-2h4.586a1 1 0 01.707.293l4.414 4.414a1 1 0 01.293.707V15a2 2 0 01-2 2h-2M8 7H6a2 2 0 00-2 2v10a2 2 0 002 2h8a2 2 0 002-2v-2"
                    />
                  </svg>
                  Posts
                </a>
              </router-link>
              <router-link
                :to="{ name: 'Tags' }"
                v-slot="{ isActive, navigate, href }"
                v-if="permissions.includes('tags') || isSuperAdmin"
              >
                <a
                  @click="navigate"
                  :href="href"
                  :class="[
                    isActive
                      ? 'bg-gray-900 text-white'
                      : 'text-gray-300 hover:bg-gray-700 hover:text-white focus:bg-gray-700'
                  ]"
                  class="group flex items-center rounded-md px-2 py-2 text-sm font-medium leading-5 transition duration-150 ease-in-out focus:outline-none"
                >
                  <svg
                    class="mr-3 h-6 w-6 text-gray-400 transition duration-150 ease-in-out group-hover:text-gray-300 group-focus:text-gray-300"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M7 7h.01M7 3h5c.512 0 1.024.195 1.414.586l7 7a2 2 0 010 2.828l-7 7a2 2 0 01-2.828 0l-7-7A1.994 1.994 0 013 12V7a4 4 0 014-4z"
                    />
                  </svg>
                  Tags
                </a>
              </router-link>
              <router-link
                :to="{ name: 'Media' }"
                v-slot="{ isActive, navigate, href }"
                v-if="permissions.includes('media') || isSuperAdmin"
              >
                <a
                  @click="navigate"
                  :href="href"
                  :class="[
                    isActive
                      ? 'bg-gray-900 text-white'
                      : 'text-gray-300 hover:bg-gray-700 hover:text-white focus:bg-gray-700'
                  ]"
                  class="group flex items-center rounded-md px-2 py-2 text-sm font-medium leading-5 transition duration-150 ease-in-out focus:outline-none"
                >
                  <svg
                    class="mr-3 h-6 w-6 text-gray-400 transition duration-150 ease-in-out group-hover:text-gray-300 group-focus:text-gray-300"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z"
                    />
                  </svg>
                  Media
                </a>
              </router-link>
              <router-link
                :to="{ name: 'Menus' }"
                v-slot="{ isActive, navigate, href }"
                v-if="permissions.includes('menus') || isSuperAdmin"
              >
                <a
                  @click="navigate"
                  :href="href"
                  :class="[
                    isActive
                      ? 'bg-gray-900 text-white'
                      : 'text-gray-300 hover:bg-gray-700 hover:text-white focus:bg-gray-700'
                  ]"
                  class="group flex items-center rounded-md px-2 py-2 text-sm font-medium leading-5 transition duration-150 ease-in-out focus:outline-none"
                >
                  <svg
                    class="mr-3 h-6 w-6 text-gray-400 transition duration-150 ease-in-out group-hover:text-gray-300 group-focus:text-gray-300"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M4 6h16M4 12h16M4 18h7"
                    />
                  </svg>
                  Menus
                </a>
              </router-link>
              <router-link
                :to="{ name: 'AccordionsIndex' }"
                v-slot="{ isActive, navigate, href }"
                v-if="permissions.includes('accordions') || isSuperAdmin"
              >
                <a
                  @click="navigate"
                  :href="href"
                  :class="[
                    isActive
                      ? 'bg-gray-900 text-white'
                      : 'text-gray-300 hover:bg-gray-700 hover:text-white focus:bg-gray-700'
                  ]"
                  class="group flex items-center rounded-md px-2 py-2 text-sm font-medium leading-5 transition duration-150 ease-in-out focus:outline-none"
                >
                  <CollectionIcon
                    :class="[
                      isActive
                        ? 'bg-gray-900 text-white'
                        : 'text-gray-300 hover:bg-gray-700 hover:text-white focus:bg-gray-700',
                      'mr-4 inline-block h-5 w-5'
                    ]"
                    aria-hidden="true"
                  />
                  Accordions
                </a>
              </router-link>
              <router-link
                :to="{ name: 'PoliciesIndex' }"
                v-slot="{ isActive, navigate, href }"
                v-if="permissions.includes('policies') || isSuperAdmin"
              >
                <a
                  @click="navigate"
                  :href="href"
                  :class="[
                    isActive
                      ? 'bg-gray-900 text-white'
                      : 'text-gray-300 hover:bg-gray-700 hover:text-white focus:bg-gray-700'
                  ]"
                  class="group flex items-center rounded-md px-2 py-2 text-sm font-medium leading-5 transition duration-150 ease-in-out focus:outline-none"
                >
                  <svg
                    class="mr-3 h-6 w-6 text-gray-400 transition duration-150 ease-in-out group-hover:text-gray-300 group-focus:text-gray-300"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z"
                    />
                  </svg>
                  Policies
                </a>
              </router-link>
              <router-link
                :to="{ name: 'PositionsIndex' }"
                v-slot="{ isActive, navigate, href }"
                v-if="permissions.includes('positions') || isSuperAdmin"
              >
                <a
                  @click="navigate"
                  :href="href"
                  :class="[
                    isActive
                      ? 'bg-gray-900 text-white'
                      : 'text-gray-300 hover:bg-gray-700 hover:text-white focus:bg-gray-700'
                  ]"
                  class="group flex items-center rounded-md px-2 py-2 text-sm font-medium leading-5 transition duration-150 ease-in-out focus:outline-none"
                >
                  <svg
                    class="mr-3 h-6 w-6 text-gray-400 transition duration-150 ease-in-out group-hover:text-gray-300 group-focus:text-gray-300"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M21 13.255A23.931 23.931 0 0112 15c-3.183 0-6.22-.62-9-1.745M16 6V4a2 2 0 00-2-2h-4a2 2 0 00-2 2v2m4 6h.01M5 20h14a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                    />
                  </svg>
                  Employee Positions
                </a>
              </router-link>
              <router-link
                :to="{ name: 'Users' }"
                v-slot="{ isActive, navigate, href }"
                v-if="permissions.includes('users') || isSuperAdmin"
              >
                <a
                  @click="navigate"
                  :href="href"
                  :class="[
                    isActive
                      ? 'bg-gray-900 text-white'
                      : 'text-gray-300 hover:bg-gray-700 hover:text-white focus:bg-gray-700'
                  ]"
                  class="group flex items-center rounded-md px-2 py-2 text-sm font-medium leading-5 transition duration-150 ease-in-out focus:outline-none"
                >
                  <svg
                    class="mr-3 h-6 w-6 text-gray-400 transition duration-150 ease-in-out group-hover:text-gray-300 group-focus:text-gray-300"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z"
                    />
                  </svg>
                  Users
                </a>
              </router-link>
              <div class="border-t border-solid border-gray-700 pt-6">
                <router-link
                  :to="{ name: 'SiteConfig' }"
                  v-slot="{ isActive, navigate, href }"
                  v-if="permissions.includes('config') || isSuperAdmin"
                >
                  <a
                    @click="navigate"
                    :href="href"
                    :class="[
                      isActive
                        ? 'bg-gray-900 text-white'
                        : 'text-gray-300 hover:bg-gray-700 hover:text-white focus:bg-gray-700'
                    ]"
                    class="group flex items-center rounded-md px-2 py-2 text-sm font-medium leading-5 transition duration-150 ease-in-out focus:outline-none"
                  >
                    <svg
                      class="mr-3 h-6 w-6 text-gray-400 transition duration-150 ease-in-out group-hover:text-gray-300 group-focus:text-gray-300"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"
                      />
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                      />
                    </svg>
                    Site Configurations
                  </a>
                </router-link>
                <router-link
                  :to="{ name: 'Home' }"
                  class="mt-6 inline-block p-2 text-sm text-gray-300 hover:text-gray-100"
                >
                  <GlobeAltIcon
                    class="inline-block h-5 w-5"
                    aria-hidden="true"
                  />
                  <span class="ml-2">&larr; Back to site</span>
                </router-link>
              </div>
            </nav>
          </div>
        </div>
        <div class="w-14 shrink-0 md:hidden">
          <!-- Dummy element to force sidebar to shrink to fit close icon -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { GlobeAltIcon, CollectionIcon } from '@heroicons/vue/outline'
export default {
  components: { GlobeAltIcon, CollectionIcon },
  computed: {
    isActive() {
      return this.$store.state.menu
    },
    permissions() {
      if (this.$store.getters['User/permissions']) {
        return Object.keys(this.$store.getters['User/permissions'])
      }
      return []
    },
    isSuperAdmin() {
      return (
        this.$store.getters['User/role'] &&
        this.$store.getters['User/role'] === 'superadmin'
      )
    }
  },
  methods: {
    closeMenu() {
      this.$store.commit('toggleMenu', false)
    },
    toggleMenu() {
      this.$store.commit('toggleMenu', !this.isActive)
    }
  }
}
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
/* Entering: "transition ease-in-out duration-300 transform"
  From: "-translate-x-full"
  To: "translate-x-0"
Leaving: "transition ease-in-out duration-300 transform"
  From: "translate-x-0"
  To: "-translate-x-full" */
</style>
