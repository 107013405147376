<template>
  <div class="flex items-center">
    <Select
      v-model="active"
      :options="menus"
      optionsDisplay="name"
      class="flex-1"
      label="Active Menu"
      inlineLabel
    />
    <button
      @click="create"
      type="button"
      class="mb-5 inline-flex items-center whitespace-nowrap rounded-md border border-transparent bg-gray-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
    >
      New Menu
    </button>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'MenuSelector',
  data: () => ({
    active: ''
  }),
  computed: {
    ...mapGetters({
      menus: 'AdminMenus/getMenus',
      activeMenu: 'AdminMenus/getActive'
    })
  },
  methods: {
    create() {
      const found = this.menus.filter((item) => item.name.includes('New Menu'))
      const name = found ? `New Menu ${found.length}` : `New Menu`
      const newMenu = {
        name,
        items: []
      }
      this.$store.commit('AdminMenus/addMenu', newMenu)
      this.$store.commit('AdminMenus/setActive', newMenu)
    }
  },
  watch: {
    active(value) {
      this.$store.commit('AdminMenus/setActive', this.$_.cloneDeep(value))
    },
    activeMenu(value) {
      if (!this.$_.isEqual(this.active, value)) {
        this.active = this.$_.cloneDeep(value)
      }
    }
  }
}
</script>
