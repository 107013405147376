<template>
  <div class="pt-2 pb-6 md:py-6">
    <div class="mx-auto mb-6 max-w-7xl px-4 sm:px-6 md:px-8">
      <h1 class="text-2xl font-semibold text-gray-900">Employee Positions</h1>
    </div>
    <DataControls view="AdminPositions" />
    <div class="mx-auto max-w-7xl px-4 sm:px-6 md:px-8">
      <div class="mb-4 flex items-center justify-between">
        <Paginator
          :from="from"
          :to="to"
          :total="totalPositions"
          position="top"
          @next="next"
          @prev="prev"
          hideButtons
          v-show="totalPositions"
        />
        <span class="inline-flex rounded-md shadow-sm">
          <router-link
            :to="{ name: 'PositionsCreate' }"
            tag="button"
            class="focus:shadow-outline-gray inline-flex items-center rounded-md border border-transparent bg-gray-600 px-4 py-2 text-sm font-medium leading-5 text-white transition duration-150 ease-in-out hover:bg-gray-500 focus:border-gray-700 focus:outline-none active:bg-gray-700"
          >
            Create
          </router-link>
        </span>
      </div>
      <div class="overflow-hidden bg-white shadow sm:rounded-md">
        <div class="p-4 italic text-gray-500" v-if="formatted.length < 1">
          No pages found.
        </div>
        <ul>
          <li
            v-for="(position, index) in formatted"
            :key="index"
            :class="[index > 0 ? 'border-t border-gray-200' : '']"
          >
            <router-link
              :to="{
                name: 'PositionsEdit',
                params: { id: position.id, position }
              }"
              class="flex items-center justify-between transition duration-150 ease-in-out hover:bg-gray-50 focus:bg-gray-50 focus:outline-none"
            >
              <div
                class="block flex-1 items-center justify-between px-4 py-3 text-sm leading-6 text-gray-700 sm:px-6 lg:flex"
              >
                <div>
                  <div>
                    <span
                      class="mr-2 text-xs font-light uppercase text-gray-400"
                      >Title</span
                    >
                    <span class="font-semibold text-gray-900">{{
                      position.title
                    }}</span>
                  </div>
                  <div>
                    <span
                      class="mr-2 text-xs font-light uppercase text-gray-400"
                      >Deptartment</span
                    >
                    <span>{{ position.department }}</span>
                  </div>
                </div>
                <div>
                  <div>
                    <span
                      class="mr-2 text-xs font-light uppercase text-gray-400"
                      >created</span
                    >
                    <span>{{ position.created }}</span>
                  </div>
                  <div>
                    <span
                      class="mr-2 text-xs font-light uppercase text-gray-400"
                      >Last Updated</span
                    >
                    <span>{{ position.updated }}</span>
                  </div>
                </div>
              </div>
              <div class="pr-4">
                <svg
                  class="h-5 w-5 text-gray-400"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fill-rule="evenodd"
                    d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                    clip-rule="evenodd"
                  />
                </svg>
              </div>
            </router-link>
          </li>
        </ul>
      </div>
      <Paginator
        class="border-b border-gray-200"
        :from="from"
        :to="to"
        :total="totalPositions"
        @next="next"
        @prev="prev"
        v-show="totalPositions"
      />
    </div>
    <div
      class="mx-auto my-4 flex max-w-7xl justify-between px-4 sm:px-6 md:px-8"
    >
      <div class="flex items-center">
        <label
          for="items"
          class="mr-2 block text-sm font-medium leading-5 text-gray-700 sm:mt-px"
        >
          items
        </label>
        <div class="mt-1 sm:col-span-2 sm:mt-0">
          <div class="max-w-lg rounded-md shadow-sm sm:max-w-xs">
            <select
              v-model="itemsPerPage"
              id="items"
              class="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
            >
              <option>10</option>
              <option>20</option>
              <option>50</option>
            </select>
          </div>
        </div>
      </div>
      <span class="inline-flex rounded-md shadow-sm">
        <router-link
          :to="{ name: 'PositionsCreate' }"
          tag="button"
          class="focus:shadow-outline-gray inline-flex items-center rounded-md border border-transparent bg-gray-600 px-4 py-2 text-sm font-medium leading-5 text-white transition duration-150 ease-in-out hover:bg-gray-500 focus:border-gray-700 focus:outline-none active:bg-gray-700"
        >
          Create
        </router-link>
      </span>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import DataControls from '@/adminInterface/components/utilities/DataControls'
import Paginator from '@/adminInterface/components/utilities/Paginator'
export default {
  title: 'Positions - iKTA',
  name: 'PositionsIndex',
  components: { DataControls, Paginator },
  created() {
    this.$store.dispatch('AdminPositions/getQuery')
  },
  methods: {
    update(value) {
      this.results = value
    },
    next() {
      if (this.to < this.totalPositions) {
        this.$store.commit('AdminPositions/nextPage')
        this.$store.dispatch('AdminPositions/getQuery')
      }
    },
    prev() {
      if (this.offset > 0) {
        this.$store.commit('AdminPositions/prevPage')
        this.$store.dispatch('AdminPositions/getQuery')
      }
    }
  },
  computed: {
    ...mapGetters({
      page: 'AdminPositions/page',
      offset: 'AdminPositions/offset',
      errors: 'getErrors',
      totalPositions: 'AdminPositions/count',
      to: 'AdminPositions/to',
      from: 'AdminPositions/from',
      currentFilter: 'AdminPositions/filter'
    }),
    positions() {
      return this.$_.cloneDeep(this.$store.getters['AdminPositions/get'])
    },
    itemsPerPage: {
      get() {
        return this.$store.getters['AdminPositions/limit']
      },
      set(value) {
        this.$store.commit('AdminPositions/setLimit', value)
      }
    },
    formatted() {
      return this.positions.map((position) => {
        position.created = this.$date.format(
          new Date(position.created),
          'MM/dd/yyyy h:mm a'
        )
        position.updated = this.$date.format(
          new Date(position.updated),
          'MM/dd/yyyy h:mm a'
        )
        return position
      })
    }
  },
  watch: {
    itemsPerPage(value) {
      this.$store.commit('AdminPositions/setLimit', value)
      this.$store.dispatch('AdminPositions/getQuery')
    }
  }
}
</script>
