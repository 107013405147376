export default {
  get(state) {
    return state.posts
  },
  latest(state) {
    return state.latest
      .slice()
      .sort((a, b) => (a.published < b.published ? 1 : -1))
  },
  archives(state) {
    return state.archives
  },
  archive(state) {
    return state.archive
  },
  news(state) {
    return state.news
  },
  pinned(state) {
    return state.pinned
  },
  count(state) {
    return state.count
  },
  offset(state) {
    return state.offset
  },
  limit(state) {
    return state.limit
  },
  page(state) {
    return state.page
  },
  from(state) {
    return state.offset + 1
  },
  to(state) {
    return state.limit > state.count
      ? state.count
      : state.limit * state.page > state.count
      ? state.count
      : state.limit * state.page
  },
  filter(state) {
    return state.filter
  },
  filterOptions(state) {
    return state.filterOptions
  },
  filterBy(state) {
    return state.filterBy
  },
  filterByOptions(state, getters, rootState, rootGetters) {
    return state.filter === 'tags'
      ? rootGetters['Tags/getAll'].map((tag) => tag.name)
      : null
  },
  order(state) {
    return state.order
  },
  orderBy(state) {
    return state.orderBy
  },
  orderOptions(state) {
    return state.orderOptions
  }
}
