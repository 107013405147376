import axios from 'axios'

export default {
  async getAll({ commit, rootGetters }) {
    const { data } = await axios.get(
      `${process.env.VUE_APP_API_URL}/tags`,
      rootGetters['User/config']
    )
    commit('set', data)
  },

  async create({ commit, rootGetters }, newTags) {
    const temp = newTags.replace(/,\s+/g, ',')
    const tags = temp.split(',')
    try {
      const { data } = await axios.post(
        `${process.env.VUE_APP_API_URL}/tags`,
        { tags },
        rootGetters['User/config']
      )
      commit('add', data)
    } catch (error) {
      commit('handleErrors', error.response.data, { root: true })
    }
  },

  async update({ commit, rootGetters }, tag) {
    try {
      const { data } = await axios.patch(
        `${process.env.VUE_APP_API_URL}/tags/${tag.id}`,
        { name: tag.name },
        rootGetters['User/config']
      )
      commit('update', data)
    } catch (error) {
      commit('handleErrors', error.response.data, { root: true })
    }
  },

  async remove({ commit, rootGetters }, id) {
    try {
      await axios.delete(
        `${process.env.VUE_APP_API_URL}/tags/${id}`,
        rootGetters['User/config']
      )
      commit('remove', id)
    } catch (error) {
      commit('handleErrors', error.response.data, { root: true })
    }
  }
}
