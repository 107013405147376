<template>
  <div
    class="mt-6 rounded-md border-solid border-gray-600 bg-white p-4 shadow-md"
  >
    <h2 class="text-grey-900 mb-2 text-xl font-bold">Edit File</h2>
    <form class="flex items-start" @submit.prevent="update">
      <div class="mr-2 flex-1">
        <Input
          v-model="form.name"
          label="Title"
          :helpText="`${form.filename} (filename)`"
        />
      </div>
      <div class="mr-2 flex-1">
        <label class="block text-sm font-medium leading-5 text-gray-700"
          >Folder</label
        >
        <div class="mt-1 rounded-md shadow-sm">
          <select
            v-model="form.folderId"
            class="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
          >
            <option
              v-for="folder in folders"
              :key="folder.id"
              :value="folder.id"
            >
              {{ folder.title }}
            </option>
          </select>
        </div>
        <span class="text-xs italic text-gray-500"
          >{{ currentFolder }} (current folder)</span
        >
      </div>

      <div class="my-6 flex justify-start">
        <span class="inline-flex rounded-md shadow-sm">
          <button
            @click="update"
            type="button"
            class="focus:shadow-outline-gray mr-2 inline-flex items-center rounded-md border border-transparent bg-gray-600 px-4 py-2 text-sm font-medium leading-5 text-white transition duration-150 ease-in-out hover:bg-gray-500 focus:border-gray-700 focus:outline-none active:bg-gray-700"
          >
            Update
          </button>
        </span>
        <span class="inline-flex rounded-md shadow-sm">
          <button
            @click="close"
            type="button"
            class="focus:shadow-outline-blue inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium leading-5 text-gray-700 transition duration-150 ease-in-out hover:text-gray-500 focus:border-blue-300 focus:outline-none active:bg-gray-50 active:text-gray-800"
          >
            Cancel
          </button>
        </span>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  mounted() {
    this.form = this.$_.cloneDeep(this.file)
  },
  props: {
    file: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    form: {}
  }),
  computed: {
    folders() {
      return this.$store.getters['Media/getFolders']
    },
    currentFolder() {
      return this.folders.find((folder) => folder.id === this.file.folderId)
        .title
    }
  },
  methods: {
    async update() {
      let data = {}
      if (this.form.name !== this.file.name) data.name = this.form.name
      if (this.form.folderId !== this.file.folderId)
        data.folderId = this.form.folderId

      this.$store.dispatch('Media/updateFile', { id: this.file.id, data })
      this.close()
    },
    close() {
      this.$emit('close')
    }
  }
}
</script>

<style></style>
