import actions from './actions'
import mutations from './mutations'
import getters from './getters'

export default {
  namespaced: true,
  state: () => ({
    showFileUploader: false,
    showFilePicker: false,
    activeFolder: null,
    files: [],
    folders: [],
    sortFiles: {
      by: 'added',
      direction: 'DESC'
    }
  }),
  mutations,
  getters,
  actions
}
