import axios from 'axios'
import router from '@/router'

export default {
  async getAll({ commit, rootGetters }) {
    try {
      const { data } = await axios.get(
        `${process.env.VUE_APP_API_URL}/positions`,
        rootGetters['User/config']
      )
      commit('set', data)
      return data
    } catch (error) {
      commit('handleErrors', error.response.data, { root: true })
    }
  },
  async getQuery({ state, rootGetters, commit }) {
    try {
      let query = state.offset
        ? `?limit=${state.limit}&offset=${state.offset}`
        : `?limit=${state.limit}`
      query =
        state.filter && state.filterBy
          ? `${query}&filter=${state.filter}&filterBy=${state.filterBy}`
          : query
      query =
        state.orderBy.key !== 'title' || state.order !== 'ASC'
          ? `${query}&order=${state.order}&orderBy=${state.orderBy.key}`
          : query
      const { data } = query
        ? await axios.get(
            `${process.env.VUE_APP_API_URL}/positions${query}`,
            rootGetters['User/config']
          )
        : await axios.get(
            `${process.env.VUE_APP_API_URL}/positions`,
            rootGetters['User/config']
          )
      commit('set', data)
    } catch (errors) {
      commit('handleErrors', errors.response.data, { root: true })
    }
  },

  async get({ commit, rootGetters }, id) {
    try {
      const { data } = await axios.get(
        `${process.env.VUE_APP_API_URL}/positions/${id}`,
        rootGetters['User/config']
      )
      return data
    } catch (errors) {
      commit('handleErrors', errors.response.data, { root: true })
    }
  },

  async create({ commit, rootGetters }, params) {
    try {
      await axios.post(
        `${process.env.VUE_APP_API_URL}/positions`,
        params,
        rootGetters['User/config']
      )
      commit(
        'displayAlert',
        {
          position: 'top',
          level: 'success',
          title: 'Position created.',
          timer: 3000
        },
        { root: true }
      )
      router.push({ name: 'PositionsIndex' })
    } catch (errors) {
      commit('handleErrors', errors.response.data, { root: true })
    }
  },

  async update({ commit, rootGetters }, params) {
    try {
      await axios.patch(
        `${process.env.VUE_APP_API_URL}/positions/${params.get('id')}`,
        params,
        rootGetters['User/config']
      )
      commit(
        'displayAlert',
        {
          position: 'top',
          level: 'success',
          title: 'Position updated.',
          timer: 3000
        },
        { root: true }
      )
      router.push({ name: 'PositionsIndex' })
    } catch (errors) {
      commit('handleErrors', errors.response.data, { root: true })
    }
  },

  async delete({ commit, rootGetters }, id) {
    try {
      await axios.delete(
        `${process.env.VUE_APP_API_URL}/positions/${id}`,
        rootGetters['User/config']
      )
      commit(
        'displayAlert',
        {
          position: 'top',
          level: 'success',
          title: 'Position deleted.',
          timer: 3000
        },
        { root: true }
      )
    } catch (errors) {
      commit('handleErrors', errors.response.data, { root: true })
    }
  }
}
