import actions from './actions'
import mutations from './mutations'
import getters from './getters'

export default {
  namespaced: true,
  state: () => ({
    menus: [],
    active: null,
    original: null,
    activeItemIndex: null,
    activeItem: {
      name: '',
      menuType: null,
      url: '',
      newTab: false,
      external: false,
      items: []
    }
  }),
  mutations,
  getters,
  actions
}
