<template>
  <main
    class="relative z-10 mx-auto w-full max-w-7xl flex-1 py-12 px-4 sm:px-6 lg:py-16 lg:px-8"
  >
    <ComponentHeader component="opportunities" />
    <div v-if="hasOpportunities">
      <div
        class="mb-8"
        v-for="opportunity in opportunities"
        :key="opportunity.jobId"
      >
        <div class="mb-2">
          <span
            class="text-sm font-bold uppercase tracking-wide text-red-600"
            v-if="opportunity.isPrivate"
            >internal only</span
          >
          <h2 class="text-2xl font-bold text-kta-blue-900">
            {{ opportunity.title }}
          </h2>
          <div class="text-sm text-gray-600">
            {{ opportunity.locationName }} • {{ opportunity.department }} •
            Posted on {{ opportunity.posted }} • {{ opportunity.wageType }} •
            Pay Grade
            {{ opportunity.Attributes[4].value }}
          </div>
        </div>
        <div v-html="opportunity.summary"></div>
        <router-link
          :to="createLink(opportunity)"
          class="my-4 inline-block text-sm font-semibold tracking-wide text-kta-blue-500 underline"
        >
          Read More
        </router-link>
      </div>
    </div>
    <div class="italic" v-else>
      There are currently no available opportunities.
    </div>
  </main>
</template>
<script>
import ComponentHeader from '@/clientInterface/components/layout/ComponentHeader'
export default {
  name: 'Opportunities',
  components: { ComponentHeader },
  created() {
    this.$store.dispatch('Opportunities/getAll')
  },
  computed: {
    opportunities() {
      return this.$store.getters['Opportunities/getAll']
    },
    hasOpportunities() {
      return !!this.opportunities.length
    }
  },
  methods: {
    createLink(opportunity) {
      const jobName = opportunity.title.toLowerCase().replace(/[\W_]/g, '-')
      return `/opportunities/${jobName}`
    }
  }
}
</script>
