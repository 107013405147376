<template>
  <div class="pt-2 pb-6 md:py-6">
    <div class="mx-auto mb-4 max-w-7xl px-4 sm:px-6 md:px-8">
      <h1 class="text-2xl font-semibold text-gray-900">{{ pageTitle }}</h1>
    </div>
    <div class="mx-auto max-w-7xl px-4 sm:px-6 md:px-8">
      <form @submit.prevent>
        <div>
          <div class="items-start lg:flex lg:space-x-3">
            <Input
              class="flex-1"
              v-model="form.title"
              label="Title"
              :error="errors.title"
              errorKey="title"
            />
            <Input
              v-model="form.slug"
              class="flex-1"
              label="Slug"
              :error="errors.slug"
              errorKey="slug"
              @input="updateSlug"
            />
          </div>
          <div>
            <label
              class="mb-1 block text-sm font-medium leading-5 text-gray-700"
              >Summary/Preview</label
            >
            <Wysiwyg
              v-model="form.summary"
              :height="4"
              :error="errors.summary"
              class="mb-3"
            />
            <label
              class="mb-1 block text-sm font-medium leading-5 text-gray-700"
              >Body</label
            >
            <Wysiwyg
              v-model="form.body"
              :height="12"
              :error="errors.body"
              class="mb-3"
            />
            <TagInput
              :tags="form.tags"
              @add="addTag"
              @remove="removeTag"
              :error="errors.tags"
            />
          </div>
          <div class="md:flex md:space-x-3">
            <DateSelector
              class="flex-1"
              v-model="form.published"
              label="Published"
              includeSwitch
              includeTime
            />
            <DateSelector
              class="flex-1"
              v-model="form.expires"
              label="Expiration"
              includeSwitch
              includeTime
            />
          </div>
          <div class="md:flex md:space-x-3">
            <DateSelector
              class="flex-1"
              v-model="form.latestUntil"
              label="Latest Until"
              includeSwitch
              includeTime
            />
            <DateSelector
              class="flex-1"
              v-model="form.archived"
              includeSwitch
              label="Archive"
              includeTime
            />
          </div>
        </div>
        <Checkbox
          name="important"
          label="Important Post"
          description="Add the <span class='inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-red-100 text-red-800 uppercase'>important</span> flag to the post."
          v-model="form.important"
        />
        <Checkbox
          class="mt-4"
          name="pinned"
          label="Pin Post"
          description="Pinning a post will keep it at the top of the Latest News section."
          v-model="form.pinned"
          :disabled="!pinnable"
        />
        <div class="mt-8 flex justify-between border-t border-gray-200 pt-5">
          <span class="inline-flex rounded-md shadow-sm" v-if="editing">
            <button
              type="button"
              @click="remove"
              class="focus:shadow-outline-red inline-flex justify-center rounded-md border border-transparent bg-red-600 py-2 px-4 text-sm font-medium leading-5 text-white transition duration-150 ease-in-out hover:bg-red-500 focus:border-red-700 focus:outline-none active:bg-red-700"
            >
              Delete
            </button>
          </span>
          <div class="flex flex-1 justify-between">
            <div class="flex flex-1 justify-end">
              <span class="inline-flex rounded-md shadow-sm">
                <button
                  type="button"
                  class="focus:shadow-outline-blue rounded-md border border-gray-300 py-2 px-4 text-sm font-medium leading-5 text-gray-700 transition duration-150 ease-in-out hover:text-gray-500 focus:border-blue-300 focus:outline-none active:bg-gray-50 active:text-gray-800"
                  @click="cancel"
                >
                  Cancel
                </button>
              </span>
              <span class="ml-3 inline-flex rounded-md shadow-sm">
                <button
                  type="submit"
                  class="focus:shadow-outline-gray inline-flex justify-center rounded-md border border-transparent bg-gray-600 py-2 px-4 text-sm font-medium leading-5 text-white transition duration-150 ease-in-out hover:bg-gray-500 focus:border-gray-700 focus:outline-none active:bg-gray-700"
                  @click="submit"
                  v-text="editing ? 'Update' : 'Save'"
                ></button>
              </span>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
  <ConfirmDialog :isOpen="confirm" @confirmed="confirmed" />
</template>

<script>
import { mapGetters } from 'vuex'
import Wysiwyg from '@/adminInterface/components/form/Wysiwyg'
import TagInput from '@/adminInterface/components/form/TagInput'
import DateSelector from '@/adminInterface/components/form/DateSelector'
import ConfirmDialog from '@/adminInterface/components/utilities/ConfirmDialog'
export default {
  name: 'CreateEditPost',
  components: { Wysiwyg, TagInput, DateSelector, ConfirmDialog },
  async created() {
    if (this.$store.getters['AdminTags/getAll'].length < 1)
      await this.$store.dispatch('AdminTags/getAll')
    if (!this.$store.getters['AdminPosts/getPinned'])
      await this.$store.dispatch('AdminPosts/getPinned')
    if (this.$route.params.id) {
      this.pageTitle = 'Edit Post'
      this.loadData()
    }
  },
  data() {
    return {
      pageTitle: 'Create Post',
      confirm: false,
      form: {
        title: '',
        slug: '',
        body: '',
        published: null,
        expiration: null,
        latestUntil: null,
        archived: null,
        important: false,
        tags: []
      },
      original: {},
      dynamicSlug: true
    }
  },
  methods: {
    async loadData() {
      if (this.editing) {
        this.dynamicSlug = false
        this.form = await this.$store.dispatch(
          'AdminPosts/get',
          this.$route.params.id
        )
        this.original = this.$_.cloneDeep(this.form)
      }
    },
    submit() {
      this.editing ? this.update() : this.create()
    },
    async create() {
      let data = this.$_.cloneDeep(this.form)

      for (const prop in data) {
        if (
          ['published', 'expires', 'latestUntil', 'archived'].includes(prop) &&
          data[prop] === ''
        ) {
          data[prop] = null
        }
        if (prop === 'tags' && data.tags.length) {
          data[prop] = data[prop].map((tag) => tag.name)
        }
      }
      await this.$store.dispatch('AdminPosts/create', data)
    },
    async update() {
      let changed = {}
      const original = this.$_.cloneDeep(this.original)
      for (const prop in original) {
        if (!this.$_.isEqual(this.form[prop], original[prop])) {
          changed[prop] = this.form[prop]
          if (
            ['published', 'expires', 'latestUntil', 'archived'].includes(prop)
          ) {
            changed[prop] = changed[prop] === '' ? null : changed[prop]
          }
          if (prop == 'tags') {
            changed[prop] = changed[prop].map((tag) => tag.name)
          }
        }
      }
      changed.id = original.id
      await this.$store.dispatch('AdminPosts/update', changed)
    },
    remove() {
      this.confirm = true
    },
    async confirmed(confirmed) {
      if (confirmed) {
        await this.$store.dispatch('AdminPosts/delete', this.$route.params.id)
      }
      this.confirm = false
    },
    cancel() {
      this.$router.push({ name: 'PostsIndex' })
    },
    addTag(tag) {
      this.form.tags.push(tag)
    },
    removeTag({ name }) {
      this.form.tags = this.form.tags.filter((tag) => tag.name !== name)
    },
    updateSlug(value, event) {
      if (this.dynamicSlug) this.dynamicSlug = false
      if (event.data === ' ' || event.data === '-') {
        return (this.form.slug = event.target.value.replaceAll(/\s/g, '-'))
      }
      this.form.slug = this.$_.kebabCase(event.target.value)
      this.$forceUpdate()
    }
  },
  computed: {
    ...mapGetters({
      posts: 'AdminPosts/get',
      tags: 'AdminTags/getAll',
      pinned: 'AdminPosts/pinned'
    }),
    editing() {
      return !!this.$route.params.id
    },
    errors() {
      return this.$store.getters.getErrors
    },
    isPinned() {
      return this.pinned?.id === +this.$route.params.id
    },
    pinnable() {
      return !this.pinned || this.isPinned
    }
  },
  watch: {
    form: {
      deep: true,
      handler(value) {
        if (value?.title && this.dynamicSlug) {
          this.form.slug = this.$_.kebabCase(value.title)
        }
        if (this.errors) {
          for (const prop in this.errors) {
            if (value[prop].length > 0) {
              this.$store.commit('removeError', prop)
            }
          }
        }
      }
    }
  }
}
</script>
