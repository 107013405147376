<template>
  <div
    :class="[
      menuOpen ? 'inset-0 bg-gray-800' : 'inset-x-0 top-0 mb-8',
      !insideHeader || menuOpen ? '' : 'bg-gray-50',
      'fixed z-30 overflow-y-scroll'
    ]"
  >
    <div
      :class="[
        !insideHeader || menuOpen
          ? 'text-white text-shadow-xl'
          : 'text-gray-500 hover:text-gray-700',
        'inline-flex cursor-pointer items-center p-6 font-semibold tracking-wider'
      ]"
      @click="menuOpen = !menuOpen"
    >
      <MenuIcon
        v-if="!menuOpen"
        :class="[
          !insideHeader && !menuOpen ? 'drop-shadow-sm-dark' : '',
          'block h-6 w-6'
        ]"
        aria-hidden="true"
      />
      <XIcon v-else class="block h-6 w-6" aria-hidden="true" />
      <span class="ml-2" v-text="menuOpen ? 'Close' : 'Menu'"></span>
    </div>
    <div
      class="mx-auto max-w-7xl justify-between px-6 text-gray-100 lg:flex"
      v-if="menuOpen"
    >
      <div v-for="(item, index) in menu" :key="index">
        <router-link
          :to="item.url"
          @click="menuOpen = false"
          class="mb-5 inline-block lg:font-bold"
          v-if="item.menuType !== 'text' && !item.external"
          >{{ item.name }}</router-link
        >
        <a
          :href="item.url"
          @click="menuOpen = false"
          class="mb-5 inline-block lg:font-bold"
          v-if="item.menuType !== 'text' && item.external"
          :target="{ _blank: item.newTab }"
          >{{ item.name }}</a
        >
        <span
          class="mb-5 inline-block lg:font-bold"
          v-if="item.menuType === 'text'"
          >{{ item.name }}</span
        >
        <span v-if="item.items.length > 0">
          <hr class="mb-5 hidden w-6 border-t-2 lg:block" />
          <router-link
            :to="item.url"
            exact
            @click="menuOpen = false"
            class="ml-6 mb-5 block lg:ml-0"
            v-for="(item, index) in item.items"
            :key="index"
            >{{ item.name }}</router-link
          >
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { MenuIcon, XIcon } from '@heroicons/vue/outline'
export default {
  name: 'HeaderNav',
  components: {
    MenuIcon,
    XIcon
  },
  created() {
    this.$store.dispatch('Menus/getMenus')
    this.observer = new IntersectionObserver(this.observeAction, {
      rootMargin: '-40px 0px 0px 0px'
    })
    this.observer.observe(this.header)
  },
  props: {
    header: {
      type: Object,
      required: false,
      default: null
    }
  },
  data: () => ({
    menuOpen: false,
    insideHeader: true
  }),
  computed: {
    menu() {
      return this.$store.getters['Menus/main']
    }
  },
  methods: {
    observeAction(entry) {
      this.insideHeader = entry[0].isIntersecting ? false : true
    },
    addObservers() {
      this.$nextTick(() => {
        this.observer.observe(this.header)
      })
    }
  },
  beforeUnmount() {
    this.observer.disconnect()
  },
  watch: {
    header() {
      this.addObservers()
    },
    $route() {
      this.menuOpen = false
      window.scrollTo(0, 0)
    }
  }
}
</script>
<style scoped>
.header-text-shadow {
  text-shadow: 0 0 6px rgb(0 0 0 / 60%);
}
</style>
