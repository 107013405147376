export default {
  getMenus(state) {
    return state.menus
  },

  // eslint-disable-next-line no-unused-vars
  main(state, getters, rootState, rootGetters) {
    return state.menus.find(
      (menu) => menu.name === rootState.config.mainNavMenu
    )?.items
  },

  // eslint-disable-next-line no-unused-vars
  footer(state, getters, rootState, rootGetters) {
    return state.menus.find(
      (menu) => menu.name === rootState.config.footerNavMenu
    )?.menuItems
  }
}
