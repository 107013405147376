<template>
  <div class="flex h-screen overflow-hidden bg-gray-100">
    <Sidebar />
    <div class="flex w-0 flex-1 flex-col overflow-hidden">
      <main class="relative flex-1 overflow-y-auto focus:outline-none">
        <router-view />
      </main>
    </div>
  </div>
  <FileUploader v-if="uploadFile" />
  <Alert />
</template>
<script>
import Sidebar from '@/adminInterface/components/layout/Sidebar'
import FileUploader from '@/adminInterface/components/media/FileUploader'
import Alert from '@/adminInterface/components/Alert'
export default {
  name: 'Admin',
  components: {
    Alert,
    Sidebar,
    FileUploader
  },
  computed: {
    uploadFile() {
      return this.$store.state.Media.showFileUploader
    },
    errors() {
      return this.$store.getters.getErrors
    }
  },
  watch: {
    $route() {
      if (this.errors) this.$store.commit('removeErrors')
    }
  }
}
</script>
