export default {
  set(state, policies) {
    state.policies = policies.items
    state.count = policies.count
  },
  setSections(state, sections) {
    state.sections = sections
  },
  add(state, policy) {
    state.policies.push(policy)
  },
  update(state, update) {
    let index = state.policies.findIndex((policy) => policy.id === update.id)
    state.policies.splice(index, 1, update)
  },
  remove(state, id) {
    let index = state.policies.findIndex((policy) => policy.id === id)
    state.policies.splice(index, 1)
  },
  removeSection(state, id) {
    state.sections = state.sections.filter((section) => section.id !== id)
  },
  setFilter(state, filter) {
    state.filter = filter === 'all' ? null : filter
  },
  setFilterBy(state, filter) {
    state.filterBy = filter !== 'All' ? filter : null
  },
  setOrder(state, order) {
    state.order = order
  },
  setOrderBy(state, order) {
    state.orderBy = order
  },
  nextPage(state) {
    state.page++
    state.offset += state.limit
  },
  prevPage(state) {
    state.page--
    state.offset -= state.limit
  },
  setLimit(state, value) {
    state.limit = value
  }
}
