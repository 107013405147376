export default {
  get(state) {
    return state.items
  },

  formatted(state) {
    return state.items
      .filter((item) => item.lastName)
      ?.map((item) => {
        item.mobile = item.mobile?.replace(
          /(\d{3})(\d{3})(\d{4})/,
          '($1) $2-$3'
        )
        item.direct = item.direct?.replace(
          /(\d{3})(\d{3})(\d{4})/,
          '($1) $2-$3'
        )
        item.supervisor = state.items.find((i) => i.id === item.supervisorId)
        return item
      })
  }
}
