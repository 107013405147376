import axios from 'axios'

export default {
  async getConfig({ commit }) {
    try {
      const { data } = await axios.get(`${process.env.VUE_APP_API_URL}/config`)
      commit('setConfig', data)
    } catch (error) {
      commit('handleErrors', error.response.data)
    }
  },

  async setConfig({ commit, getters }, settings) {
    try {
      const { data } = await axios.post(
        `${process.env.VUE_APP_API_URL}/config/`,
        settings,
        getters['User/config']
      )
      commit('displayAlert', {
        level: 'success',
        title: 'Saved.',
        text: '',
        timer: 3000
      })
      return data
    } catch (errors) {
      commit('handleErrors', errors.response.data)
    }
  }
}
