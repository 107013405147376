<template>
  <div class="pt-2 pb-6 md:py-6">
    <div class="mx-auto max-w-7xl px-4 sm:px-6 md:px-8">
      <h1 class="text-2xl font-semibold text-gray-900">Site Configurations</h1>
    </div>
    <div class="mx-auto mb-6 max-w-7xl px-4 sm:px-6 md:px-8">
      <form @submit.prevent="update">
        <div class="mt-8 border-t border-solid pt-8">
          <h2 class="mb-4 text-xl font-semibold text-gray-900">
            News Settings
          </h2>
          <Input
            v-model="config.latestNewsCount"
            label="Latest News Count"
            :error="errors.latestNewsCount"
            errorKey="latestNewsCount"
          />
        </div>
        <div class="mt-8 border-t border-solid pt-8">
          <h2 class="mb-4 text-xl font-semibold text-gray-900">
            Navigation Settings
          </h2>
          <Select
            v-model="config.mainNavMenu"
            :options="menus"
            label="Main Navigation"
          />
        </div>
        <ComponentConfig appComponent="home" title="Home Page Settings" />
        <ComponentConfig
          appComponent="directory"
          title="Employee Directory Settings"
        />
        <ComponentConfig
          appComponent="positions"
          title="Employee Positions Settings"
        />
        <ComponentConfig
          appComponent="opportunities"
          title="Employee Opportunities Settings"
        />
        <ComponentConfig appComponent="tags" title="Tag Posts Settings" />
        <ComponentConfig
          appComponent="archive"
          title="Archive Posts Settings"
        />
        <div class="flex justify-end">
          <button
            type="submit"
            class="focus:shadow-outline-gray inline-flex justify-center rounded-md border border-transparent bg-gray-600 py-2 px-4 text-sm font-medium leading-5 text-white transition duration-150 ease-in-out hover:bg-gray-500 focus:border-gray-700 focus:outline-none active:bg-gray-700"
          >
            Save
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import ComponentConfig from '@/adminInterface/components/ComponentConfig'
export default {
  title: 'Config - iKTA',
  name: 'SiteConfig',
  async created() {
    if (!this.$store.getters['AdminMenus/getMenus'].length) {
      this.$store.dispatch('AdminMenus/getMenus')
    }
    await this.$store.dispatch('getConfig')
  },
  components: { ComponentConfig },
  computed: {
    menus() {
      return this.$store.getters['AdminMenus/getMenus'].map((menu) => menu.name)
    },
    errors() {
      return this.$store.getters.getErrors
    },
    config() {
      return this.$store.getters.config
    }
  },
  methods: {
    update() {
      this.$store.dispatch('setConfig', this.config)
    }
  }
}
</script>
