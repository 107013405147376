export default {
  setNews(state, posts) {
    state.news.posts = posts.items
    state.news.count = posts.count
  },
  setLatest(state, posts) {
    state.latest = posts
  },
  setPinned(state, post) {
    state.pinned = post ? post : null
  },
  setArchives(state, data) {
    state.archives = data
  },
  setArchive(state, posts) {
    state.archive = posts
  },
  setCurrentPage(state, data) {
    if (!data.component) return

    if (data.page) {
      sessionStorage.setItem(`cp-${data.component}`, data.page)
      state[data.component].offset =
        state[data.component].limit * (data.page - 1)
      return (state[data.component].page = data.page)
    }

    if (!state[data.component].page) {
      state[data.component].page = sessionStorage.getItem(
        `cp-${data.component}`
      )
        ? +sessionStorage.getItem(`cp-${data.component}`)
        : 1
    }
  },
  nextPage(state, component) {
    state[component].page++
    state[component].offset += state[component].limit
    sessionStorage.setItem(`cp-${component}`, state[component].page)
  },
  prevPage(state, component) {
    state[component].page--
    state[component].offset -= state[component].limit
    sessionStorage.setItem(`cp-${component}`, state[component].page)
  }
}
