<template>
  <div class="pt-2 pb-6 md:py-6">
    <div class="mx-auto mb-6 max-w-7xl px-4 sm:px-6 md:px-8">
      <h1 class="text-2xl font-semibold text-gray-900">Accordions</h1>
    </div>
    <div class="mx-auto max-w-7xl px-4 sm:px-6 md:px-8">
      <div class="mb-4 flex items-center justify-end">
        <span class="inline-flex rounded-md shadow-sm">
          <router-link
            :to="{ name: 'AccordionCreate' }"
            tag="button"
            class="focus:shadow-outline-gray inline-flex items-center rounded-md border border-transparent bg-gray-600 px-4 py-2 text-sm font-medium leading-5 text-white transition duration-150 ease-in-out hover:bg-gray-500 focus:border-gray-700 focus:outline-none active:bg-gray-700"
          >
            Create
          </router-link>
        </span>
      </div>
      <div class="overflow-hidden bg-white shadow sm:rounded-md">
        <div class="p-4 italic text-gray-500" v-if="accordions.length < 1">
          No accordions found.
        </div>
        <ul>
          <li
            v-for="(accordion, index) in accordions"
            :key="index"
            :class="[index > 0 ? 'border-t border-gray-200' : '']"
          >
            <router-link
              :to="{
                name: 'AccordionEdit',
                params: { id: accordion.id }
              }"
              class="flex items-center justify-between transition duration-150 ease-in-out hover:bg-gray-50 focus:bg-gray-50 focus:outline-none"
            >
              <div
                class="block flex-1 items-center justify-between px-4 py-3 text-sm leading-6 text-gray-700 sm:px-6 lg:flex"
              >
                <div>
                  <span class="mr-2 text-xs font-light uppercase text-gray-400"
                    >Name</span
                  >
                  <span class="font-semibold text-gray-900">{{
                    accordion.name
                  }}</span>
                </div>
                <div>
                  <span class="mr-2 text-xs font-light uppercase text-gray-400"
                    >ID#
                  </span>
                  <span class="font-semibold text-gray-900">{{
                    accordion.id
                  }}</span>
                </div>
              </div>
              <div class="pr-4">
                <svg
                  class="h-5 w-5 text-gray-400"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fill-rule="evenodd"
                    d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                    clip-rule="evenodd"
                  />
                </svg>
              </div>
            </router-link>
          </li>
        </ul>
      </div>
    </div>
    <div class="mx-auto my-4 flex max-w-7xl justify-end px-4 sm:px-6 md:px-8">
      <span class="inline-flex rounded-md shadow-sm">
        <router-link
          :to="{ name: 'AccordionCreate' }"
          tag="button"
          class="focus:shadow-outline-gray inline-flex items-center rounded-md border border-transparent bg-gray-600 px-4 py-2 text-sm font-medium leading-5 text-white transition duration-150 ease-in-out hover:bg-gray-500 focus:border-gray-700 focus:outline-none active:bg-gray-700"
        >
          Create
        </router-link>
      </span>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'accordionIndex',
  created() {
    this.$store.dispatch('AdminAccordions/fetchAll')
  },
  computed: {
    ...mapGetters({
      accordions: 'AdminAccordions/all'
    }),
    itemsPerPage: {
      get() {
        // return this.$store.getters['AdminPages/limit']
        return 10
      },
      set(value) {
        console.log(value)
        // this.$store.commit('AdminPages/setLimit', value)
      }
    }
  }
}
</script>

<style></style>
