<template>
  <draggable
    class="rounded border border-gray-300 bg-gray-200 py-4"
    tag="ul"
    :list="items"
    :group="{ name: 'g1' }"
    item-key="index"
  >
    <template #item="{ element, index }">
      <li class="mx-4 mt-2 rounded border border-gray-300 bg-gray-100">
        <div class="flex items-center justify-between p-2">
          <p>{{ element.name }}</p>
          <div class="flex items-center">
            <button
              class="mr-2 text-sm italic text-gray-400 underline hover:text-gray-600 focus:outline-none"
              @click="edit(element, index)"
            >
              edit
            </button>
            <button
              class="mr-2 text-sm italic text-red-400 underline hover:text-red-600 focus:outline-none"
              @click="remove(element, index)"
            >
              remove
            </button>
          </div>
        </div>
        <MenuItemSorter
          :parent="{ name: element.name, index }"
          :items="element.items"
        />
      </li>
    </template>
  </draggable>
</template>
<script>
import draggable from 'vuedraggable'

export default {
  name: 'MenuItemSorter',
  props: {
    parent: {
      required: false,
      type: Object
    },
    items: {
      required: true,
      type: Array
    }
  },
  components: {
    draggable
  },
  methods: {
    edit(item, index) {
      this.$store.commit('AdminMenus/editItem', {
        item,
        index,
        parent: this.parent
      })
    },
    remove(item, index) {
      this.$store.commit('AdminMenus/removeItem', {
        item,
        index,
        parent: this.parent
      })
    }
  }
}
</script>
<style scoped>
.dragArea {
  min-height: 50px;
  outline: 1px dashed;
}
</style>
