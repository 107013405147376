import axios from 'axios'

export default {
  async getMenus({ commit, rootGetters, state }) {
    try {
      const { data } = await axios.get(
        `${process.env.VUE_APP_API_URL}/menus`,
        rootGetters['User/config']
      )
      commit('setMenus', data)
      if (state.activeId) commit('setActive')
    } catch (error) {
      commit('handleErrors', error.response.data, { root: true })
    }
  }
}
