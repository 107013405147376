export default {
  all(state) {
    return state.accordions
  },
  active(state) {
    return state.active
  }
  // count(state) {
  //   return state.count
  // },
  // offset(state) {
  //   return state.offset
  // },
  // limit(state) {
  //   return state.limit
  // },
  // page(state) {
  //   return state.page
  // },
  // from(state) {
  //   return state.offset + 1
  // },
  // to(state) {
  //   return state.limit > state.count
  //     ? state.count
  //     : state.limit * state.page > state.count
  //     ? state.count
  //     : state.limit * state.page
  // },
  // filter(state) {
  //   return state.filter
  // },
  // filterOptions(state) {
  //   return state.filterOptions
  // },
  // order(state) {
  //   return state.order
  // },
  // orderBy(state) {
  //   return state.orderBy
  // },
  // orderOptions(state) {
  //   return state.orderOptions
  // }
}
