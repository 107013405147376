export default {
  setConfig(state, config) {
    state.config = config
    localStorage.setItem('siteConfig', config)
  },
  toggleMenu(state, status) {
    state.menu = status
  },
  setError(state, error) {
    state.error = error
  },
  setHeaderInfo(state, info) {
    for (const prop in info) {
      state.headerInfo[prop] = info[prop]
    }
  },
  handleErrors(state, errors) {
    state.errors = errors.error
  },
  removeError(state, prop) {
    if (prop === 'message') {
      return (state.errors = null)
    }
    state.errors.message = state.errors.message.filter(
      (error) => error.property !== prop
    )
    if (state.errors.message.length === 0) {
      state.errors = null
    }
  },
  removeErrors(state) {
    state.errors = null
  },
  displayAlert(state, data) {
    state.alert = data
  },
  closeAlert(state) {
    state.alert = {
      level: 'info',
      title: '',
      text: '',
      timer: null
    }
  }
}
