import axios from 'axios'

export default {
  async getLatest({ commit, rootGetters }) {
    try {
      const { data } = await axios.get(
        `${process.env.VUE_APP_API_URL}/posts/latest?limit=5`,
        rootGetters['User/config']
      )
      commit('setLatest', data)
    } catch (error) {
      commit('handleErrors', error.response.data, { root: true })
    }
  },
  async getNews({ state, rootGetters, commit }) {
    try {
      let query = state.news.offset
        ? `?limit=6&offset=${state.news.offset}`
        : `?limit=6`
      const { data } = await axios.get(
        `${process.env.VUE_APP_API_URL}/posts/published${query}`,
        rootGetters['User/config']
      )
      commit('setNews', data)
    } catch (error) {
      commit('handleErrors', error.response.data, { root: true })
    }
  },
  async getPinned({ commit, rootGetters }) {
    try {
      const { data } = await axios.get(
        `${process.env.VUE_APP_API_URL}/posts/pinned`,
        rootGetters['User/config']
      )
      commit('setPinned', data)
    } catch (error) {
      commit('handleErrors', error.response.data, { root: true })
    }
  },
  async get({ commit, rootGetters }, slug) {
    try {
      const { data } = await axios.get(
        `${process.env.VUE_APP_API_URL}/posts/${slug}`,
        rootGetters['User/config']
      )
      return data
    } catch (error) {
      commit('handleErrors', error.response.data, { root: true })
    }
  },
  async fetchArchives({ commit, rootGetters }) {
    try {
      const { data } = await axios.get(
        `${process.env.VUE_APP_API_URL}/posts/archives`,
        rootGetters['User/config']
      )
      commit('setArchives', data)
    } catch (error) {
      commit('handleErrors', error.response.data, { root: true })
    }
  },
  async fetchArchivePosts({ commit, rootGetters }, date) {
    try {
      const { data } = await axios.get(
        `${process.env.VUE_APP_API_URL}/posts/archives/${date.year}/${date.month}`,
        rootGetters['User/config']
      )
      commit('setArchive', data)
    } catch (error) {
      commit('handleErrors', error.response.data, { root: true })
    }
  }
}
