<template>
  <main
    class="relative z-10 mx-auto w-full max-w-7xl flex-1 py-12 px-4 sm:px-6 lg:py-16 lg:px-8"
  >
    <div class="mb-6 text-kta-blue-800" v-if="!page.headerImage">
      <h1 class="mb-2 text-4xl font-bold">{{ page.title }}</h1>
      <h2 class="text-2xl font-medium" v-if="page.subtitle">
        {{ page.subtitle }}
      </h2>
    </div>
    <BodyText :content="page.body" />
    <TagPosts :postsTag="tag" v-if="tag" />
  </main>
</template>
<script>
import TagPosts from '@/clientInterface/components/TagPosts'
/* eslint-disable no-unused-vars */
export default {
  name: 'Page',
  components: { TagPosts },
  async created() {
    await this.$store.dispatch('Pages/get', this.$route.params.slug)
    this.$store.commit('setHeaderInfo', {
      image: this.page.headerImage ? this.page.headerImage.path : '',
      title: this.page.title,
      subtitle: this.page.subtitle
    })
  },
  async beforeRouteUpdate(to, from) {
    await this.$store.dispatch('Pages/get', to.params.slug)
    this.$store.commit('setHeaderInfo', {
      image: this.page.headerImage ? this.page.headerImage.path : '',
      title: this.page.title,
      subtitle: this.page.subtitle
    })
  },
  beforeRouteLeave() {
    this.$store.commit('Pages/setCurrent', {})
  },
  computed: {
    pages() {
      return this.$store.getters['Pages/all']
    },
    page() {
      return this.$store.getters['Pages/current']
    },
    tag() {
      const slug = this.$route.params.slug
      if (slug === 'safety-resources') {
        return 'Safety'
      }
      if (slug === 'health-wellness') {
        return 'Wellness'
      }
      return null
    }
  }
}
</script>
