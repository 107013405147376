import actions from './actions'
import mutations from './mutations'
import getters from './getters'

export default {
  namespaced: true,
  state: () => ({
    accessToken: null,
    refreshToken: null,
    refreshing: false,
    paychecks: [],
    w2s: [],
    contactOptions: {},
    user: {}
  }),
  mutations,
  getters,
  actions
}
