<template>
  <nav
    class="flex items-center justify-between border-t border-gray-200 px-4 sm:px-0"
  >
    <div class="-mt-px flex w-0 flex-1">
      <a
        @click.prevent="prev"
        class="inline-flex cursor-pointer select-none items-center border-t-2 border-transparent pt-4 pr-1 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
      >
        <svg
          class="mr-3 h-5 w-5 text-gray-400"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
          aria-hidden="true"
        >
          <path
            fill-rule="evenodd"
            d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z"
            clip-rule="evenodd"
          /></svg
        >Previous
      </a>
    </div>
    <div class="hidden md:-mt-px md:flex" v-if="splitCount">
      <span v-for="n in pageNumbers" :key="n" class="text-gray-500">
        <span v-if="isNaN(n)" class="inline-block pt-4">{{ n }}</span>
        <a
          @click="gotoPage(n)"
          class="inline-flex cursor-pointer select-none items-center border-t-2 border-transparent px-4 pt-4 text-sm font-medium"
          :class="[
            n === currentPage
              ? 'border-kta-blue-500 text-kta-blue-600'
              : 'hover:border-gray-300 hover:text-gray-700'
          ]"
          v-else
          >{{ n }}</a
        >
      </span>
    </div>
    <div class="-mt-px flex w-0 flex-1 justify-end">
      <a
        @click.prevent="next"
        class="inline-flex cursor-pointer select-none items-center border-t-2 border-transparent pt-4 pl-1 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
        >Next
        <svg
          class="ml-3 h-5 w-5 text-gray-400"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
          aria-hidden="true"
        >
          <path
            fill-rule="evenodd"
            d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
            clip-rule="evenodd"
          />
        </svg>
      </a>
    </div>
  </nav>
</template>
<script>
export default {
  name: 'PaginationControls',
  props: {
    component: {
      type: String,
      required: true
    },
    getter: {
      type: String,
      required: true
    }
  },
  computed: {
    details() {
      return this.$store.getters[`${this.component}/${this.getter}`]
    },
    pageCount() {
      return Math.ceil(this.details.count / this.details.limit)
    },
    currentPage() {
      return this.details.page
    },
    splitCount() {
      return this.pageCount > this.details.limit
    },
    pageNumbers() {
      if (this.pageCount > 5) {
        const start =
          this.currentPage <= 3
            ? [1, 2, 3, '...']
            : this.currentPage + 3 < this.pageCount - 2
            ? [
                this.currentPage - 2,
                this.currentPage - 1,
                this.currentPage,
                this.currentPage + 1,
                this.currentPage + 2,
                '...'
              ]
            : [
                this.pageCount - 5,
                this.pageCount - 4,
                this.pageCount - 3,
                this.pageCount - 2
              ]
        const end = [this.pageCount - 1, this.pageCount]
        return [...start, ...end]
      }
      return [...Array(5).keys()]
    }
  },
  methods: {
    gotoPage(page) {
      this.$store.commit(`${this.component}/setCurrentPage`, {
        component: this.getter,
        page
      })
      this.$emit('pageChange')
    },
    next() {
      if (this.currentPage < this.pageCount) {
        this.$store.commit(`${this.component}/nextPage`, this.getter)
        this.$emit('pageChange')
      }
    },
    prev() {
      if (this.currentPage > 1) {
        this.$store.commit(`${this.component}/prevPage`, this.getter)
        this.$emit('pageChange')
      }
    }
  }
}
</script>
