<template>
  <main
    class="relative z-10 mx-auto w-full max-w-7xl flex-1 py-12 px-4 sm:px-6 lg:py-16 lg:px-8"
  >
    <div
      class="relative overflow-hidden bg-white px-6 pt-3 pb-4 text-sm leading-6 text-gray-700 shadow sm:rounded-md"
    >
      <h1 class="text-lg font-semibold">User Profile</h1>
      <div class="mb-2 flex items-center justify-between">
        <div>
          <span class="mr-2 text-sm font-medium">Employee ID#</span>
          <span class="text-sm font-light">{{ user.id }}</span>
        </div>
        <div class="rounded-md border px-3 text-xs uppercase leading-9">
          {{ user.status }}
        </div>
      </div>
      <div class="flex items-center">
        <Input
          v-model="user.firstName"
          label="First Name"
          class="mr-2 flex-1"
          :error="errors.firstName"
          errorKey="firstName"
          readonly
        />
        <Input
          v-model="user.lastName"
          label="Last Name"
          class="flex-1"
          :error="errors.lastName"
          errorKey="lastName"
          readonly
        />
      </div>
      <div class="flex items-center">
        <Input
          v-model="user.password"
          type="password"
          :error="errors.password"
          errorKey="password"
          label="New Password"
          class="mr-2 flex-1"
        />
        <Input
          v-model="user.confirmPassword"
          type="password"
          :error="errors.confirmPassword"
          errorKey="confirmPassword"
          label="Confirm Password"
          class="flex-1"
        />
      </div>
      <div class="flex items-start">
        <Input
          v-model="user.ktaEmail"
          label="KTA Email"
          class="mr-2 flex-1"
          :error="errors.ktaEmail"
          errorKey="ktaEmail"
          readonly
        />
        <Input
          v-model="user.personalEmail"
          label="Personal Email"
          class="flex-1"
          :error="errors.personalEmail"
          errorKey="personalEmail"
        />
      </div>
      <div class="flex items-center">
        <Input
          v-model="ktaMobile"
          label="KTA Phone"
          class="mr-2 flex-1"
          :error="errors.ktaMobile"
          errorKey="ktaMobile"
          mask="(###) ###-####"
          readonly
        />
        <Input
          v-model="personalMobile"
          label="Personal Phone"
          :error="errors.personalMobile"
          errorKey="personalMobile"
          class="flex-1"
          mask="(###) ###-####"
        />
      </div>
      <div class="space-y-2">
        <Checkbox
          v-model="user.internalNews"
          :disabled="!user.personalEmail"
          label="Receive KTA news at personal email.*"
          name="personalEmailNews"
        />
        <Checkbox
          v-model="user.externalNews"
          :disabled="!user.personalEmail"
          label="Receive Turnpike Times at personal email.*"
          name="personalEmailNews"
        />
        <Checkbox
          v-model="user.notifications"
          :disabled="!user.personalEmail"
          label="Receive notifications at personal email.*"
          name="personalEmailNotifications"
        />
      </div>
      <div class="absolute bottom-4 right-4 flex justify-end" v-if="edited">
        <button
          type="button"
          class="rounded-md border border-transparent bg-gray-600 px-6 py-2 text-sm font-medium text-white shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
          @click="save"
        >
          Save
        </button>
      </div>
      <span class="mt-4 block text-xs italic text-gray-400"
        ><sup>*</sup>Users cannot opt-out of notifications or KTA internal news
        on '@ksturnpike.com' emails.</span
      >
    </div>
    <div class="mt-4 grid-cols-2 gap-4 lg:grid">
      <div
        class="mb-6 flex flex-col overflow-hidden rounded-lg bg-white shadow lg:mb-0"
      >
        <div class="bold border-b border-gray-200 px-6 py-3 text-sm">
          Paycheck Stubs
        </div>
        <div v-show="years">
          <div class="sm:hidden">
            <label for="tabs" class="sr-only">Select a tab</label>
            <select
              id="tabs"
              v-model="currentYear"
              name="tabs"
              class="block w-full border-gray-100 focus:border-kta-blue-600 focus:ring-kta-blue-600"
            >
              <option v-for="year in years" :key="year" :selected="currentYear">
                {{ year }}
              </option>
            </select>
          </div>
          <nav
            class="relative z-0 flex divide-x divide-gray-200"
            aria-label="Tabs"
          >
            <div
              v-for="year in years"
              @click="currentYear = year"
              :key="year"
              :class="[
                currentYear
                  ? 'text-gray-900'
                  : 'text-gray-500 hover:text-gray-700',
                'group relative hidden min-w-0 flex-1 cursor-pointer overflow-hidden py-3 px-4 text-center text-sm font-medium hover:bg-gray-50 focus:z-10 sm:block'
              ]"
              :aria-current="currentYear ? 'page' : undefined"
            >
              <span>{{ year }}</span>
              <span
                aria-hidden="true"
                :class="[
                  currentYear === year ? 'bg-kta-blue-600' : 'bg-transparent',
                  'absolute inset-x-0 bottom-0 h-0.5'
                ]"
              />
            </div>
          </nav>
        </div>
        <div
          class="flex h-4 flex-1 items-center justify-center"
          v-if="fetchingPaychecks"
        >
          <LoadAnimation />
        </div>
        <div :class="{ 'border-t border-gray-200': years.length }" v-else>
          <div v-if="years.length">
            <div
              v-for="(dates, year) in paychecks"
              :key="year"
              class="relative px-6 py-5"
              v-show="year == currentYear"
            >
              <div
                class="grid grid-cols-3 gap-2 text-sm sm:grid-cols-5 lg:grid-cols-4 xl:grid-cols-5"
                v-if="this.paycheckUrls[year]"
              >
                <a
                  :href="url"
                  :download="dates[index]"
                  class="mr-4 text-kta-blue-600 underline hover:text-kta-blue-700"
                  v-for="(url, index) in paycheckUrls[year]"
                  :key="index"
                  >{{ dates[index] }}</a
                >
              </div>
            </div>
          </div>
          <p class="relative p-6 text-sm italic" v-else>
            No Paychecks available.
          </p>
        </div>
      </div>
      <div class="flex flex-col overflow-hidden rounded-lg bg-white shadow">
        <div class="bold border-b border-gray-200 px-6 py-3 text-sm">
          W-2 History
        </div>
        <div
          class="flex h-4 flex-1 items-center justify-center"
          v-if="fetchingW2s"
        >
          <LoadAnimation />
        </div>
        <div class="p-6 text-sm" v-else>
          <ul v-if="w2Urls.length" class="flex flex-wrap">
            <li v-for="(url, index) in w2Urls" :key="index">
              <a
                :href="url"
                class="mr-4 text-kta-blue-600 underline hover:text-kta-blue-700"
                >{{ Object.keys(w2s)[index] }}</a
              >
            </li>
          </ul>
          <p class="italic" v-else>No W-2s available.</p>
        </div>
      </div>
    </div>
  </main>
</template>
<script>
export default {
  name: 'Profile',
  title() {
    return 'KTA - News'
  },
  async created() {
    await this.$store.dispatch('User/fetchW2s')
    await this.$store.dispatch('User/fetchPaychecks')
    await this.$store.dispatch('User/fetchUser')
    await this.getPaycheckUrls()
    await this.getW2Urls()
    this.setOriginal()
  },
  data: () => ({
    fetchingPaychecks: true,
    fetchingW2s: true,
    currentYear: new Date().getFullYear().toString(),
    original: '',
    paycheckUrls: {},
    w2Urls: []
  }),
  computed: {
    user() {
      return this.$store.getters['User/profile']
    },
    ktaMobile() {
      return this.user.ktaMobile ? this.formatPhone(this.user.ktaMobile) : null
    },
    personalMobile: {
      get() {
        return this.user.personalMobile
          ? this.formatPhone(this.user.personalMobile)
          : null
      },
      set(value) {
        this.$store.commit(
          'User/setUserPersonalMobile',
          value.replace(/\D/g, '')
        )
      }
    },
    paychecks() {
      return this.$store.getters['User/paychecks']
    },
    years() {
      return Object.keys(this.paychecks).reverse()
    },
    w2s() {
      return this.$store.getters['User/w2s']
    },
    edited() {
      return !this.$_.isEqual(this.user, this.original)
    },
    errors() {
      return this.$store.getters.getErrors
    }
  },
  methods: {
    formatPhone(number) {
      const parts = number.match(/^(\d{3})(\d{3})(\d{4})$/)
      return parts ? `(${parts[1]}) ${parts[2]}-${parts[3]}` : number
    },
    async save() {
      let updateProps = {}
      for (const prop in this.user) {
        if (!this.$_.isEqual(this.user[prop], this.original[prop])) {
          if (!['permissions', 'role'].includes(prop)) {
            updateProps[prop] = this.user[prop]
          }
          if (prop === 'personalMobile') {
            updateProps[prop].replace(/[()-\s]/g, '')
          }
          if (
            prop === 'personalEmail' &&
            !this.user[prop] &&
            this.user.ktaEmail
          ) {
            updateProps.ktaEmail = this.user.ktaEmail
          }
        }
      }

      if (Object.keys(updateProps).length) {
        await this.$store.dispatch('User/update', {
          id: this.user.id,
          props: updateProps
        })
        this.setOriginal()
      }
      this.$store.commit('displayAlert', {
        position: 'top',
        level: 'success',
        text: 'Profile updated.',
        timer: 3000
      })
    },
    async getPaycheckUrls() {
      if (!Object.keys(this.paychecks).length) {
        return (this.fetchingPaychecks = false)
      }
      if (!Object.keys(this.paycheckUrls).includes(this.currentYear)) {
        let paychecks = []
        for await (const paycheck of this.paychecks[this.currentYear]) {
          paychecks.push(
            await this.$store.dispatch(`User/fetchPaycheck`, paycheck)
          )
        }
        this.paycheckUrls[this.currentYear] = paychecks
        this.fetchingPaychecks = false
        this.$forceUpdate()
      }
    },
    async getW2Urls() {
      let w2s = []
      for await (const w2 of Object.values(this.w2s)) {
        w2s.push(await this.$store.dispatch(`User/fetchW2`, w2))
      }
      this.w2Urls = w2s
      this.fetchingW2s = false
    },
    setOriginal() {
      this.original = this.$_.cloneDeep(this.user)
    }
  },
  watch: {
    user: {
      deep: true,
      handler(value) {
        if (!value.password) {
          delete this.user.password
        }
        if (!value.confirmPassword) {
          delete this.user.confirmPassword
        }
      }
    },
    currentYear() {
      this.fetchingPaychecks = true
      this.getPaycheckUrls()
    }
  }
}
</script>
