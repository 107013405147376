<template>
  <div>
    <h3
      class="test-sm mb-4 font-bold uppercase tracking-wide text-kta-blue-900"
    >
      Tags
    </h3>
    <ul class="space-y-2">
      <li v-for="tag in tags" :key="tag.id" class="flex items-center space-x-1">
        <span class="block text-yellow-500">
          <svg
            class="h-3 w-3"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            viewBox="0 0 13 24"
          >
            <path
              d="M6.26087 0L12.5217 12L6.26087 24H0L6.26087 12L0 0H6.26087Z"
            />
          </svg>
        </span>
        <router-link
          :to="{ path: `/tag/${$_.kebabCase(tag.name)}` }"
          class="font-medium text-kta-blue-900 outline-none focus:outline-none"
        >
          {{ tag.name }}
        </router-link>
        <span class="text-sm text-kta-blue-500">({{ tag.itemCount }})</span>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  name: 'PostsTagsMenu',
  async created() {
    await this.$store.dispatch('Tags/getAll')
  },
  computed: {
    tags() {
      return this.$store.getters['Tags/tags'].filter((tag) => tag.itemCount)
    }
  }
}
</script>
