export default {
  setFolders(state, folders) {
    state.folders = folders
  },
  setFiles(state, files) {
    state.files = files
  },
  sortFiles(state, prop) {
    if (state.sortFiles.by !== prop) {
      state.sortFiles.by = prop
      state.sortFiles.direction === 'ASC'
    } else {
      state.sortFiles.direction =
        state.sortFiles.direction === 'ASC' ? 'DESC' : 'ASC'
    }
  },
  setActiveFolder(state, id) {
    state.activeFolder = id
  },
  toggleFileUploader(state) {
    state.showFileUploader = !state.showFileUploader
  },
  toggleFilePicker(state) {
    state.showFilePicker = !state.showFilePicker
  }
}
