export default {
  get(state) {
    return state.positions
  },
  count(state) {
    return state.count
  },
  offset(state) {
    return state.offset
  },
  limit(state) {
    return state.limit
  },
  page(state) {
    return state.page
  },
  from(state) {
    return state.offset + 1
  },
  to(state) {
    return state.limit > state.count
      ? state.count
      : state.limit * state.page > state.count
      ? state.count
      : state.limit * state.page
  },
  filter(state) {
    return state.filter
  },
  filterBy(state) {
    return state.filterBy
  },
  filterOptions(state) {
    return state.filterOptions
  },
  filterByOptions(state) {
    return state.filter === 'department' ? state.departments : null
  },
  order(state) {
    return state.order
  },
  orderBy(state) {
    return state.orderBy
  },
  orderOptions(state) {
    return state.orderOptions
  },
  departments(state) {
    return state.departments
  }
}
