import actions from './actions'
import mutations from './mutations'
import getters from './getters'

export default {
  namespaced: true,
  state: () => ({
    posts: [],
    pinned: null,
    count: 0,
    offset: 0,
    limit: 10,
    filter: null,
    filterBy: null,
    filterOptions: [
      { key: 'published', value: 'Published' },
      { key: 'unpublished', value: 'Unpublished' },
      { key: 'latestUntil', value: 'Latest Until' },
      { key: 'tags', value: 'Tag', filterBy: true }
    ],
    order: 'ASC',
    orderBy: { key: 'created', value: 'Created', type: 'date' },
    orderOptions: [
      { key: 'created', value: 'Created', type: 'date' },
      { key: 'title', value: 'Title', type: 'string' },
      { key: 'published', value: 'Published', type: 'date' }
    ],
    page: 1
  }),
  mutations,
  getters,
  actions
}
