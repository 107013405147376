<template>
  <div class="mb-12 flex items-center space-x-2">
    <h1 class="text-3xl font-extrabold leading-7 text-kta-blue-900">
      News & Information
    </h1>
    <div class="h-5 w-5 text-yellow-500">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          d="M6.26087 0L12.5217 12L6.26087 24H0L6.26087 12L0 0H6.26087ZM11.4783 0L17.7391 12L11.4783 24H17.7391L24 12L17.7391 0H11.4783Z"
          fill="currentColor"
        />
      </svg>
    </div>
  </div>
  <div class="items-start md:flex">
    <div class="mr-4 hidden w-48 md:block">
      <PostsTagsMenu class="mb-6" />
      <PostsArchiveMenu />
    </div>
    <div class="flex-1 gap-16 md:grid lg:grid-cols-2 lg:gap-x-5 lg:gap-y-12">
      <article v-for="(post, index) in posts" :key="index" class="mb-6 md:mb-0">
        <router-link :to="{ path: `/posts/${post.slug}` }" class="block">
          <p class="text-xl font-bold text-kta-blue-900">
            {{ post.title }}
          </p>
        </router-link>
        <p class="text-sm font-medium text-kta-blue-500">
          <time datetime="2020-03-16">{{ post.published }}</time>
        </p>
        <BodyText
          class="mt-3 text-base text-gray-600"
          :content="post.summary ? post.summary : post.body"
        />
        <div class="mt-3">
          <router-link
            v-if="post.summary"
            :to="{ path: `/posts/${post.slug}` }"
            class="text-sm font-semibold text-kta-blue-500 underline hover:text-kta-blue-600"
          >
            Read More
          </router-link>
        </div>
        <div class="mt-4 flex flex-wrap items-center">
          <router-link
            :to="{ path: `/tag/${$_.kebabCase(tag.name)}` }"
            v-for="(tag, index) in post.tags"
            :key="index"
            class="mr-2 mb-2 rounded-full bg-kta-blue-900 px-2 py-1 text-xs uppercase tracking-wider text-white focus:ring-2 focus:ring-kta-blue-500 focus-visible:outline-none md:px-4 md:py-1 md:font-bold"
            >{{ tag.name }}</router-link
          >
        </div>
      </article>
      <PaginationControls component="Posts" getter="news" class="col-span-2" />
    </div>
  </div>
</template>
<script>
import PostsTagsMenu from './PostsTagsMenu.vue'
import PostsArchiveMenu from './PostsArchiveMenu.vue'
import PaginationControls from '../PaginationControls.vue'

export default {
  name: 'NewsInfo',
  components: { PostsTagsMenu, PostsArchiveMenu, PaginationControls },
  computed: {
    news() {
      return this.$store.getters['Posts/news']
    },
    posts() {
      return this.news
        ? this.$_.cloneDeep(this.news.posts).map((post) => {
            post.published = this.$date.format(new Date(post.published), 'PP')
            return post
          })
        : []
    },
    page() {
      return this.news.page
    }
  },
  watch: {
    page: {
      handler() {
        this.$store.dispatch('Posts/getNews')
      },
      deep: true
    }
  },
  data: () => ({})
}
</script>
