import axios from 'axios'

export default {
  async getAll({ state, rootGetters, commit }) {
    try {
      let query = state.offset
        ? `?limit=${state.limit}&offset=${state.offset}`
        : `?limit=${state.limit}`
      query =
        state.filter && state.filterBy
          ? `${query}&filter=${state.filter}&filterBy=${state.filterBy}`
          : query
      query =
        state.orderBy.key !== 'title' || state.order !== 'ASC'
          ? `${query}&order=${state.order}&orderBy=${state.orderBy.key}`
          : query
      const { data } = query
        ? await axios.get(
            `${process.env.VUE_APP_API_URL}/policies${query}`,
            rootGetters['User/config']
          )
        : await axios.get(
            `${process.env.VUE_APP_API_URL}/policies`,
            rootGetters['User/config']
          )
      commit('set', data)
    } catch (errors) {
      commit('handleErrors', errors.response.data, { root: true })
    }
  },

  async getSections({ commit, rootGetters }) {
    try {
      const { data } = await axios.get(
        `${process.env.VUE_APP_API_URL}/policies/sections`,
        rootGetters['User/config']
      )
      commit('setSections', data)
    } catch (errors) {
      commit('handleErrors', errors.response.data, { root: true })
    }
  },

  async get({ commit, rootGetters }, id) {
    try {
      const { data } = await axios.get(
        `${process.env.VUE_APP_API_URL}/policies/${id}`,
        rootGetters['User/config']
      )
      return data
    } catch (errors) {
      commit('handleErrors', errors.response.data, { root: true })
    }
  },

  async create({ commit, rootGetters }, params) {
    try {
      const policy = await axios.post(
        `${process.env.VUE_APP_API_URL}/policies`,
        params,
        rootGetters['User/config']
      )
      commit('add', policy.data)
      return policy
    } catch (errors) {
      commit('handleErrors', errors.response.data, { root: true })
    }
  },

  async createSection({ commit, rootGetters }, params) {
    try {
      const section = await axios.post(
        `${process.env.VUE_APP_API_URL}/policies/sections`,
        params,
        rootGetters['User/config']
      )
      return section
    } catch (errors) {
      commit('handleErrors', errors.response.data, { root: true })
    }
  },

  async update({ commit, rootGetters }, params) {
    try {
      const response = await axios.patch(
        `${process.env.VUE_APP_API_URL}/policies/${params.id}`,
        params.data,
        rootGetters['User/config']
      )
      commit('update', response.data)
      return response
    } catch (errors) {
      commit('handleErrors', errors.response.data, { root: true })
    }
  },

  async updateSection({ commit, rootGetters }, params) {
    const { id, ...data } = params
    try {
      const response = await axios.patch(
        `${process.env.VUE_APP_API_URL}/policies/sections/${id}`,
        data,
        rootGetters['User/config']
      )
      return response
    } catch (errors) {
      commit('handleErrors', errors.response.data, { root: true })
    }
  },

  async delete({ commit, rootGetters }, id) {
    try {
      const response = await axios.delete(
        `${process.env.VUE_APP_API_URL}/policies/${id}`,
        rootGetters['User/config']
      )
      commit('remove', id)
      return response
    } catch (errors) {
      commit('handleErrors', errors.response.data, { root: true })
    }
  },

  async deleteSection({ commit, rootGetters }, id) {
    try {
      const response = await axios.delete(
        `${process.env.VUE_APP_API_URL}/policies/sections/${id}`,
        rootGetters['User/config']
      )
      commit('removeSection', id)
      return response
    } catch (errors) {
      commit('handleErrors', errors.response.data, { root: true })
    }
  }
}
