<template>
  <header class="relative z-20" ref="header">
    <div
      class="relative"
      :class="{
        'aspect-w-8 aspect-h-4 md:aspect-w-10 lg:aspect-w-16': info.image
      }"
    >
      <a
        :href="ctaLink"
        v-if="info.image && info.ctaLink && !info.ctaButtonText"
      >
        <span class="sr-only">link</span>
        <img class="object-cover" :src="info.image" alt="" />
      </a>
      <img
        class="object-cover"
        :src="info.image"
        alt=""
        v-if="
          (info.image && info.ctaButtonText) || (info.image && !info.ctaLink)
        "
      />
      <div
        class="z-20 mx-auto flex max-w-7xl items-start justify-between p-4 text-white sm:px-6 lg:px-8"
      >
        <div
          class="absolute inset-0 -z-10 mx-auto mt-8 flex max-w-7xl items-center text-white lg:mt-0"
          :class="{ 'justify-center': isHome }"
          v-if="info.image"
        >
          <div
            class="px-8 leading-6 text-shadow-md md:leading-10"
            :class="{ 'text-center': isHome }"
          >
            <h1
              class="text-2xl font-extrabold text-shadow-lg md:text-5xl lg:mb-3 lg:text-6xl"
            >
              {{ info.title }}
            </h1>
            <p
              class="text-md font-semibold text-shadow-lg md:text-2xl lg:text-3xl"
            >
              {{ info.subtitle }}
            </p>
            <a :href="info.ctaLink">
              <button
                type="button"
                class="mt-4 rounded-md border-2 border-white py-1 px-6 font-semibold drop-shadow-md text-shadow-lg hover:bg-white hover:text-gray-700 md:py-2"
                v-if="isHome && info.ctaButtonText"
              >
                {{ info.ctaButtonText }}
              </button>
            </a>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>
<script>
export default {
  name: 'Header',
  mounted() {
    this.$emit('headerRef', this.$refs.header)
  },
  computed: {
    loggedIn() {
      return this.$store.getters['User/loggedIn']
    },
    info() {
      return this.$store.getters.headerInfo
    },
    isHome() {
      return this.$route.path === '/'
    }
  }
}
</script>
