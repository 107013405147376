import axios from 'axios'

export default {
  async fetchAll({ commit, rootGetters }) {
    try {
      const { data } = await axios.get(
        `${process.env.VUE_APP_API_URL}/users`,
        rootGetters['User/config']
      )
      commit('set', data)
    } catch (errors) {
      commit('handleErrors', errors.response.data, { root: true })
    }
  },
  async fetchOne({ commit, rootGetters }, id) {
    try {
      const { data } = await axios.get(
        `${process.env.VUE_APP_API_URL}/users/${id}`,
        rootGetters['User/config']
      )
      return data
    } catch (errors) {
      commit('handleErrors', errors.response.data, { root: true })
    }
  },
  async fetchPermissions({ commit, rootGetters }) {
    try {
      const { data } = await axios.get(
        `${process.env.VUE_APP_API_URL}/users/permissions`,
        rootGetters['User/config']
      )
      commit('setPermissions', data)
    } catch (errors) {
      commit('handleErrors', errors.response.data, { root: true })
    }
  },
  async updatePermissions({ commit, rootGetters }, params) {
    try {
      return await axios.post(
        `${process.env.VUE_APP_API_URL}/users/${params.id}/permissions`,
        params.permissions,
        rootGetters['User/config']
      )
    } catch (errors) {
      commit('handleErrors', errors.response.data, { root: true })
    }
  },
  async updateRole({ commit, rootGetters }, params) {
    try {
      return await axios.post(
        `${process.env.VUE_APP_API_URL}/users/${params.id}/role`,
        { role: params.role },
        rootGetters['User/config']
      )
    } catch (errors) {
      commit('handleErrors', errors.response.data, { root: true })
    }
  },
  async delete({ commit, rootGetters }, id) {
    try {
      const response = await axios.delete(
        `${process.env.VUE_APP_API_URL}/pages/${id}`,
        rootGetters['User/config']
      )
      commit('remove', id)
      return response
    } catch (errors) {
      commit('handleErrors', errors.response.data, { root: true })
    }
  }
}
