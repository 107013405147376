import { cloneDeep } from 'lodash'
const defaultMenu = {
  name: '',
  menuType: null,
  url: '',
  newTab: false,
  external: false,
  items: []
}

export default {
  setMenus(state, menus) {
    state.menus = menus
    if (state.active) {
      state.active = state.menus.find((menu) => menu.id === state.active.id)
    }
  },
  setActive(state, menu) {
    state.active = menu
    state.original = cloneDeep(menu)
  },
  addMenu(state, menu) {
    state.menus.push(menu)
  },
  addItem(state) {
    state.active.items.push(state.activeItem)
    state.activeItem = defaultMenu
  },
  editItem(state, item) {
    state.tempItem = cloneDeep(item)
    state.activeItem = state.tempItem.item
  },
  updateItem(state) {
    state.tempItem.parent
      ? state.active.items[state.tempItem.parent.index].items.splice(
          state.tempItem.index,
          1,
          state.activeItem
        )
      : state.active.items.splice(state.tempItem.index, 1, state.activeItem)
    delete state.tempItem
    state.activeItem = defaultMenu
  },
  cancelItem(state) {
    if (state.tempItem) delete state.tempItem
    state.activeItem = defaultMenu
  },
  removeItem(state, item) {
    !item.parent
      ? state.active.items.splice(item.index, 1)
      : state.active.items[item.parent.index].items.splice(item.index, 1)
  },
  remove(state) {
    state.menus = state.menus.filter((menu) => menu.name !== state.active.name)
    state.active = null
  },
  setActiveItemProp(state, { prop, value }) {
    state.activeItem[prop] = value
  }
}
