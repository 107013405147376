export default {
  all(state) {
    return state.positions
  },
  nested(state) {
    const nested = {}
    state.positions.items?.forEach((position) => {
      const { department } = position
      if (!nested[department]) {
        nested[department] = []
      }
      nested[department] = [...nested[department], position]
    })

    return nested
  }
}
