<template>
  <div class="pt-2 pb-6 md:py-6">
    <div class="mx-auto mb-6 max-w-7xl px-4 sm:px-6 md:px-8">
      <h1 class="text-2xl font-semibold text-gray-900">Edit User</h1>
      <router-link
        :to="{ name: 'Users' }"
        class="mt-2 inline-block text-sm text-kta-blue-600 hover:text-kta-blue-800"
        >&larr; Back to Users</router-link
      >
    </div>
    <div class="mx-auto max-w-7xl px-4 sm:px-6 md:px-8">
      <div
        class="p4-2 relative overflow-hidden bg-white pb-2 text-sm leading-6 text-gray-700 shadow sm:rounded-md"
      >
        <div class="p-4">
          <div class="flex items-center justify-between">
            <div>
              <span class="text-sm font-semibold">Employee ID#</span>
              {{ user.id }}
            </div>
            <div class="flex items-center">
              <Select
                v-model="user.role"
                :options="['superadmin', 'admin', 'user']"
                errorKey="role"
                @update:modelValue="initPermissions"
              />
              <div
                :class="[
                  `${statusColor}`,
                  'ml-2 mb-5 rounded-md border px-3 text-xs uppercase leading-9'
                ]"
              >
                {{ user.status }}
              </div>
            </div>
          </div>
          <div class="flex items-center">
            <Input
              v-model="user.firstName"
              label="First Name"
              class="mr-2 flex-1"
              errorKey="firstName"
            />
            <Input
              v-model="user.lastName"
              label="Last Name"
              class="flex-1"
              errorKey="lastName"
            />
          </div>
          <div class="flex items-start">
            <Input
              v-model="user.ktaEmail"
              label="KTA Email"
              class="mr-2 flex-1"
              errorKey="ktaEmail"
              readonly
            />
            <Input
              v-model="user.personalEmail"
              errorKey="personalEmail"
              label="Personal Email"
              class="flex-1"
            />
          </div>
          <div class="flex items-center">
            <Input
              v-model="ktaMobile"
              errorKey="ktaMobile"
              label="KTA Phone"
              class="mr-2 flex-1"
              mask="(###) ###-####"
              readonly
            />
            <Input
              v-model="personalMobile"
              errorKey="personalMobile"
              label="Personal Phone"
              class="flex-1"
              mask="(###) ###-####"
            />
          </div>
          <div class="space-y-2">
            <Checkbox
              v-model="user.personalEmailNews"
              :disabled="!user.personalEmail"
              label="Receive KTA news at personal email."
              name="personalEmailNews"
            />
            <Checkbox
              v-model="user.personalEmailNotifications"
              :disabled="!user.personalEmail"
              label="Receive notifications at personal email."
              name="personalEmailNotifications"
            />
          </div>
          <div v-if="user.role === 'admin'">
            <div class="mt-6 mb-4 text-lg font-bold">Permissions</div>
            <div
              class="grid grid-cols-2 gap-x-4 gap-y-6 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5"
            >
              <div v-for="(actions, type) in permissions" :key="type">
                <div>
                  <input
                    :id="type"
                    v-model="user.permissions[type]"
                    value="read"
                    @change="toggleAllPermissions(type)"
                    type="checkbox"
                    class="h-4 w-4 rounded border-gray-300 text-kta-blue-600 focus:ring-kta-blue-500"
                  />
                  <label :for="type" class="ml-2 font-medium text-gray-700">{{
                    type
                  }}</label>
                </div>
                <div
                  class="ml-2 border-l border-gray-300 pl-2"
                  v-for="(action, index) in actions"
                  :key="index"
                >
                  <input
                    :id="`${type}-${action}`"
                    v-model="user.permissions[type]"
                    :value="action"
                    @update:modelValue="updatePermissions(type)"
                    type="checkbox"
                    class="h-4 w-4 rounded border-gray-300 text-kta-blue-600 focus:ring-kta-blue-500"
                  />
                  <label
                    :for="`${type}-${action}`"
                    class="ml-2 font-medium text-gray-700"
                    >{{ action }}</label
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="flex justify-end px-4" v-if="edited">
          <button
            type="button"
            class="rounded-md border border-transparent bg-gray-600 px-6 py-2 text-sm font-medium text-white shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
            @click="save"
          >
            Save
          </button>
        </div>
        <div class="mt-4 flex items-center justify-between border-t px-4 pt-2">
          <div>
            <span class="mr-2 text-xs font-light uppercase text-gray-500"
              >Registered</span
            >
            <span class="font-semibold text-gray-800">{{
              user.created
                ? $date.format(new Date(user.created), 'MM-dd-yyyy')
                : ''
            }}</span>
          </div>
          <div>
            <span class="mr-2 text-xs font-light uppercase text-gray-500"
              >Last Updated</span
            >
            <span class="font-semibold text-gray-800">{{
              user.created ? $date.format(new Date(user.updated), 'Pp') : ''
            }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'EditUser',
  async created() {
    this.user = await this.$store.dispatch(
      'AdminUsers/fetchOne',
      this.$route.params.id
    )
    this.original = this.$_.cloneDeep(this.user)
    this.$store.dispatch('AdminUsers/fetchPermissions')
  },
  data: () => ({
    original: '',
    user: {}
  }),
  methods: {
    initPermissions(value) {
      if (value === 'admin') {
        this.user.permissions = {}
        for (const permission of Object.keys(this.permissions)) {
          this.user.permissions[permission] = []
        }
      }
    },
    updatePermissions(type) {
      if (!this.user.permissions[type].includes('read')) {
        this.user.permissions[type].push('read')
      }
    },
    toggleAllPermissions(type) {
      this.user.permissions[type] = this.user.permissions[type].includes('read')
        ? ['read', ...this.permissions[type]]
        : []
    },
    formatPhone(number) {
      const parts = number.match(/^(\d{3})(\d{3})(\d{4})$/)
      return parts ? `(${parts[1]}) ${parts[2]}-${parts[3]}` : number
    },
    save() {
      let updateProps = {}
      for (const prop in this.user) {
        if (!this.$_.isEqual(this.user[prop], this.original[prop])) {
          if (prop === 'permissions') {
            this.$store.dispatch('AdminUsers/updatePermissions', {
              id: this.user.id,
              permissions: this.user.permissions
            })
          }
          if (prop === 'role') {
            this.$store.dispatch('AdminUsers/updateRole', {
              id: this.user.id,
              role: this.user.role
            })
          }
          if (!['permissions', 'role'].includes(prop)) {
            updateProps[prop] = this.user[prop]
          }
        }
      }
      if (Object.keys(updateProps).length) {
        this.$store.dispatch('User/update', {
          id: this.user.id,
          props: updateProps
        })
      }
      this.$store.commit('displayAlert', {
        position: 'top',
        level: 'success',
        text: 'User updated.',
        timer: 3000
      })
    }
  },
  computed: {
    permissions() {
      return this.$store.getters['AdminUsers/permissions']
    },
    edited() {
      return !this.$_.isEqual(this.user, this.original)
    },
    ktaMobile() {
      return this.user.ktaMobile ? this.formatPhone(this.user.ktaMobile) : null
    },
    personalMobile: {
      get() {
        return this.user.personalMobile
          ? this.formatPhone(this.user.personalMobile)
          : null
      },
      set(value) {
        this.user.personalMobile = value.replace(/\D/g, '')
      }
    },
    statusColor() {
      return this.user.status === 'active'
        ? 'bg-green-200 text-green-600 border-green-300'
        : this.user.status === 'retired'
        ? 'bg-yellow-200 text-yellow-700 border-yellow-400'
        : 'bg-red-200 text-red-800 border-red-00'
    }
  }
}
</script>
