<template>
  <router-link to="/login" v-if="!loggedIn" class="text-kta-blue-600 underline"
    >Login</router-link
  >
  <div v-else>
    <div
      class="fixed inset-0 bg-transparent"
      @click="active = !active"
      v-if="active"
    ></div>
    <div class="relative ml-3">
      <div class="rounded-full hover:ring-8">
        <button
          @click="active = !active"
          class="focus:shadow-outline flex items-center rounded-full text-sm focus:outline-none"
          id="user-menu"
          aria-label="User menu"
          aria-haspopup="true"
        >
          <img
            class="h-12 w-12 rounded-full"
            :src="`/employee-images/${employeeId}.jpg`"
            alt=""
          />
        </button>
      </div>
      <transition
        enter-active-class="transition ease-out duration-300"
        enter-from-class="opacity-0 scale-95"
        enter-to-class="opacity-100"
        leave-active-class="transition ease-in duration-300"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0 scale-95"
      >
        <div
          class="absolute right-0 mt-2 w-48 origin-top-right rounded-md shadow-lg"
          v-show="active"
        >
          <div
            class="shadow-xs rounded-md bg-white py-1"
            role="menu"
            aria-orientation="vertical"
            aria-labelledby="user-menu"
          >
            <router-link
              :to="{ name: 'User' }"
              @click="active = false"
              class="block px-4 py-2 text-sm text-gray-700 transition duration-150 ease-in-out hover:bg-gray-100"
              role="menuitem"
              >Profile</router-link
            >
            <a
              href="sign-out"
              class="block px-4 py-2 text-sm text-gray-700 transition duration-150 ease-in-out hover:bg-gray-100"
              role="menuitem"
              @click.prevent="signout"
              >Sign out</a
            >
            <router-link
              :to="{ name: 'Admin' }"
              @click="active = false"
              class="block border-t px-4 py-2 text-sm text-gray-700 transition duration-150 ease-in-out hover:bg-gray-100"
              role="menuitem"
              v-if="isAdmin"
              >Site Administration</router-link
            >
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProfileMenu',
  data: () => ({
    active: false
  }),
  computed: {
    loggedIn() {
      return this.$store.getters['User/loggedIn']
    },
    employeeId() {
      return this.$store.getters['User/id']
    },
    isAdmin() {
      return this.$store.getters['User/isAdmin']
    }
  },
  methods: {
    signout() {
      this.$store.commit('User/logout')
      if (this.$route.meta.auth) {
        this.$router.push({ name: 'Login' })
      }
    }
  }
}
</script>
