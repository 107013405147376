export default {
  set(state, posts) {
    state.posts = posts.items
    state.count = posts.count
  },
  setPinned(state, post) {
    state.pinned = post ? post : null
  },
  setFilter(state, filter) {
    state.filter = filter === 'all' ? null : filter
  },
  setFilterBy(state, filter) {
    state.filterBy = filter !== 'All' ? filter : null
  },
  setOrder(state, order) {
    state.order = order
  },
  setOrderBy(state, order) {
    state.orderBy = order
  },
  nextPage(state) {
    state.page++
    state.offset += state.limit
  },
  prevPage(state) {
    state.page--
    state.offset -= state.limit
  },
  setLimit(state, value) {
    state.limit = value
  }
}
