<template>
  <main
    class="relative z-10 mx-auto flex w-full max-w-7xl flex-1 items-start py-12 px-4 sm:px-6 lg:py-16 lg:px-8"
  >
    <PolicyMenu :currentHash="currentHash" class="w-1/4" />
    <div class="flex-1 space-y-8 text-kta-blue-900" id="policies">
      <div
        class="min-h-1/2vm"
        v-for="policy in policies"
        :key="policy.id"
        :id="`${section.sectionId}-${policy.article}-${policy.paragraph}`"
        :ref="`${section.sectionId}-${policy.article}-${policy.paragraph}`"
      >
        <h4 class="font-bold">{{ policy.title }}</h4>
        <div class="mb-2 flex items-center">
          <span class="mr-2 text-sm font-semibold text-gray-400"
            >POLICY {{ section.sectionId }}-{{ policy.article }}-{{
              policy.paragraph
            }}</span
          >
          <span
            class="flex items-center space-x-1 rounded-full bg-red-600 px-2 text-xs font-semibold leading-5 text-white"
          >
            <span>PDF</span>
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-3 w-3"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="3"
                  d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
                />
              </svg>
            </span>
          </span>
        </div>
        <div class="policy-content" v-html="policy.content"></div>
      </div>
    </div>
  </main>
</template>
<script>
import PolicyMenu from '@/clientInterface/components/policies/PolicyMenu'

export default {
  name: 'Policies',
  components: { PolicyMenu },
  data: () => ({
    currentHash: null
  }),
  created() {
    this.$store.dispatch('Policies/getAll')
    this.$store.commit('Policies/setSection', this.$route.params.policy)
    this.observer = new IntersectionObserver(this.observeAction, {
      rootMargin: '-40% 0px -40% 0px'
    })
  },
  mounted() {
    this.$router.push({ hash: this.$route.hash })
  },
  beforeRouteUpdate(to, from, next) {
    this.currentHash = to.hash
    if (to.params.policy !== from.params.policy) {
      this.observer.disconnect()
      this.$store.commit('Policies/setSection', to.params.policy)
    }
    next()
  },
  updated() {
    for (const ref in this.$refs) {
      if (this.$refs[ref]) {
        this.observer.observe(this.$refs[ref])
      }
    }
  },
  computed: {
    policyUrls() {
      return this.policies.map((policy) => policy.title)
    },
    sections() {
      return this.$store.getters['Policies/sections']
    },
    section() {
      return this.$store.getters['Policies/section']
    },
    policies() {
      if (this.section?.policy) {
        return this.section.policy
      }
      return []
    }
  },
  methods: {
    observeAction(entries) {
      entries.forEach((entry) => {
        if (!entry.isIntersecting) {
          return
        }
        history.pushState(history.state, null, `#${entry.target.id}`)
        this.currentHash = `#${entry.target.id}`
      })
    },
    updateRefs() {
      console.log('updateRefs')
    },
    addObservers() {
      this.$nextTick(() => {
        for (const ref in this.$refs) {
          this.observer.observe(this.$refs[ref])
        }
      })
    },
    removeObservers() {
      this.$nextTick(() => {
        for (const ref in this.$refs) {
          this.observer.unobserve(this.$refs[ref])
        }
      })
    }
  },
  watch: {
    policies() {
      this.addObservers()
    }
  },
  beforeUnmount() {
    this.observer.disconnect()
  }
}
</script>
<style>
.policy-content p {
  margin-bottom: 1rem;
}
</style>
