<template>
  <main
    class="relative z-10 mx-auto max-w-7xl py-12 px-4 sm:px-6 lg:py-16 lg:px-8"
  >
    <LatestNews />
    <NewsInfo />
  </main>
</template>

<script>
import LatestNews from '@/clientInterface/components/posts/LatestNews'
import NewsInfo from '@/clientInterface/components/posts/NewsInfo'

export default {
  name: 'Home',
  title() {
    return 'KTA - News'
  },
  created() {
    this.$store.dispatch('Posts/getLatest')
    this.$store.dispatch('Posts/getNews')
    this.$store.commit('Posts/setCurrentPage', {
      component: 'news',
      page: null
    })
    this.$store.commit('setHeaderInfo', {
      image: this.config.image,
      title: this.config.title,
      subtitle: this.config.subtitle,
      ctaButtonText: this.config.ctaButtonText,
      ctaLink: this.config.ctaLink
    })
  },
  components: { LatestNews, NewsInfo },
  computed: {
    config() {
      return this.$store.getters.config.home
    }
  },
  watch: {
    config(value) {
      this.$store.commit('setHeaderInfo', {
        image: value.image,
        title: value.title,
        subtitle: value.subtitle,
        ctaButtonText: value.ctaButtonText,
        ctaLink: value.ctaLink
      })
    }
  }
}
</script>
