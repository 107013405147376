// Admin Views
import Admin from '@/adminInterface/Admin.vue'
import AdminHome from '@/adminInterface/views/Home.vue'
import PagesIndex from '@/adminInterface/views/Pages/Index.vue'
import PagesCreate from '@/adminInterface/views/Pages/Create.vue'
import PageHistory from '@/adminInterface/views/Pages/History.vue'

import PostsIndex from '@/adminInterface/views/Posts/Index.vue'
import PostsCreate from '@/adminInterface/views/Posts/Create.vue'

import PoliciesIndex from '@/adminInterface/views/Policies/Index.vue'
import PoliciesCreate from '@/adminInterface/views/Policies/Create.vue'
import PoliciesHistory from '@/adminInterface/views/Policies/History.vue'

import PositionsIndex from '@/adminInterface/views/Positions/Index.vue'
import PositionsCreate from '@/adminInterface/views/Positions/Create.vue'
import PositionHistory from '@/adminInterface/views/Positions/History.vue'

import AccordionsIndex from '@/adminInterface/views/Accordions/Index.vue'
import AccordionCreate from '@/adminInterface/views/Accordions/Create.vue'
import Tags from '@/adminInterface/views/Tags.vue'
import Menus from '@/adminInterface/views/Menus.vue'
import Media from '@/adminInterface/views/Media.vue'
import EditUser from '@/adminInterface/views/Users/Edit.vue'
import Users from '@/adminInterface/views/Users/Index.vue'
import SiteConfig from '@/adminInterface/views/SiteConfig.vue'

const adminRoutes = [
  {
    path: '/admin',
    name: 'Admin',
    component: Admin,
    meta: { auth: true },
    children: [
      {
        path: '',
        name: 'AdminHome',
        component: AdminHome
      },
      {
        path: 'pages',
        name: 'PagesIndex',
        component: PagesIndex
      },
      {
        path: 'pages/create',
        name: 'PagesCreate',
        component: PagesCreate
      },
      {
        path: 'pages/:id',
        name: 'PagesEdit',
        component: PagesCreate
      },
      {
        path: 'pages/:id/history',
        name: 'PageHistory',
        component: PageHistory
      },
      {
        path: 'positions',
        name: 'PositionsIndex',
        component: PositionsIndex
      },
      {
        path: 'positions/create',
        name: 'PositionsCreate',
        component: PositionsCreate
      },
      {
        path: 'positions/:id',
        name: 'PositionsEdit',
        component: PositionsCreate
      },
      {
        path: 'positions/:id/history',
        name: 'PositionHistory',
        component: PositionHistory
      },
      {
        path: 'posts',
        name: 'PostsIndex',
        component: PostsIndex
      },
      {
        path: 'posts/create',
        name: 'PostsCreate',
        component: PostsCreate
      },
      {
        path: 'posts/:id',
        name: 'PostsEdit',
        component: PostsCreate
      },
      {
        path: 'menus',
        name: 'Menus',
        component: Menus
      },
      {
        path: 'accordions',
        name: 'AccordionsIndex',
        component: AccordionsIndex
      },
      {
        path: 'accordions/create',
        name: 'AccordionCreate',
        component: AccordionCreate
      },
      {
        path: 'accordions/:id',
        name: 'AccordionEdit',
        component: AccordionCreate
      },
      {
        path: 'tags',
        name: 'Tags',
        component: Tags
      },
      {
        path: 'media',
        name: 'Media',
        component: Media
      },
      {
        path: 'media/folder/:id',
        name: 'MediaFolder',
        component: Media
      },
      {
        path: 'policies',
        name: 'PoliciesIndex',
        component: PoliciesIndex
      },
      {
        path: 'policies/create',
        name: 'PoliciesCreate',
        component: PoliciesCreate
      },
      {
        path: 'policies/:id',
        name: 'PoliciesEdit',
        component: PoliciesCreate
      },
      {
        path: 'policies/:id/history',
        name: 'PoliciesHistory',
        component: PoliciesHistory
      },
      {
        path: 'users',
        name: 'Users',
        component: Users
      },
      {
        path: 'users/:id',
        name: 'EditUser',
        component: EditUser
      },
      {
        path: 'config',
        name: 'SiteConfig',
        component: SiteConfig
      }
    ]
  }
]

export { adminRoutes }
