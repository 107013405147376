import { format } from 'date-fns'

export default {
  getAll(state) {
    const parser = new DOMParser()
    return state.opportunities.reduce(
      (
        opportunities,
        {
          Attributes,
          applyLink,
          department,
          description,
          expires,
          isPrivate,
          jobId,
          locationName,
          posted,
          summary,
          title,
          wageType
        }
      ) => {
        // remove HTML entities
        description = parser.parseFromString(description, 'text/html').body
          .textContent

        wageType = wageType === 'Salary' ? 'Exempt' : 'Non-exempt'
        expires = format(new Date(expires), 'PP')
        posted = format(new Date(posted), 'PP')

        const matchingJob = opportunities.find((item) => title === item?.title)
        if (!matchingJob) {
          opportunities.push({
            Attributes,
            applyLink,
            department,
            description,
            expires,
            isPrivate,
            jobId,
            locationName,
            posted,
            summary,
            title,
            wageType
          })

          return opportunities
        }

        if (!matchingJob.locations?.length) {
          matchingJob.locations = []
          matchingJob.locations.push({
            applyLink: matchingJob.applyLink,
            jobId: matchingJob.jobId,
            location: matchingJob.locationName
          })
        }

        matchingJob.locations.push({
          applyLink,
          jobId,
          location: locationName
        })

        matchingJob.locationName = 'Various Locations'
        matchingJob.applyLink = ''
        matchingJob.jobId = ''
        return opportunities
      },
      []
    )
  }
}
