export default {
  get(state) {
    return state.users
  },
  count(state) {
    return state.count
  },
  offset(state) {
    return state.offset
  },
  limit(state) {
    return state.limit
  },
  page(state) {
    return state.page
  },
  from(state) {
    return state.offset + 1
  },
  to(state) {
    return state.limit > state.count
      ? state.count
      : state.limit * state.page > state.count
      ? state.count
      : state.limit * state.page
  },
  filter(state) {
    return state.filter
  },
  filterOptions(state) {
    return state.filterOptions
  },
  order(state) {
    return state.order
  },
  orderBy(state) {
    return state.orderBy
  },
  orderOptions(state) {
    return state.orderOptions
  },
  permissions(state) {
    let permissions = {}
    for (const permission in state.permissions) {
      permissions[permission] = state.permissions[permission].filter(
        (action) => action !== 'read'
      )
    }
    return permissions
  }
}
