<template>
  <div>
    <button
      @click="toggleManager"
      type="button"
      class="inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
    >
      Manage Policy Sections
    </button>
    <div class="fixed inset-0 z-40" v-show="showManager">
      <div class="absolute inset-0 flex items-center justify-center">
        <span
          class="absolute inset-0 bg-black opacity-50"
          @click="toggleManager"
        ></span>
        <div class="relative ml-64 w-full max-w-4xl px-6">
          <div class="relative w-full rounded-md bg-gray-50 p-4">
            <h2 class="text-grey-900 mb-4 text-xl font-bold">
              Section Manager
            </h2>
            <ul class="max-h-128 space-y-3 overflow-y-scroll pb-6">
              <li
                class="flex items-center justify-between rounded-md border border-gray-200 bg-white shadow-sm"
                v-for="section in sections"
                :key="section.id"
              >
                <div class="flex-1 truncate px-4 py-2 text-sm leading-5">
                  <div>
                    <span
                      class="mr-2 text-xs font-light uppercase text-gray-400"
                      >Section ID</span
                    >
                    <span class="text-gray-500">{{ section.sectionId }}</span>
                  </div>
                  <div>
                    <span
                      class="mr-2 text-xs font-light uppercase text-gray-400"
                      >Section Name</span
                    >
                    <span
                      class="font-medium text-gray-900 transition duration-150 ease-in-out"
                      >{{ section.name }}</span
                    >
                  </div>
                </div>
                <div
                  class="absolute inset-0"
                  v-if="active === section.id"
                  @click="toggleActive"
                ></div>
                <div class="relative shrink-0 pr-2">
                  <button
                    @click="toggleActive(section.id)"
                    class="relative z-0 inline-flex h-8 w-8 items-center justify-center rounded-full bg-transparent text-gray-400 transition duration-150 ease-in-out hover:text-gray-500 focus:bg-gray-100 focus:text-gray-500 focus:outline-none"
                  >
                    <svg
                      class="h-5 w-5"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        d="M10 6a2 2 0 110-4 2 2 0 010 4zM10 12a2 2 0 110-4 2 2 0 010 4zM10 18a2 2 0 110-4 2 2 0 010 4z"
                      />
                    </svg>
                  </button>
                  <transition name="expand">
                    <div
                      class="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md border border-gray-200 shadow-lg"
                      v-show="active === section.id"
                    >
                      <div
                        class="shadow-xs rounded-md bg-white py-1"
                        role="menu"
                        aria-orientation="vertical"
                        aria-labelledby="user-menu"
                      >
                        <button
                          type="button"
                          class="block w-full px-4 py-2 text-left text-sm text-gray-700 transition duration-150 ease-in-out hover:bg-gray-100"
                          role="menuitem"
                          @click="edit(section)"
                        >
                          Edit
                        </button>
                        <button
                          type="button"
                          class="block w-full px-4 py-2 text-left text-sm text-gray-700 transition duration-150 ease-in-out hover:bg-gray-100"
                          role="menuitem"
                          @click="remove(section.id)"
                        >
                          Remove
                        </button>
                      </div>
                    </div>
                  </transition>
                </div>
              </li>
            </ul>
            <div class="border-t border-gray-200 pt-4">
              <h3 v-text="editing ? 'Edit Section' : 'Create New Section'"></h3>
              <form class="mt-3" submit.prevent>
                <div class="flex items-center space-x-3">
                  <div class="flex flex-1 items-start space-x-3">
                    <Input
                      class="flex-1"
                      v-model="form.name"
                      label="Name"
                      :error="errors.name"
                      errorKey="name"
                    />
                    <Input
                      class="w-1/4"
                      v-model="form.sectionId"
                      label="Section Id"
                      :error="errors.sectionId"
                      errorKey="sectionId"
                    />
                  </div>
                  <button
                    v-if="editing"
                    type="submit"
                    @click.prevent="update"
                    class="focus:shadow-outline-gray mt-1 inline-flex items-center rounded-md border border-transparent bg-gray-600 px-4 py-2 text-sm font-medium leading-5 text-white transition duration-150 ease-in-out hover:bg-gray-500 focus:border-gray-700 focus:outline-none active:bg-gray-700"
                    :class="{ 'mb-4': errors }"
                  >
                    Update
                  </button>
                  <button
                    v-else
                    type="submit"
                    @click.prevent="create"
                    class="focus:shadow-outline-gray mt-1 inline-flex items-center rounded-md border border-transparent bg-gray-600 px-4 py-2 text-sm font-medium leading-5 text-white transition duration-150 ease-in-out hover:bg-gray-500 focus:border-gray-700 focus:outline-none active:bg-gray-700"
                    :class="{ 'mb-4': errors }"
                  >
                    Create
                  </button>
                  <button
                    v-if="editing"
                    type="button"
                    @click="cancel"
                    class="mt-1 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                    :class="{ 'mb-4': errors }"
                  >
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'SectionsManager',
  created() {
    if (!this.sections.length) this.$store.dispatch('AdminPolicies/getSections')
  },
  data: () => ({
    form: {
      id: null,
      sectionId: '',
      name: ''
    },
    active: null,
    editing: false,
    showManager: false
  }),
  methods: {
    toggleManager() {
      if (this.showManager) {
        this.cancel()
        this.$store.commit('removeErrors')
      }
      this.showManager = !this.showManager
    },
    toggleActive(id) {
      this.active === id ? (this.active = null) : (this.active = id)
    },
    cancel() {
      this.form = {
        id: null,
        sectionId: '',
        name: ''
      }
      this.active = null
      this.editing = false
    },
    edit(section) {
      this.form.id = section.id
      this.form.sectionId = section.sectionId
      this.form.name = section.name
      this.active = null
      this.editing = true
    },
    async remove(id) {
      console.log(id)
      // Update this when we add policy manager
      // const selected = await this.$swal({
      //   position: 'center',
      //   icon: 'error',
      //   title: 'Delete Section',
      //   text: 'This will remove this section from all associated policies and prevent the it from displaying. Are you sure you want to delete?',
      //   confirmButtonColor: '#f27474',
      //   confirmButtonText: 'Delete',
      //   showConfirmButton: true,
      //   showCancelButton: true
      // })
      // if (selected.isConfirmed) {
      //   await this.$store.dispatch('AdminPolicies/deleteSection', id)
      //   await this.$store.dispatch('AdminPolicies/getAll')
      //   this.$store.commit('displayMessage', { title: 'Section Removed' })
      //   this.toggleManager()
      // }
    },
    async update() {
      const response = await this.$store.dispatch(
        'AdminPolicies/updateSection',
        this.form
      )
      if (response && response.status === 200) {
        this.$store.dispatch('AdminPolicies/getSections')
        this.$store.dispatch('AdminPolicies/getAll')
        this.$store.commit('displayMessage', { title: 'Section Created' })
        this.cancel()
      }
    },
    async create() {
      // eslint-disable-next-line no-unused-vars
      const { id, ...data } = this.form
      const response = await this.$store.dispatch(
        'AdminPolicies/createSection',
        data
      )
      if (response && response.status === 201) {
        this.$store.dispatch('AdminPolicies/getSections')
        this.$store.commit('displayMessage', { title: 'Section Created' })
        this.form = { id: null, sectionId: '', name: '' }
        this.active = null
      }
    }
  },
  computed: {
    ...mapGetters({
      sections: 'AdminPolicies/sections',
      errors: 'getErrors'
    })
  },
  watch: {
    form: {
      deep: true,
      handler(value) {
        if (this.errors) {
          for (const prop in this.errors) {
            if (
              value[prop] ||
              (typeof value[prop] == 'object' && value[prop].length > 0)
            ) {
              this.$store.commit('removeError', prop)
            }
          }
        }
      }
    }
  }
}
</script>
<style scoped>
.expand-enter-active,
.expand-leave-active {
  transition: transform 0.5s, opacity 0.5s;
}
.expand-enter,
.expand-leave-to {
  opacity: 0;
  transform: scale(0.95);
}
</style>
