<template>
  <div class="pt-2 pb-6 md:py-6">
    <div class="mx-auto mb-6 max-w-7xl px-4 sm:px-6 md:px-8">
      <h1 class="text-2xl font-semibold text-gray-900">Posts</h1>
    </div>
    <DataControls view="AdminPosts" />
    <div class="mx-auto max-w-7xl px-4 sm:px-6 md:px-8">
      <div
        class="mb-4 flex items-center justify-between rounded-md bg-white shadow"
      >
        <router-link
          :to="{ name: 'PostsEdit', params: { id: pinned.id, pinned } }"
          class="block w-full rounded-md p-4 transition duration-150 ease-in-out hover:bg-gray-50 focus:bg-gray-50 focus:outline-none"
          v-if="pinned"
        >
          <span class="mr-2 text-xs uppercase italic text-gray-500"
            >Currently Pinned Post:</span
          >
          <span class="text-gray-800">{{ pinned.title }}</span>
        </router-link>
        <div
          class="block w-full rounded-md p-4 transition duration-150 ease-in-out focus:bg-gray-50 focus:outline-none"
          v-else
        >
          <span class="mr-2 text-xs uppercase italic text-gray-500"
            >Currently Pinned Post:</span
          >
          <span class="text-gray-800"></span>
        </div>
        <button
          type="button"
          class="rounded-r-md bg-gray-600 p-4 text-gray-50 hover:bg-gray-500"
          @click="unpin"
          v-if="pinned"
        >
          Unpin
        </button>
      </div>
      <div class="mb-4 flex items-center justify-between">
        <Paginator
          :from="from"
          :to="to"
          :total="totalPosts"
          position="top"
          @next="next"
          @prev="prev"
          hideButtons
          v-if="totalPosts"
        />
        <span class="inline-flex rounded-md shadow-sm">
          <router-link
            :to="{ name: 'PostsCreate' }"
            tag="button"
            class="focus:shadow-outline-gray inline-flex items-center rounded-md border border-transparent bg-gray-600 px-4 py-2 text-sm font-medium leading-5 text-white transition duration-150 ease-in-out hover:bg-gray-500 focus:border-gray-700 focus:outline-none active:bg-gray-700"
          >
            Create
          </router-link>
        </span>
      </div>
      <div class="overflow-hidden bg-white shadow sm:rounded-md">
        <div class="p-4 italic text-gray-500" v-if="formatted.length < 1">
          You currently have no posts.
        </div>
        <ul>
          <li
            v-for="(post, index) in formatted"
            :key="index"
            :class="[index > 0 ? 'border-t border-gray-200' : '']"
          >
            <router-link
              :to="{ name: 'PostsEdit', params: { id: post.id, post } }"
              class="block transition duration-150 ease-in-out hover:bg-gray-50 focus:bg-gray-50 focus:outline-none"
            >
              <div class="px-4 py-4 sm:px-6">
                <div
                  class="block flex-wrap items-center justify-between sm:flex"
                >
                  <div class="mb-2 text-sm leading-5 sm:mb-0">
                    <span
                      class="mr-2 text-xs font-light uppercase text-gray-400"
                      >Title</span
                    >
                    <span class="text-grey-700 truncate font-medium">{{
                      post.title
                    }}</span>
                  </div>
                  <div
                    class="mt-2 flex items-center text-sm leading-5 text-gray-500 sm:mt-0"
                  >
                    <span
                      class="mr-2 text-xs font-light uppercase text-gray-400"
                      >Slug</span
                    >
                    <span>/{{ post.slug }}/</span>
                  </div>
                </div>
                <div class="mt-2 sm:flex sm:justify-between">
                  <div
                    class="mt-2 flex items-center text-sm leading-5 text-gray-500 sm:mt-0"
                  >
                    <span
                      class="mr-2 text-xs font-light uppercase text-gray-400"
                      >Created</span
                    >
                    <span
                      ><time :datetime="post.created">{{
                        post.created
                      }}</time></span
                    >
                  </div>
                  <div
                    class="mt-2 flex items-center text-sm leading-5 text-gray-500 sm:mt-0"
                  >
                    <span
                      class="mr-2 text-xs font-light uppercase text-gray-400"
                      >Published</span
                    >
                    <span
                      ><time :datetime="post.published">{{
                        post.published
                      }}</time></span
                    >
                  </div>
                </div>
                <div
                  class="flex flex-col-reverse items-start justify-between sm:flex-row"
                >
                  <div>
                    <div
                      class="mt-2 flex items-center"
                      v-show="post.archived || post.important"
                    >
                      <span
                        class="mr-1 inline-flex rounded bg-red-100 px-2 text-xs font-semibold uppercase leading-5 text-red-800"
                        v-show="post.archived"
                        >archived</span
                      >
                      <span
                        class="mr-1 inline-flex rounded bg-red-100 px-2 text-xs font-semibold uppercase leading-5 text-red-800"
                        v-show="post.important"
                        >important</span
                      >
                    </div>
                    <div class="flex shrink-0 flex-wrap">
                      <span
                        class="mt-2 mr-1 inline-flex rounded-md bg-gray-700 px-2 text-xs font-semibold leading-5 text-gray-200"
                        v-for="(tag, index) in post.tags"
                        :key="index"
                      >
                        {{ tag.name }}
                      </span>
                    </div>
                  </div>
                  <div>
                    <div
                      class="mt-2 flex items-center text-sm leading-5 text-gray-500 sm:justify-end"
                      v-show="post.expires"
                    >
                      <span
                        class="mr-2 text-xs font-light uppercase text-gray-400"
                        >Expires</span
                      >
                      <span
                        ><time :datetime="post.expires">{{
                          post.expires
                        }}</time></span
                      >
                    </div>
                    <div
                      class="whitespace-no-wrap mt-2 flex items-center text-sm leading-5 text-gray-500 sm:justify-end"
                      v-show="post.latestUntil"
                    >
                      <span
                        class="mr-2 text-xs font-light uppercase text-gray-400"
                        >Latest Until</span
                      >
                      <span
                        ><time :datetime="post.latestUntil">{{
                          post.latestUntil
                        }}</time></span
                      >
                    </div>
                  </div>
                </div>
              </div>
            </router-link>
          </li>
        </ul>
      </div>
      <Paginator
        class="border-b border-gray-200"
        :from="from"
        :to="to"
        :total="totalPosts"
        @next="next"
        @prev="prev"
      />
    </div>
    <div
      class="mx-auto my-4 flex max-w-7xl justify-between px-4 sm:px-6 md:px-8"
    >
      <div class="flex items-center">
        <label
          for="items"
          class="mr-2 block text-sm font-medium leading-5 text-gray-700 sm:mt-px"
        >
          items
        </label>
        <div class="mt-1 sm:col-span-2 sm:mt-0">
          <div class="max-w-lg rounded-md shadow-sm sm:max-w-xs">
            <select
              v-model="itemsPerPage"
              id="items"
              class="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
            >
              <option>10</option>
              <option>20</option>
              <option>50</option>
            </select>
          </div>
        </div>
      </div>
      <span class="inline-flex rounded-md shadow-sm">
        <router-link
          :to="{ name: 'PostsCreate' }"
          tag="button"
          class="focus:shadow-outline-gray inline-flex items-center rounded-md border border-transparent bg-gray-600 px-4 py-2 text-sm font-medium leading-5 text-white transition duration-150 ease-in-out hover:bg-gray-500 focus:border-gray-700 focus:outline-none active:bg-gray-700"
        >
          Create
        </router-link>
      </span>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import DataControls from '@/adminInterface/components/utilities/DataControls'
import Paginator from '@/adminInterface/components/utilities/Paginator'
export default {
  title: 'Posts - iKTA',
  name: 'PostsIndex',
  components: { DataControls, Paginator },
  created() {
    this.$store.dispatch('AdminPosts/getPinned')
    this.$store.dispatch('AdminPosts/getQuery')
  },
  beforeUpdate() {
    if (!this.$store.getters['AdminTags/getAll'].length)
      this.$store.dispatch('AdminTags/getQuery')
  },
  data() {
    return {
      showLatestSort: false
    }
  },
  computed: {
    ...mapGetters({
      page: 'AdminPosts/page',
      offset: 'AdminPosts/offset',
      errors: 'getErrors',
      totalPosts: 'AdminPosts/count',
      to: 'AdminPosts/to',
      from: 'AdminPosts/from',
      pinned: 'AdminPosts/pinned'
    }),
    itemsPerPage: {
      get() {
        return this.$store.getters['AdminPosts/limit']
      },
      set(value) {
        this.$store.commit('AdminPosts/setLimit', value)
      }
    },
    posts() {
      return this.$_.cloneDeep(this.$store.getters['AdminPosts/get'])
    },
    formatted() {
      const posts = this.posts
      return posts.map((post) => {
        post.created = this.$date.format(
          new Date(post.created),
          'MM/dd/yyyy h:mm a'
        )
        if (post.expires)
          post.expires = this.$date.format(
            new Date(post.expires),
            'MM/dd/yyyy  h:mm a'
          )
        if (post.latestUntil)
          post.latestUntil = this.$date.format(
            new Date(post.latestUntil),
            'MM/dd/yyyy  h:mm a'
          )
        if (post.updated)
          post.updated = this.$date.format(
            new Date(post.updated),
            'MM/dd/yyyy h:mm a'
          )
        if (post.published)
          post.published = this.$date.format(
            new Date(post.published),
            'MM/dd/yyyy  h:mm a'
          )
        return post
      })
    }
  },
  methods: {
    async unpin() {
      await this.$store.dispatch('AdminPosts/update', {
        id: this.pinned.id,
        pinned: false
      })
      this.$store.dispatch('AdminPosts/getPinned')
    },
    orderLatestPosts() {
      this.showLatestSort = true
    },
    next() {
      if (this.to < this.totalPosts) {
        this.$store.commit('AdminPosts/nextPage')
        this.$store.dispatch('AdminPosts/getQuery')
      }
    },
    prev() {
      if (this.offset > 0) {
        this.$store.commit('AdminPosts/prevPage')
        this.$store.dispatch('AdminPosts/getQuery')
      }
    }
  },
  watch: {
    itemsPerPage(value) {
      this.$store.commit('AdminPosts/setLimit', value)
      this.$store.dispatch('AdminPosts/getQuery')
    }
  }
}
</script>
