<template>
  <div class="flex items-center">
    <Popover class="relative">
      <PopoverButton
        class="inline-flex w-full justify-center px-4 py-2 text-sm font-medium"
        @click="showFilters = !showFilters"
      >
        <FilterIcon
          class="mr-2 -ml-1 h-5 w-5 text-gray-600 hover:text-gray-700"
          aria-hidden="true"
        />
        Filter
      </PopoverButton>
      <transition
        enter-active-class="transition duration-200 ease-out"
        enter-from-class="translate-y-1 opacity-0"
        enter-to-class="translate-y-0 opacity-100"
        leave-active-class="transition duration-150 ease-in"
        leave-from-class="translate-y-0 opacity-100"
        leave-to-class="translate-y-1 opacity-0"
      >
        <PopoverPanel
          class="absolute right-0 mt-2 w-64 origin-top-right overflow-hidden rounded-md bg-white px-px shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
        >
          <div
            v-for="section in filters"
            :key="section.id"
            class="border-b border-gray-200 py-1"
          >
            <h3 class="flow-root bg-white">
              <button
                type="button"
                @click="toggleFilterSection(section.id)"
                class="flex w-full items-center justify-between p-3 text-sm text-gray-400 hover:text-gray-500"
              >
                <span class="font-medium text-gray-900">
                  {{ section.name }}
                </span>
                <span class="ml-6 flex items-center">
                  <MinusSmIcon
                    v-if="activeFilterSection === section.id"
                    class="h-5 w-5"
                    aria-hidden="true"
                  />
                  <PlusSmIcon v-else class="h-5 w-5" aria-hidden="true" />
                </span>
              </button>
            </h3>
            <div
              :class="[
                activeFilterSection === section.id
                  ? 'mt-px h-full max-h-60 py-3'
                  : 'h-0',
                'overflow-y-scroll'
              ]"
            >
              <div class="mx-2 space-y-4">
                <div
                  v-for="(option, index) in section.options"
                  :key="index"
                  class="flex items-center"
                >
                  <input
                    :id="`${section.id}-${index}`"
                    v-model="filterBy[section.id]"
                    :value="option"
                    type="checkbox"
                    :checked="option"
                    class="h-4 w-4 rounded border-gray-300 text-kta-blue-600 focus:ring-kta-blue-500"
                  />
                  <label
                    :for="`${section.id}-${index}`"
                    class="ml-3 text-sm text-gray-600"
                  >
                    {{ option }}
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div class="flex justify-end">
            <button
              type="button"
              @click="resetFilterBy"
              class="my-3 inline-flex items-center rounded border border-gray-500 px-2.5 py-1.5 text-xs font-medium text-gray-600 hover:border-gray-800 hover:text-gray-800 focus:outline-none focus:ring-2 focus:ring-gray-600 focus:ring-offset-2"
            >
              Reset
            </button>
            <PopoverButton
              type="button"
              @click="updateFilterBy"
              class="m-3 inline-flex items-center rounded border border-transparent bg-kta-blue-600 px-2.5 py-1.5 text-xs font-medium text-white hover:bg-kta-blue-800 focus:outline-none focus:ring-2 focus:ring-kta-blue-600 focus:ring-offset-2"
            >
              Update
            </PopoverButton>
          </div>
          <div class="border-t border-gray-200 p-3 text-sm">
            <span class="text-xs font-semibold uppercase">Active Filters</span>
            <div
              class="flex flex-wrap items-center text-xs"
              v-if="filterBy.title.length"
            >
              <span class="font-semibold tracking-wide">Title: </span>
              <span
                v-for="title in filterBy.title"
                :key="title"
                class="m-1 whitespace-nowrap rounded-full bg-gray-200 px-2"
                >{{ title }}</span
              >
            </div>
            <div
              class="flex flex-wrap items-center text-xs"
              v-if="filterBy.department.length"
            >
              <span class="font-semibold tracking-wide">Department: </span>
              <span
                v-for="department in filterBy.department"
                :key="department"
                class="m-1 whitespace-nowrap rounded-full bg-gray-200 px-2"
                >{{ department }}</span
              >
            </div>
            <div
              class="flex flex-wrap items-center text-xs"
              v-if="filterBy.location.length"
            >
              <span class="font-semibold tracking-wide">Location: </span>
              <span
                v-for="location in filterBy.location"
                :key="location"
                class="m-1 whitespace-nowrap rounded-full bg-gray-200 px-2"
                >{{ location }}</span
              >
            </div>
          </div>
        </PopoverPanel>
      </transition>
    </Popover>
    <Menu as="div" class="relative inline-block text-left">
      <div>
        <MenuButton
          class="inline-flex w-full justify-center px-4 py-2 text-sm font-medium"
        >
          <SortDescendingIcon
            class="mr-2 -ml-1 h-5 w-5 text-gray-600 hover:text-gray-700"
            aria-hidden="true"
          />
          Sort
        </MenuButton>
      </div>

      <transition
        enter-active-class="transition duration-100 ease-out"
        enter-from-class="transform scale-95 opacity-0"
        enter-to-class="transform scale-100 opacity-100"
        leave-active-class="transition duration-75 ease-in"
        leave-from-class="transform scale-100 opacity-100"
        leave-to-class="transform scale-95 opacity-0"
      >
        <MenuItems
          class="absolute right-0 mt-2 w-56 origin-top-right overflow-hidden rounded-md bg-white py-2 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
        >
          <MenuItem
            v-slot="{ active }"
            v-for="(option, index) in sortOptions"
            :key="index"
          >
            <button
              :class="[
                active ? 'bg-kta-blue-600 text-white' : 'text-gray-900',
                'group flex w-full items-center px-4 py-2 text-sm'
              ]"
              @click="$emit('sortBy', option.value)"
            >
              {{ option.name }}
            </button>
          </MenuItem>
        </MenuItems>
      </transition>
    </Menu>
  </div>
</template>

<script>
import {
  Menu,
  MenuButton,
  MenuItems,
  MenuItem,
  Popover,
  PopoverButton,
  PopoverPanel
} from '@headlessui/vue'
import { FilterIcon, SortDescendingIcon } from '@heroicons/vue/outline'
import { MinusSmIcon, PlusSmIcon } from '@heroicons/vue/solid'
import { uniq } from 'lodash'
export default {
  components: {
    Menu,
    MenuButton,
    MenuItems,
    MenuItem,
    Popover,
    PopoverButton,
    PopoverPanel,
    FilterIcon,
    SortDescendingIcon,
    MinusSmIcon,
    PlusSmIcon
  },
  props: {
    directory: {
      type: Array,
      required: true
    }
  },
  data: () => ({
    sortOptions: [
      {
        name: 'First Name',
        value: 'firstName'
      },
      {
        name: 'Last Name',
        value: 'lastName'
      },
      {
        name: 'Department',
        value: 'department'
      },
      {
        name: 'Location',
        value: 'location'
      }
    ],
    activeFilterSection: null,
    filterBy: {
      title: [],
      department: [],
      location: []
    }
  }),
  computed: {
    filters() {
      return [
        {
          id: 'title',
          name: 'Title',
          options: this.titles
        },
        {
          id: 'department',
          name: 'Department',
          options: this.departments
        },
        {
          id: 'location',
          name: 'Location',
          options: this.locations
        }
      ]
    },
    titles() {
      return uniq(this.directory.map((item) => item.title)).sort()
    },
    departments() {
      const departments = this.directory.map((item) => item.department.trim())
      const result = departments.reduce((acc, department) => {
        const locationIndex = this.locations.findIndex((location) =>
          department.includes(location)
        )
        const hasLocation = locationIndex > -1
        const location = this.locations[locationIndex]

        if (hasLocation) {
          const pairedDownItem = department.replace(location, '').trim()
          acc.push(pairedDownItem)
        } else {
          acc.push(department)
        }
        return acc
      }, [])
      return uniq(result).sort()
    },
    locations() {
      return uniq(this.directory.map((item) => item.location.trim()))
        .sort()
        .filter((item) => item)
    }
  },
  methods: {
    updateFilterBy() {
      const filters = this.$_.cloneDeep(this.filterBy)
      this.$emit('filterBy', filters)
    },
    resetFilterBy() {
      this.filterBy = {
        title: [],
        department: [],
        location: []
      }
    },
    toggleFilterSection(section) {
      this.activeFilterSection =
        this.activeFilterSection === section ? null : section
    }
  }
}
</script>
