export default {
  config(state) {
    return state.config || localStorage.getItem('siteConfig')
  },
  activeMessage(state) {
    return state.alert.title || state.alert.text ? state.alert : false
  },
  getErrors(state) {
    if (state.errors && 'message' in state.errors) {
      if (typeof state.errors.message === 'string')
        return { message: state.errors.message }
      let errors = {}
      state.errors.message.forEach((item) => {
        if (!item.children.length) {
          errors[item.property] = Object.values(item.constraints)[0]
          return
        }

        item.children.forEach((childItem) => {
          if (!childItem.children.length) {
            errors[childItem.property] = Object.values(childItem.constraints)[0]
            return
          }

          errors[item.property] = item.children.map((properties) => {
            let obj = {
              index: parseInt(properties.property)
            }
            properties.children.forEach((prop) => {
              obj[prop.property] = Object.values(prop.constraints)[0]
            })
            return obj
          })
        })
      })
      return errors
    }
    return false
  },
  headerInfo(state) {
    return state.headerInfo
  },
  months(state) {
    return state.months
  }
}
