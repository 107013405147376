<template>
  <div v-if="config">
    <h1
      class="mb-6 text-4xl font-bold text-kta-blue-800"
      v-if="!config.image && config.title"
    >
      {{ config.title }}
    </h1>
    <div
      class="mb-6 pb-4"
      v-if="config.introText"
      v-html="config.introText"
    ></div>
  </div>
</template>

<script>
export default {
  name: 'ComponentHeader',
  props: {
    component: {
      type: String,
      required: true
    }
  },
  async mounted() {
    await this.$store.commit('setHeaderInfo', {
      image: this.config.image,
      title: this.config.title,
      subtitle: this.config.subtitle,
      ctaButtonText: this.config.ctaButtonText,
      ctaLink: this.config.ctaLink
    })
  },
  computed: {
    config() {
      return this.$store.getters.config[this.component]
        ? this.$store.getters.config[this.component]
        : {}
    }
  },
  watch: {
    config(value) {
      this.$store.commit('setHeaderInfo', {
        image: value.image,
        title: value.title,
        subtitle: value.subtitle,
        ctaButtonText: value.ctaButtonText,
        ctaLink: value.ctaLink
      })
    },
    component(value) {
      this.$store.commit('setHeaderInfo', {
        image: value.image,
        title: value.title,
        subtitle: value.subtitle,
        ctaButtonText: value.ctaButtonText,
        ctaLink: value.ctaLink
      })
    }
  }
}
</script>

<style></style>
