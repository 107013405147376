<template>
  <div class="mt-8 border-t border-solid pt-8">
    <h2 class="mb-4 text-xl font-semibold text-gray-900">
      {{ title }}
    </h2>
    <div class="grid grid-cols-3 gap-x-4">
      <Input
        v-model="config.title"
        label="Title"
        :error="errors.title"
        errorKey="title"
      />
      <Input
        v-model="config.subtitle"
        label="Subtitle"
        :error="errors.subtitle"
        errorKey="subtitle"
      />
      <div class="row-span-2">
        <div class="w-full">
          <label class="mb-1 block text-sm font-medium leading-5 text-gray-700"
            >Hero Image</label
          >
          <div class="flex items-center rounded-md shadow-sm">
            <span class="inline-flex rounded-md">
              <button
                @click="toggleFilePicker"
                type="button"
                class="focus:shadow-outline-gray inline-flex items-center rounded-l-md border border-gray-700 bg-gray-600 px-4 py-2 text-sm font-medium leading-5 text-white transition duration-150 ease-in-out hover:bg-gray-500 focus:border-gray-700 focus:outline-none active:bg-gray-700"
              >
                Select
              </button>
            </span>
            <div
              class="w-full overflow-x-scroll whitespace-nowrap rounded-r-md border border-l-0 border-gray-300 bg-gray-50 px-4 py-2 text-sm leading-5 text-gray-600"
            >
              <span class="inline-block h-3">{{ config.image }}</span>
            </div>
          </div>
          <div class="mt-1 text-xs italic text-gray-500">
            Images need to be 1600px x 400px
          </div>
          <FilePicker
            class="z-10"
            v-if="filePickerActive"
            @cancel="toggleFilePicker"
            @insertImage="setImage"
            imagesOnly
            :showStyle="false"
          />
        </div>
        <div
          v-if="config.image"
          class="relative mt-4 w-full rounded border border-solid border-gray-300 pb-1/4"
        >
          <img
            class="absolute h-full w-full object-contain"
            :src="config.image"
            alt="header image"
          />
          <button
            type="button"
            class="absolute top-0 right-0 mr-2 mt-2 text-gray-400 transition hover:text-gray-600 focus:ring-2"
            @click="removeImage"
          >
            <svg class="h-6 w-6" viewBox="0 0 20 20" fill="currentColor">
              <path
                fill-rule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                clip-rule="evenodd"
              />
            </svg>
          </button>
        </div>
      </div>
      <Input
        v-model="config.ctaButtonText"
        label="Button Text"
        :error="errors.ctaButtonText"
        errorKey="ctaButtonText"
      />
      <Input
        v-model="config.ctaLink"
        label="Link"
        :error="errors.ctaLink"
        errorKey="ctaLink"
      />
    </div>
    <Checkbox
      v-model="config.includeIntroText"
      :name="`${appComponent}-intro`"
      label="Include Intro Text"
      :error="errors.hasIntro"
      class="mb-4"
    />
    <Wysiwyg
      v-model="config.introText"
      :error="errors.intro"
      v-if="config.includeIntroText"
    />
  </div>
</template>

<script>
import FilePicker from '@/adminInterface/components/utilities/FilePicker'
import Wysiwyg from './form/Wysiwyg.vue'
export default {
  name: 'ComponentConfig',
  components: { FilePicker, Wysiwyg },
  props: {
    title: {
      type: String,
      required: true
    },
    appComponent: {
      type: String,
      required: true
    }
  },
  data: () => ({
    filePickerActive: false,
    dynamicSlug: true
  }),
  computed: {
    config() {
      return this.$store.getters.config[this.appComponent]
        ? this.$store.getters.config[this.appComponent]
        : {}
    },
    errors() {
      return {}
    }
  },
  methods: {
    updateimage(value, event) {
      if (this.dynamicSlug) this.dynamicSlug = false
      if (event.data === ' ' || event.data === '-') {
        return (this.config.image = event.target.value.replaceAll(/\s/g, '-'))
      }
      this.config.image = this.$_.kebabCase(event.target.value)
      this.$forceUpdate()
    },
    toggleFilePicker() {
      this.filePickerActive = !this.filePickerActive
    },
    setImage(image) {
      this.config.image = image.src
      this.toggleFilePicker()
    },
    removeImage() {
      this.config.image = ''
    }
  }
}
</script>

<style></style>
