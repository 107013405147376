import axios from 'axios'

export default {
  async getAll({ commit, rootGetters }) {
    try {
      const { data } = await axios.get(
        `${process.env.VUE_APP_API_URL}/positions`,
        rootGetters['User/config']
      )
      commit('set', data)
      return data
    } catch (error) {
      commit('handleErrors', error.response.data, { root: true })
    }
  },
  async getPdf({ commit, rootGetters }, id) {
    try {
      const { data } = await axios.get(
        `${process.env.VUE_APP_API_URL}/positions/${id}/pdf`,
        { ...rootGetters['User/config'], responseType: 'blob' }
      )
      return URL.createObjectURL(new Blob([data]))
    } catch (error) {
      commit('handleErrors', error.response.data, { root: true })
    }
  }
}
