<template>
  <div class="pt-2 pb-6 md:py-6">
    <div class="mx-auto max-w-7xl px-4 sm:px-6 md:px-8">
      <h1 class="text-2xl font-semibold text-gray-900">Page History</h1>
      <div class="mt-6 items-start xl:flex xl:space-x-3">
        <div>
          <h2 class="mb-2 font-semibold text-gray-700">Versions</h2>
          <ul class="space-y-2">
            <li
              class="relative flex cursor-pointer items-center justify-between rounded-lg bg-white p-2 text-sm font-light tracking-wide shadow-sm"
              :class="[
                active === index
                  ? 'bg-gray-500 text-white ring-1 ring-gray-700'
                  : 'text-gray-700 ring-1 ring-transparent hover:bg-gray-50 hover:ring-gray-200'
              ]"
              v-for="(history, index) in histories"
              :key="history.id"
              @click="active = index"
            >
              <span
                >{{ history.created }} by {{ history.user?.firstName }}
                {{ history.user?.lastName }}</span
              >
              <span
                class="ml-5 rounded-md bg-blue-200 px-2 py-1 text-xs uppercase text-blue-900"
                v-if="index === 0 || !history.length > 1"
                >current version</span
              >
              <span
                class="ml-5 rounded-md bg-green-100 px-2 py-1 text-xs uppercase text-green-900"
                v-if="index === histories.length - 1"
                >original version</span
              >
            </li>
          </ul>
        </div>
        <div class="mt-8 flex-1 overflow-hidden bg-white shadow sm:rounded-lg">
          <div class="px-4 py-5 sm:p-0" v-if="selected">
            <dl class="sm:divide-y sm:divide-gray-200">
              <div class="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                <dt class="text-sm font-medium text-gray-500">Title</dt>
                <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                  {{ selected.title }}
                </dd>
              </div>
              <div class="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                <dt class="text-sm font-medium text-gray-500">Department</dt>
                <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                  {{ selected.department }}
                </dd>
              </div>
              <div class="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                <dt class="text-sm font-medium text-gray-500">Exempt Status</dt>
                <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                  <span
                    v-text="selected.exempt ? 'Exempt' : 'Non-exempt'"
                  ></span>
                </dd>
              </div>
              <div class="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                <dt class="text-sm font-medium text-gray-500">Pay Grade</dt>
                <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                  {{ selected.payGrade }}
                </dd>
              </div>
              <div class="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                <dt class="text-sm font-medium text-gray-500">
                  Supervisor Title
                </dt>
                <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                  {{ selected.supervisorTitle }}
                </dd>
              </div>
              <div class="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                <dt class="text-sm font-medium text-gray-500">
                  Direct Reports
                </dt>
                <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                  {{ selected.directReports }}
                </dd>
              </div>
              <div class="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                <dt class="text-sm font-medium text-gray-500">Other Inputs</dt>
                <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                  {{ selected.otherInputs }}
                </dd>
              </div>
              <div class="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                <dt class="text-sm font-medium text-gray-500">Summary</dt>
                <dd
                  class="content-preview mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0"
                  v-html="selected.summary"
                ></dd>
              </div>
              <div class="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                <dt class="text-sm font-medium text-gray-500">Content</dt>
                <dd
                  class="content-preview mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0"
                  v-html="selected.content"
                ></dd>
              </div>
            </dl>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  title: 'Position History - iKTA',
  name: 'PositionHistory',
  async created() {
    this.position = await this.$store.dispatch(
      'AdminPositions/get',
      this.$route.params.id
    )
  },
  data() {
    return {
      position: null,
      active: 0
    }
  },
  computed: {
    histories() {
      if (this.position) {
        const versions = this.position.history.map((history) => {
          history.created = this.$date.format(
            new Date(history.created),
            `LLL dd yyyy 'at' h:mm a`
          )
          history.to = JSON.parse(history.to)
          history.from = JSON.parse(history.from)
          return history
        })
        versions.unshift({
          created: this.$date.format(
            new Date(this.position.created),
            `LLL dd yyyy 'at' h:mm a`
          )
        })

        return versions.reverse()
      }
      return {}
    },
    selected() {
      return this.versions[this.active]
    },
    versions() {
      let versions = []
      if (this.position) {
        const position = this.$_.cloneDeep(this.position)
        versions.push(position)
        this.histories.forEach((history, index) => {
          if (index === this.histories.length - 1) return
          const position = this.$_.cloneDeep(versions[index])
          for (const key in history.from) {
            position[key] = history.from[key]
          }
          versions.push(position)
        })
        return versions
      }

      return []
    }
  }
}
</script>

<style>
.content-preview p {
  margin-bottom: 1rem;
}
</style>
