import { format } from 'date-fns'
import { cloneDeep } from 'lodash'
export default {
  getFolders(state) {
    return state.folders
  },
  getFiles(state) {
    const files = cloneDeep(state.files).sort((a, b) => {
      return state.sortFiles.direction === 'DESC'
        ? a[state.sortFiles.by] < b[state.sortFiles.by]
          ? 1
          : -1
        : a[state.sortFiles.by] > b[state.sortFiles.by]
        ? 1
        : -1
    })
    return files.map((file) => {
      file.added = format(new Date(file.added), 'MM/dd/yyyy h:mm a')
      file.size > 1000000
        ? (file.size = `${(file.size / 1000000).toFixed(2)} Mb`)
        : (file.size = `${(file.size / 1000).toFixed(2)} Kb`)
      return file
    })
  },
  getImageFiles(state, getters) {
    return getters.getFiles.filter((file) => file.type.includes('image'))
  },

  getActiveFolder(state) {
    return state.activeFolder
  },
  sortedBy(state) {
    return state.sortFiles
  }
}
