<template>
  <div class="pt-2 pb-6 md:py-6">
    <div class="mx-auto max-w-7xl px-4 sm:px-6 md:px-8">
      <h1 class="text-2xl font-semibold text-gray-900">{{ pageTitle }}</h1>
    </div>
    <div class="mx-auto max-w-7xl px-4 sm:px-6 md:px-8">
      <form @submit.prevent>
        <div>
          <div class="items-start lg:flex lg:space-x-3">
            <Input
              class="flex-1"
              v-model="form.title"
              label="Title"
              :error="errors.title"
            />
            <Input
              class="flex-1"
              v-model="form.subtitle"
              label="Subtitle"
              :error="errors.subtitle"
            />
          </div>
          <div class="items-start lg:flex lg:space-x-3">
            <Input
              v-model="form.slug"
              class="lg:w-1/2"
              label="Slug"
              :error="errors.slug"
              @input="updateSlug"
            />
            <div class="w-full lg:w-1/2">
              <label
                class="mb-1 block text-sm font-medium leading-5 text-gray-700"
              >
                Header Image
              </label>
              <div class="flex items-center rounded-md shadow-sm">
                <span class="inline-flex rounded-md">
                  <button
                    @click="toggleFilePicker"
                    type="button"
                    class="focus:shadow-outline-gray inline-flex items-center rounded-l-md border border-gray-700 bg-gray-600 px-4 py-2 text-sm font-medium leading-5 text-white transition duration-150 ease-in-out hover:bg-gray-500 focus:border-gray-700 focus:outline-none active:bg-gray-700"
                  >
                    Select
                  </button>
                </span>
                <div
                  class="w-full overflow-x-scroll whitespace-nowrap rounded-r-md border border-l-0 border-gray-300 bg-gray-50 px-4 py-2 text-sm leading-5 text-gray-600"
                >
                  <span class="inline-block h-3">{{
                    form.headerImage.path
                  }}</span>
                </div>
              </div>
              <div class="mt-1 text-xs italic text-gray-500">
                Images need to be 1600px x 400px
              </div>
              <FilePicker
                class="z-10"
                v-if="filePickerActive"
                @cancel="toggleFilePicker"
                @insertImage="setHeaderImage"
                imagesOnly
                :showStyle="false"
              />
            </div>
          </div>
          <div
            v-if="form.headerImage.path"
            class="relative mt-4 w-full rounded border border-solid border-gray-300 pb-1/4"
          >
            <img
              class="absolute h-full w-full object-contain"
              :src="form.headerImage.path"
              alt="header image"
            />
            <button
              type="button"
              class="absolute top-0 right-0 mr-2 mt-2 text-gray-400 transition hover:text-gray-600 focus:ring-2"
              @click="removeImage"
            >
              <svg class="h-6 w-6" viewBox="0 0 20 20" fill="currentColor">
                <path
                  fill-rule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                  clip-rule="evenodd"
                />
              </svg>
            </button>
          </div>
          <div class="mt-6">
            <label
              class="mb-1 block text-sm font-medium leading-5 text-gray-700"
            >
              Body
            </label>
            <Wysiwyg
              aria-label="body"
              v-model="form.body"
              :error="errors.body"
            />
          </div>
          <DateSelector
            class="mt-4"
            v-model="form.published"
            label="Published"
            includeSwitch
            includeTime
          />
        </div>
        <div class="mt-8 border-t border-gray-200 pt-5">
          <div class="flex justify-between">
            <div class="flex items-center space-x-3">
              <span class="inline-flex rounded-md shadow-sm" v-if="editing">
                <button
                  type="submit"
                  class="focus:shadow-outline-red inline-flex justify-center rounded-md border border-transparent bg-red-600 py-2 px-4 text-sm font-medium leading-5 text-white transition duration-150 ease-in-out hover:bg-red-500 focus:border-red-700 focus:outline-none active:bg-red-700"
                  @click="remove"
                >
                  Delete
                </button>
              </span>
              <span class="space-x-3 text-sm italic" v-if="editing">
                <span class="font-light text-gray-700" v-show="lastEdit"
                  >Last edited on {{ lastEdit }}</span
                >
                <span class="font-light text-gray-700" v-show="!lastEdit"
                  >This is the original version</span
                >
                <router-link
                  class="text-blue-700 underline"
                  :to="{ name: 'PageHistory' }"
                  v-show="lastEdit"
                  >(Version History)</router-link
                >
              </span>
            </div>
            <div class="flex justify-between">
              <span class="inline-flex rounded-md shadow-sm">
                <button
                  type="button"
                  class="focus:shadow-outline-blue rounded-md border border-gray-300 py-2 px-4 text-sm font-medium leading-5 text-gray-700 transition duration-150 ease-in-out hover:text-gray-500 focus:border-blue-300 focus:outline-none active:bg-gray-50 active:text-gray-800"
                  @click="cancel"
                >
                  Cancel
                </button>
              </span>
              <span class="ml-3 inline-flex rounded-md shadow-sm">
                <button
                  type="submit"
                  class="focus:shadow-outline-gray inline-flex justify-center rounded-md border border-transparent bg-gray-600 py-2 px-4 text-sm font-medium leading-5 text-white transition duration-150 ease-in-out hover:bg-gray-500 focus:border-gray-700 focus:outline-none active:bg-gray-700"
                  @click="submit"
                  v-text="editing ? 'Update' : 'Save'"
                ></button>
              </span>
            </div>
          </div>
        </div>
      </form>
    </div>
    <ConfirmDialog :isOpen="confirm" @confirmed="confirmed" />
  </div>
</template>

<script>
import ConfirmDialog from '@/adminInterface/components/utilities/ConfirmDialog'
import DateSelector from '@/adminInterface/components/form/DateSelector'
import FilePicker from '@/adminInterface/components/utilities/FilePicker'
import Wysiwyg from '@/adminInterface/components/form/Wysiwyg'

export default {
  name: 'CreateEditPage',
  created() {
    if (this.$route.params.id) {
      this.pageTitle = 'Edit Page'
      this.loadData()
    }
  },
  data() {
    return {
      pageTitle: 'Create Page',
      confirm: false,
      form: {
        title: '',
        subtitle: '',
        slug: '',
        headerImage: {
          id: null,
          path: ''
        },
        body: '',
        published: ''
      },
      history: [],
      dynamicSlug: true,
      filePickerActive: false
    }
  },
  methods: {
    submit() {
      this.editing ? this.update() : this.create()
    },
    async create() {
      if (!this.form.published) {
        this.form.published = null
      }
      const form = this.$_.cloneDeep(this.form)
      form.headerImageId = form.headerImage.id
      delete form.headerImage
      await this.$store.dispatch('AdminPages/create', form)
    },
    async update() {
      const form = this.$_.cloneDeep(this.form)
      for (const prop in form) {
        if (this.$_.isEqual(this.original[prop], form[prop])) delete form[prop]
      }
      if (form.headerImage) {
        form.headerImageId = form.headerImage.id
        delete form.headerImage
      }
      form.id = this.original.id
      await this.$store.dispatch('AdminPages/update', form)
    },
    async confirmed(confirmed) {
      if (confirmed) {
        await this.$store.dispatch('AdminPages/delete', this.$route.params.id)
      }
      this.confirm = false
    },
    async remove() {
      this.confirm = true
    },
    cancel() {
      this.$router.push({ name: 'PagesIndex' })
    },
    updateSlug(event) {
      if (this.dynamicSlug) this.dynamicSlug = false
      if (event.data === ' ' || event.data === '-') {
        return (this.form.slug = event.target.value.replaceAll(/\s+/g, '-'))
      }
      this.form.slug = `/${this.$_.kebabCase(event.target.value)}`
      this.$forceUpdate()
    },
    toggleFilePicker() {
      this.filePickerActive = !this.filePickerActive
    },
    setHeaderImage(image) {
      this.form.headerImage.path = image.src
      this.form.headerImage.id = image.id
      this.toggleFilePicker()
    },
    removeImage() {
      this.form.headerImage = ''
      this.form.headerImageId = null
    },
    async loadData() {
      if (this.editing) {
        const page = await this.$store.dispatch(
          'AdminPages/get',
          this.$route.params.id
        )
        if (!page.headerImage) page.headerImage = { id: null, path: '' }
        const { history, ...data } = page
        this.history = history
        this.dynamicSlug = false
        this.form = data
        this.original = this.$_.cloneDeep(page)
      }
    }
  },
  computed: {
    pages() {
      return this.$store.getters['AdminPages/get']
    },
    editing() {
      return !!this.$route.params.id
    },
    errors() {
      return this.$store.getters.getErrors
    },
    editCount() {
      return this.history.length
    },
    lastEdit() {
      if (this.history.length) {
        const lastEdit = this.$_.last(this.history)?.created
        return this.$date.format(new Date(lastEdit), 'MM/dd/yyyy')
      }
      return false
    }
  },
  watch: {
    pages(value) {
      this.loadData(value)
    },
    form: {
      deep: true,
      handler(value) {
        if (this.form.title && this.dynamicSlug) {
          this.form.slug = this.$_.kebabCase(value.title)
        }
      }
    }
  },
  components: {
    ConfirmDialog,
    DateSelector,
    FilePicker,
    Wysiwyg
  }
}
</script>
