import actions from './actions'
import mutations from './mutations'
import getters from './getters'

export default {
  namespaced: true,
  state: () => ({
    policies: [],
    count: 0,
    offset: 0,
    limit: 10,
    filter: null,
    filterBy: null,
    filterOptions: [
      { key: 'sectionName', value: 'Section Name', filterBy: true },
      { key: 'sectionId', value: 'Section ID', filterBy: true }
    ],
    order: 'ASC',
    orderBy: { key: 'title', value: 'Title' },
    orderOptions: [
      { key: 'sectionName', value: 'Section Name' },
      { key: 'sectionId', value: 'Section ID' },
      { key: 'title', value: 'Title' },
      { key: 'effective', value: 'Effective Date' }
    ],
    page: 1,
    sections: []
  }),
  mutations,
  getters,
  actions
}
