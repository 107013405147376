<template>
  <transition
    enter-active-class="transition ease-out duration-200"
    enter-from-class="opacity-0"
    enter-to-class="opacity-100"
    leave-active-class="transition ease-in duration-200"
    leave-from-class="opacity-100"
    leave-to-class="opacity-0"
  >
    <div
      v-if="alert"
      :class="`fixed top-5 left-5 right-5 z-50 rounded-md bg-${color}-50 p-4 shadow`"
    >
      <div class="flex">
        <div class="shrink-0">
          <ExclamationIcon
            class="h-5 w-5 text-yellow-400"
            aria-hidden="true"
            v-if="level === 'warning'"
          />
          <XCircleIcon
            class="h-5 w-5 text-red-400"
            aria-hidden="true"
            v-if="level === 'error'"
          />
          <CheckCircleIcon
            class="h-5 w-5 text-green-400"
            aria-hidden="true"
            v-if="level === 'success'"
          />
          <InformationCircleIcon
            class="h-5 w-5 text-blue-400"
            aria-hidden="true"
            v-if="level === 'info'"
          />
        </div>
        <div class="ml-3">
          <h3 :class="`text-sm font-medium text-${color}-800`" v-if="title">
            {{ title }}
          </h3>
          <div
            :class="`mt-2 text-sm text-${color}-700`"
            v-if="text"
            v-html="text"
          ></div>
        </div>
        <div class="ml-auto pl-3">
          <div class="-mx-1.5 -my-1.5">
            <button
              @click="close"
              type="button"
              :class="`
                inline-flex
                bg-${color}-50
                rounded-md
                p-1.5
                text-${color}-500
                hover:bg-${color}-100
                focus:outline-none
                focus:ring-2
                focus:ring-offset-2
                focus:ring-offset-${color}-50
                focus:ring-${color}-600
              `"
            >
              <span class="sr-only">Dismiss</span>
              <XIcon class="h-5 w-5" aria-hidden="true" />
            </button>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
import {
  CheckCircleIcon,
  ExclamationIcon,
  InformationCircleIcon,
  XCircleIcon,
  XIcon
} from '@heroicons/vue/solid'

export default {
  name: 'Alert',
  components: {
    CheckCircleIcon,
    ExclamationIcon,
    InformationCircleIcon,
    XCircleIcon,
    XIcon
  },
  computed: {
    alert() {
      return this.$store.getters.activeMessage
    },
    color() {
      switch (this.alert.level) {
        case 'success':
          return 'green'
        case 'error':
          return 'red'
        case 'warning':
          return 'yellow'
        default:
          return 'blue'
      }
    },
    error() {
      return this.$store.getters.getErrors
    },
    level() {
      return this.alert ? this.alert.level : 'info'
    },
    text() {
      return this.alert.text
    },
    title() {
      return this.alert.title
    }
  },
  methods: {
    close() {
      this.$store.commit('closeAlert')
    }
  },
  watch: {
    alert: {
      handler(value) {
        if (value.timer) {
          setTimeout(() => {
            this.close()
          }, value.timer)
        }
      },
      deep: true
    }
  }
}
</script>
