export default {
  set(state, tags) {
    state.tags = tags
  },
  add(state, tags) {
    state.tags = [...state.tags, ...tags]
  },
  update(state, newTag) {
    const index = state.tags.findIndex((tag) => tag.id === newTag.id)
    state.tags[index].name = newTag.name
  },
  remove(state, id) {
    state.tags = state.tags.filter((tag) => tag.id !== id)
  }
}
