import router from '@/router'

export default {
  setTokens(state, tokens) {
    state.accessToken = tokens.accessToken
    state.refreshToken = tokens.refreshToken

    localStorage.setItem('accessToken', tokens.accessToken)
    localStorage.setItem('refreshToken', tokens.refreshToken)
  },

  retrieveStoredTokens(state) {
    state.accessToken = localStorage.getItem('accessToken')
    state.refreshToken = localStorage.getItem('refreshToken')
  },

  logout(state) {
    state.accessToken = null
    state.refreshToken = null
    localStorage.removeItem('accessToken')
    localStorage.removeItem('refreshToken')
  },

  setPaychecks(state, paychecks) {
    state.paychecks = paychecks
  },

  setW2s(state, w2s) {
    state.w2s = w2s
  },

  setUser(state, user) {
    state.user = user
  },

  setUserPersonalMobile(state, mobile) {
    state.user.personalMobile = mobile
  },

  setContactOptions(state, options) {
    state.contactOptions = options
  },
  setRefreshing(state, status) {
    state.refreshing = status
  },
  clearPasswordReset(state) {
    state.contactOptions = []
  },
  checkRedirect() {
    const redirect = sessionStorage.getItem('from')
    sessionStorage.removeItem('from')
    redirect ? router.push(redirect) : router.push({ name: 'Home' })
  }
}
