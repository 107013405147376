import axios from 'axios'
import router from '@/router'

export default {
  async verify({ commit }, form) {
    try {
      const { data } = await axios.post(
        `${process.env.VUE_APP_API_URL}/users/verify`,
        form
      )
      return data
    } catch (error) {
      commit('handleErrors', error.response.data, { root: true })
    }
  },

  async register({ commit }, form) {
    try {
      return await axios.post(
        `${process.env.VUE_APP_API_URL}/users/register`,
        form
      )
    } catch (error) {
      commit('handleErrors', error.response.data, { root: true })
    }
  },

  async login({ commit }, form) {
    try {
      const { data } = await axios.post(
        `${process.env.VUE_APP_API_URL}/auth/login`,
        form
      )
      commit('setTokens', data)
      commit('checkRedirect')
    } catch (error) {
      commit('handleErrors', error.response.data, { root: true })
    }
  },

  async refreshTokens({ state, commit }) {
    try {
      commit('setRefreshing', true)
      const { data } = await axios.post(
        `${process.env.VUE_APP_API_URL}/auth/refresh`,
        { refreshToken: state.refreshToken }
      )
      commit('setTokens', data)
      commit('setRefreshing', false)
    } catch (error) {
      commit('handleErrors', error.response.data, { root: true })
    }
  },

  async fetchUser({ commit, getters }) {
    try {
      const { data } = await axios.get(
        `${process.env.VUE_APP_API_URL}/users/${getters.id}`,
        getters['config']
      )
      commit('setUser', data)
    } catch (errors) {
      commit('handleErrors', errors.response.data, { root: true })
    }
  },
  async update({ commit, getters }, params) {
    try {
      const { data } = await axios.patch(
        `${process.env.VUE_APP_API_URL}/users/${params.id}`,
        params.props,
        getters['config']
      )
      commit('setUser', data)
    } catch (errors) {
      commit('handleErrors', errors.response.data, { root: true })
    }
  },
  async fetchPaychecks({ commit, getters }) {
    try {
      const { data } = await axios.get(
        `${process.env.VUE_APP_API_URL}/users/${getters.id}/paychecks`,
        getters.config
      )
      commit('setPaychecks', data)
      return 'success'
    } catch (error) {
      commit('handleErrors', error.response.data, { root: true })
    }
  },

  async fetchPaycheck({ commit, getters }, date) {
    const [year, month, day] = date.split('-')
    try {
      const { data } = await axios.get(
        `${process.env.VUE_APP_API_URL}/users/${getters.id}/paychecks/${month}-${day}-${year}`,
        { responseType: 'arraybuffer', ...getters.config }
      )
      const file = new File([data], `${date}_paystub.pdf`, {
        type: 'application/pdf'
      })
      return URL.createObjectURL(file)
    } catch (error) {
      commit('handleErrors', error.response.data, { root: true })
    }
  },

  async fetchW2s({ commit, getters }) {
    try {
      const { data } = await axios.get(
        `${process.env.VUE_APP_API_URL}/users/${getters.id}/w-2s`,
        getters.config
      )
      commit('setW2s', data)
      return 'success'
    } catch (error) {
      commit('handleErrors', error.response.data, { root: true })
    }
  },

  async fetchW2({ commit, getters }, date) {
    const [year, month, day] = date.split('-')
    try {
      const { data } = await axios.get(
        `${process.env.VUE_APP_API_URL}/users/${getters.id}/w-2s/${month}-${day}-${year}`,
        { responseType: 'arraybuffer', ...getters.config }
      )
      const file = new File([data], `${date}_w-2.pdf`, {
        type: 'application/pdf'
      })
      return URL.createObjectURL(file)
    } catch (error) {
      commit('handleErrors', error.response.data, { root: true })
    }
  },

  async fetchResetContactOptions({ commit }, id) {
    try {
      const { data } = await axios.post(
        `${process.env.VUE_APP_API_URL}/auth/request-reset`,
        { id }
      )
      commit('setContactOptions', data)
    } catch (error) {
      commit('handleErrors', error.response.data, { root: true })
    }
  },

  async sendVerificationCode({ commit }, selection) {
    try {
      await axios.post(
        `${process.env.VUE_APP_API_URL}/auth/send-verification-code`,
        selection
      )
      return true
    } catch (error) {
      commit('handleErrors', error.response.data, { root: true })
    }
  },

  async resetPassword({ commit }, details) {
    try {
      const { data } = await axios.post(
        `${process.env.VUE_APP_API_URL}/auth/reset-password`,
        details
      )
      commit('setTokens', data)
      commit(
        'displayAlert',
        {
          position: 'top',
          level: 'success',
          title: 'Password updated.',
          timer: 3000
        },
        { root: true }
      )
      router.push({ name: 'Home' })
    } catch (error) {
      commit('handleErrors', error.response.data, { root: true })
    }
  }
}
