export default {
  set(state, users) {
    state.users = users
    // state.count = pages.count
  },
  // update(state, update){
  //   let index = state.pages.findIndex(page => page.id === update.id)
  //   state.pages.splice(index, 1, update)
  // },
  // setFilter(state, filter){
  //   state.filter = filter === 'all' ? null : filter
  // },
  // setOrder(state, order){
  //   state.order = order
  // },
  // setOrderBy(state, order){
  //   state.orderBy = order
  // },
  nextPage(state) {
    state.page++
    state.offset += state.limit
  },
  prevPage(state) {
    state.page--
    state.offset -= state.limit
  },
  setLimit(state, value) {
    state.limit = value
  },
  setPermissions(state, permissions) {
    state.permissions = permissions
  }
}
