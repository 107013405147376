<template>
  <div class="pt-2 pb-6 md:py-6">
    <div class="mx-auto max-w-7xl px-4 sm:px-6 md:px-8">
      <h1 class="mb-6 text-2xl font-semibold text-gray-900">Media</h1>
      <span class="inline-flex rounded-md shadow-sm">
        <button
          type="button"
          class="focus:shadow-outline-gray inline-flex items-center rounded-md border border-transparent bg-gray-600 px-3 py-2 text-sm font-medium leading-4 text-white transition duration-150 ease-in-out hover:bg-gray-500 focus:border-gray-700 focus:outline-none active:bg-gray-700"
          @click="showUploader"
        >
          Upload File
        </button>
      </span>
    </div>
    <div class="mx-auto max-w-7xl px-4 sm:px-6 md:px-8 xl:flex">
      <div class="xl:mr-6 xl:w-1/4">
        <FolderViewer :folders="folders" />
      </div>
      <FileViewer class="xl:w-3/4" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import FolderViewer from '@/adminInterface/components/media/FolderViewer'
import FileViewer from '@/adminInterface/components/media/FileViewer'
export default {
  title: 'Media - iKTA',
  name: 'Media',
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      'id' in to.params
        ? vm.$store.commit('Media/setActiveFolder', +to.params.id)
        : vm.$store.commit('Media/setActiveFolder', null)
      vm.$store.state.activeMediaFolder
        ? vm.$store.dispatch('Media/getFiles')
        : vm.$store.dispatch('Media/getAllFiles')
    })
  },
  beforeRouteUpdate(to, from, next) {
    'id' in to.params
      ? this.$store.commit('Media/setActiveFolder', to.params.id)
      : this.$store.commit('Media/setActiveFolder', null)
    next()
  },
  created() {
    if (!this.$store.state.Media.folders.length)
      this.$store.dispatch('Media/getFolders')
  },
  components: {
    FolderViewer,
    FileViewer
  },
  computed: {
    ...mapGetters({
      activeFolder: 'Media/getActiveFolder',
      folders: 'Media/getFolders',
      files: 'Media/getFiles'
    })
  },
  watch: {
    activeFolder(id) {
      id
        ? this.$store.dispatch('Media/getFiles')
        : this.$store.dispatch('Media/getAllFiles')
    }
  },
  methods: {
    showUploader() {
      this.$store.commit('Media/toggleFileUploader')
    }
  }
}
</script>
