<template>
  <li
    class="relative mb-16 rounded-lg bg-gray-100 p-4 text-gray-500"
    v-if="post"
  >
    <div class="top-0 left-0 flex items-center py-4 md:absolute lg:px-4">
      <div class="h-4 w-4">
        <svg
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M14.417 15.246L22.4959 23.3271L22.4963 23.3274C22.6066 23.4379 22.756 23.4999 22.9118 23.5C23.0675 23.5001 23.217 23.4382 23.3274 23.3277C23.4378 23.2173 23.4999 23.0673 23.5 22.9107C23.5001 22.7542 23.438 22.6041 23.3277 22.4936L15.2531 14.4092C15.2465 14.4049 15.2398 14.4008 15.2331 14.3968L14.6708 14.0708L15.1301 13.611L18.611 10.1254C18.611 10.1253 18.611 10.1253 18.6111 10.1253C18.7213 10.0148 18.7833 9.86494 18.7833 9.7085C18.7833 9.55225 18.7215 9.40253 18.6115 9.29212C18.6113 9.29196 18.6112 9.2918 18.611 9.29164L18.3833 9.06465C17.7311 8.44542 16.8885 8.06614 15.9932 7.98879C15.0961 7.91128 14.1992 8.14152 13.4499 8.64182L13.1085 8.86976L12.8185 8.57931L9.19606 4.95203L8.8807 4.63624L9.15877 4.28717C9.49585 3.86404 9.66515 3.33109 9.63407 2.79052C9.60299 2.24995 9.37373 1.74002 8.99042 1.35847L8.98938 1.35742L8.76099 1.12872C8.33608 0.725022 7.77275 0.5 7.18713 0.5C6.60149 0.5 6.03814 0.72504 5.61322 1.12877L1.15108 5.59583L0.797331 5.24247L1.15097 5.59593C0.944721 5.80229 0.781048 6.04736 0.66934 6.31714C0.557632 6.58693 0.500084 6.87613 0.500002 7.16822C0.499918 7.46032 0.557302 7.74956 0.668859 8.0194C0.780405 8.28923 0.943922 8.53436 1.15004 8.74083C1.15005 8.74085 1.15007 8.74087 1.15009 8.74088L1.38283 8.97394L1.38376 8.97487C1.76489 9.35855 2.27393 9.58773 2.81322 9.61865C3.3525 9.64958 3.8843 9.4801 4.30664 9.14254L4.6563 8.86308L4.9726 9.17981L8.59066 12.8027L8.88743 13.0999L8.64595 13.4435C8.12544 14.1842 7.88301 15.0853 7.96157 15.9877C8.04014 16.8902 8.43463 17.7356 9.07522 18.3748L9.07747 18.3771L9.30649 18.6086C9.30673 18.6089 9.30696 18.6091 9.3072 18.6093C9.41734 18.7193 9.56636 18.781 9.72162 18.781L14.417 15.246ZM14.417 15.246C14.4127 15.2394 14.4086 15.2327 14.4046 15.2259L14.0785 14.6618L13.618 15.1229L10.137 18.6086L10.137 18.6086C10.0267 18.719 9.87743 18.781 9.72183 18.781L14.417 15.246Z"
          />
        </svg>
      </div>
      <span class="ml-2 text-xs font-semibold">Pinned</span>
    </div>

    <article class="md:flex md:space-x-4">
      <div
        class="mb-4 items-center justify-between sm:flex md:mb-0 md:block md:w-1/4 md:text-right"
      >
        <div>
          <router-link :to="{ path: `/posts/${post.slug}` }">
            <h2
              class="text-2xl font-bold text-kta-blue-900 sm:mt-6 xl:mt-0 xl:ml-16"
            >
              {{ post.title }}
            </h2>
          </router-link>
          <div class="font-semibold text-kta-blue-500">
            {{ post.published }}
          </div>
        </div>
        <span
          class="rounded-sm bg-red-600 px-2 py-1 text-xs font-medium uppercase text-white"
          v-show="post.important"
          >Important</span
        >
      </div>
      <div class="flex flex-1">
        <div>
          <BodyText
            class="text-gray-600"
            :content="post.summary ? post.summary : post.body"
          />
          <router-link
            v-if="post.summary"
            :to="{ path: `/posts/${post.slug}` }"
            class="my-4 inline-block text-sm font-semibold tracking-wide text-kta-blue-500 underline"
          >
            Read More
          </router-link>
          <div class="mt-2 flex flex-wrap items-center">
            <router-link
              :to="{ path: `/tag/${$_.kebabCase(tag.name)}` }"
              v-for="(tag, index) in post.tags"
              :key="index"
              class="mr-2 mb-2 rounded-full bg-kta-blue-900 px-2 py-1 text-xs uppercase tracking-wider text-white focus:ring-2 focus:ring-kta-blue-500 focus-visible:outline-none md:px-4 md:py-1 md:font-bold"
              >{{ tag.name }}</router-link
            >
          </div>
        </div>
      </div>
    </article>
  </li>
</template>
<script>
export default {
  name: 'PinnedPost',
  created() {
    this.$store.dispatch('Posts/getPinned')
  },
  computed: {
    post() {
      if (this.$store.getters['Posts/pinned']) {
        const post = this.$_.cloneDeep(this.$store.getters['Posts/pinned'])
        post.published = this.$date.format(new Date(post.published), 'PP')
        return post
      }
      return null
    }
  }
}
</script>
