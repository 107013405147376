<template>
  <main
    class="relative z-10 mx-auto w-full max-w-7xl flex-1 py-12 px-4 sm:px-6 lg:py-16 lg:px-8"
  >
    <span
      class="font-bold uppercase tracking-wide text-red-600"
      v-if="opportunity?.isPrivate"
      >internal only</span
    >
    <h1 class="mb-8 text-4xl font-bold text-kta-blue-800">
      {{ opportunity?.title }}
    </h1>
    <div class="content">
      <h1>Summary</h1>
      <p v-html="opportunity.summary" v-if="opportunity?.summary"></p>
    </div>
    <ul class="relative mb-8 space-y-2 rounded-md bg-gray-200 p-4">
      <li>
        <strong>Job ID:</strong>
        {{ jobIds }}
      </li>
      <li><strong>Department:</strong> {{ opportunity?.department }}</li>
      <li>
        <strong>Supervisor Title:</strong>
        {{ opportunity?.Attributes[3].value }}
      </li>
      <li><strong>Location:</strong> {{ jobLocations }}</li>
      <li>
        <strong>Exempt Status:</strong>
        {{ opportunity?.wageType }}
      </li>
      <li>
        <strong>Pay Grade:</strong> {{ opportunity?.Attributes[4].value }}
      </li>
      <li>
        <strong>Direct Reports:</strong>
        {{ opportunity?.Attributes[6].value }}
      </li>
      <li>
        <strong>Other Inputs:</strong>
        {{ opportunity?.Attributes[5].value }}
      </li>
      <li>
        <strong>Posted: </strong>
        <span v-if="opportunity">{{ opportunity?.posted }}</span>
      </li>
      <!-- <li><strong>Contact Person:</strong> {{ opportunity?.contact }}</li> -->
      <li>
        <strong>Application Deadline:</strong> Submit Request for Transfer and
        Resume by
        <span v-if="opportunity?.expires">{{ opportunity?.expires }}</span>
      </li>
      <button
        class="absolute top-0 right-4 pt-2"
        @click="downloadPdf(opportunity)"
        v-if="opportunity?.pdf"
        title="download"
      >
        <svg
          class="h-5"
          viewBox="0 0 57 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect width="57" height="16" rx="8" fill="#D0021B" />
          <path
            d="M12.5339 5C13.1539 5 13.6906 5.10333 14.1439 5.31C14.6039 5.51667 14.9572 5.81 15.2039 6.19C15.4506 6.57 15.5739 7.02 15.5739 7.54C15.5739 8.05333 15.4506 8.50333 15.2039 8.89C14.9572 9.27 14.6039 9.56333 14.1439 9.77C13.6906 9.97 13.1539 10.07 12.5339 10.07H11.1239V12H9.50391V5H12.5339ZM12.4439 8.75C12.9306 8.75 13.3006 8.64667 13.5539 8.44C13.8072 8.22667 13.9339 7.92667 13.9339 7.54C13.9339 7.14667 13.8072 6.84667 13.5539 6.64C13.3006 6.42667 12.9306 6.32 12.4439 6.32H11.1239V8.75H12.4439Z"
            fill="white"
          />
          <path
            d="M16.9305 5H20.1105C20.8705 5 21.5405 5.14667 22.1205 5.44C22.7071 5.72667 23.1605 6.13333 23.4805 6.66C23.8071 7.18667 23.9705 7.8 23.9705 8.5C23.9705 9.2 23.8071 9.81333 23.4805 10.34C23.1605 10.8667 22.7071 11.2767 22.1205 11.57C21.5405 11.8567 20.8705 12 20.1105 12H16.9305V5ZM20.0305 10.67C20.7305 10.67 21.2871 10.4767 21.7005 10.09C22.1205 9.69667 22.3305 9.16667 22.3305 8.5C22.3305 7.83333 22.1205 7.30667 21.7005 6.92C21.2871 6.52667 20.7305 6.33 20.0305 6.33H18.5505V10.67H20.0305Z"
            fill="white"
          />
          <path
            d="M27.0122 6.3V8.15H30.2522V9.45H27.0122V12H25.3922V5H30.6822V6.3H27.0122Z"
            fill="white"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M42.5222 2H40.5021C40.1663 2 39.8961 2.22296 39.8961 2.5001V6.00078H37.679C37.2296 6.00078 37.0048 6.44879 37.323 6.71134L41.1687 9.88279C41.3581 10.0391 41.6687 10.0391 41.8581 9.88279L45.6987 6.71134C46.0169 6.44879 45.7921 6.00078 45.3427 6.00078H43.1282V2.5001C43.1282 2.22296 42.858 2 42.5222 2ZM48.3261 9.6C48.3261 9.2675 48.0412 9 47.6872 9H43.7817L42.4772 10.225C41.9421 10.7275 41.0796 10.7275 40.5445 10.225L39.24 9H35.3346C34.9805 9 34.6956 9.2675 34.6956 9.6V12.4C34.6956 12.7325 34.9805 13 35.3346 13H47.6872C48.0412 13 48.3261 12.7325 48.3261 12.4V9.6ZM44.6087 11.5C44.6087 11.225 44.3299 11 43.9891 11C43.6484 11 43.3696 11.225 43.3696 11.5C43.3696 11.775 43.6484 12 43.9891 12C44.3299 12 44.6087 11.775 44.6087 11.5ZM46.4674 11C46.8081 11 47.0869 11.225 47.0869 11.5C47.0869 11.775 46.8081 12 46.4674 12C46.1266 12 45.8478 11.775 45.8478 11.5C45.8478 11.225 46.1266 11 46.4674 11Z"
            fill="white"
          />
        </svg>
      </button>
    </ul>
    <div v-html="opportunity?.description" class="content"></div>
    <Select
      v-if="opportunity?.locations"
      v-model="location"
      :options="opportunity.locations"
      label="Location"
      optionsDisplay="location"
    />
    <a
      v-if="applyLink"
      :href="applyLink"
      class="my-6 block rounded-md bg-kta-blue-500 py-3 px-6 text-center font-semibold tracking-wide text-white"
      >Apply Now</a
    >
  </main>
</template>
<script>
export default {
  name: 'Opportunity',
  async created() {
    if (!this.opportunities.length)
      await this.$store.dispatch('Opportunities/getAll')
    if (!this.opportunity) {
      this.$router.replace({ path: '/opportunities' })
    }
  },
  data: () => ({
    location: null
  }),
  computed: {
    opportunities() {
      return this.$store.getters['Opportunities/getAll']
    },
    opportunity() {
      return this.opportunities.find(
        (job) =>
          job.title.toLowerCase().replace(/[\W_]/g, '-') ===
          this.$route.params.opportunity
      )
    },
    applyLink() {
      return this.opportunity?.applyLink
        ? this.opportunity.applyLink
        : this.location?.applyLink
    },
    jobIds() {
      return this.opportunity?.locations
        ? this.opportunity?.locations.reduce(
            (jobIds, job, index) =>
              index === 0 ? job.jobId : `${jobIds}, ${job.jobId}`,
            ''
          )
        : this.opportunity?.jobId
    },
    jobLocations() {
      return this.opportunity?.locations
        ? this.opportunity?.locations.reduce(
            (locations, job, index) =>
              index === 0 ? job.location : `${locations}, ${job.location}`,
            ''
          )
        : this.opportunity?.locationName
    }
  },
  methods: {
    async downloadPdf(opportunity) {
      const pdf = await this.$store.dispatch(
        'Opportunities/getPdf',
        opportunity.jobId
      )
      let fileLink = document.createElement('a')
      fileLink.href = pdf
      fileLink.setAttribute('download', opportunity.pdf)
      document.body.appendChild(fileLink)

      fileLink.click()
    }
  }
}
</script>
<style>
.post-body > ul {
  list-style: initial;
  margin-left: 1rem;
  margin-bottom: 1rem;
}
</style>
