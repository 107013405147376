import Home from '@/clientInterface/views/Home.vue'
import Directory from '@/clientInterface/views/Directory.vue'
import Login from '@/clientInterface/views/Login.vue'
import PasswordReset from '@/clientInterface/views/PasswordReset.vue'
import Registration from '@/clientInterface/views/Registration.vue'
import Post from '@/clientInterface/views/Post.vue'
import Archive from '@/clientInterface/views/Archive.vue'
import Tag from '@/clientInterface/views/Tag.vue'
import Page from '@/clientInterface/views/Page.vue'
import Policies from '@/clientInterface/views/Policies.vue'
import Positions from '@/clientInterface/views/Positions.vue'
import Opportunities from '@/clientInterface/views/opportunities/Index.vue'
import Opportunity from '@/clientInterface/views/opportunities/Show.vue'
import User from '@/clientInterface/views/User.vue'

const clientRoutes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: { auth: true }
  },
  {
    path: '/employee-directory',
    name: 'Directory',
    component: Directory,
    meta: { auth: true }
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: { auth: false }
  },
  {
    path: '/password-reset',
    name: 'PasswordReset',
    component: PasswordReset,
    meta: { auth: false }
  },
  {
    path: '/register',
    name: 'Registration',
    component: Registration,
    meta: { auth: false }
  },
  {
    path: '/policies',
    redirect: {
      name: 'Policies',
      params: { policy: 'introduction-to-kansas-turnpike' },
      meta: { auth: true }
    }
  },
  {
    path: '/policies/:policy',
    name: 'Policies',
    component: Policies,
    meta: { auth: true }
  },
  {
    path: '/posts',
    redirect: { name: 'Home' },
    meta: { auth: true }
  },
  {
    path: '/posts/archive/:year/:month',
    name: 'Archive',
    component: Archive,
    meta: { auth: true }
  },

  {
    path: '/tag/:tag',
    name: 'Tag',
    component: Tag,
    meta: { auth: true }
  },
  {
    path: '/posts/:slug',
    name: 'Post',
    component: Post,
    meta: { auth: true }
  },
  {
    path: '/opportunities',
    name: 'Opportunities',
    component: Opportunities,
    meta: { auth: true }
  },
  {
    path: '/opportunities/:opportunity',
    name: 'Opportunity',
    component: Opportunity,
    meta: { auth: true }
  },
  {
    path: '/employee-positions',
    name: 'Positions',
    component: Positions,
    meta: { auth: true }
  },
  {
    path: '/profile',
    name: 'User',
    component: User,
    meta: { auth: true }
  },
  {
    path: '/:slug',
    name: 'Page',
    component: Page,
    meta: { auth: true }
  }
]
export { clientRoutes }
