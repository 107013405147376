import actions from './actions'
import mutations from './mutations'
import getters from './getters'

export default {
  namespaced: true,
  state: () => ({
    tags: [],
    tag: {
      name: '',
      posts: [],
      count: 0,
      offset: 0,
      limit: 6,
      page: null
    }
  }),
  mutations,
  getters,
  actions
}
