<template>
  <div class="pt-2 pb-6 md:py-6">
    <div class="mx-auto mb-6 max-w-7xl px-4 sm:px-6 md:px-8">
      <h1 class="text-2xl font-semibold text-gray-900">Pages</h1>
    </div>
    <DataControls view="AdminPages" />
    <div class="mx-auto max-w-7xl px-4 sm:px-6 md:px-8">
      <div class="mb-4 flex items-center justify-between">
        <Paginator
          :from="from"
          :to="to"
          :total="totalPages"
          position="top"
          @next="next"
          @prev="prev"
          hideButtons
          v-show="totalPages"
        />
        <span class="inline-flex rounded-md shadow-sm">
          <router-link
            :to="{ name: 'PagesCreate' }"
            tag="button"
            class="focus:shadow-outline-gray inline-flex items-center rounded-md border border-transparent bg-gray-600 px-4 py-2 text-sm font-medium leading-5 text-white transition duration-150 ease-in-out hover:bg-gray-500 focus:border-gray-700 focus:outline-none active:bg-gray-700"
          >
            Create
          </router-link>
        </span>
      </div>
      <div class="overflow-hidden bg-white shadow sm:rounded-md">
        <div class="p-4 italic text-gray-500" v-if="formatted.length < 1">
          No pages found.
        </div>
        <ul>
          <li
            v-for="(page, index) in formatted"
            :key="index"
            :class="[index > 0 ? 'border-t border-gray-200' : '']"
          >
            <router-link
              :to="{ name: 'PagesEdit', params: { id: page.id, page } }"
              class="block transition duration-150 ease-in-out hover:bg-gray-50 focus:bg-gray-50 focus:outline-none"
            >
              <div class="px-4 py-4 sm:px-6">
                <div
                  class="block flex-wrap items-center justify-between sm:flex"
                >
                  <div class="text-sm leading-5">
                    <span
                      class="mr-2 text-xs font-light uppercase text-gray-400"
                      >Title</span
                    >
                    <span class="text-grey-700 truncate font-medium"
                      >{{ page.title }}
                      <span v-if="page.subtitle"
                        >| {{ page.subtitle }}</span
                      ></span
                    >
                  </div>
                  <div
                    class="mt-2 flex items-center text-sm leading-5 text-gray-500 sm:mt-0"
                  >
                    <span
                      class="mr-2 text-xs font-light uppercase text-gray-400"
                      >Slug</span
                    >
                    <span>{{ page.slug }}/</span>
                  </div>
                </div>
                <div class="mt-2 sm:flex sm:justify-between">
                  <div
                    class="mt-2 flex items-center text-sm leading-5 text-gray-500 sm:mt-0"
                  >
                    <span
                      class="mr-2 text-xs font-light uppercase text-gray-400"
                      >Published</span
                    >
                    <span v-if="page.published"
                      ><time :datetime="page.published">{{
                        page.published
                      }}</time></span
                    >
                    <span v-else>Unpublished</span>
                  </div>
                  <div
                    class="mt-2 flex items-center text-sm leading-5 text-gray-500 sm:mt-0"
                  >
                    <span
                      class="mr-2 text-xs font-light uppercase text-gray-400"
                      >Created</span
                    >
                    <span
                      ><time :datetime="page.created">{{
                        page.created
                      }}</time></span
                    >
                  </div>
                </div>
              </div>
            </router-link>
          </li>
        </ul>
      </div>
      <Paginator
        class="border-b border-gray-200"
        :from="from"
        :to="to"
        :total="totalPages"
        @next="next"
        @prev="prev"
        v-show="totalPages"
      />
    </div>
    <div
      class="mx-auto my-4 flex max-w-7xl justify-between px-4 sm:px-6 md:px-8"
    >
      <div class="flex items-center">
        <label
          for="items"
          class="mr-2 block text-sm font-medium leading-5 text-gray-700 sm:mt-px"
        >
          items
        </label>
        <div class="mt-1 sm:col-span-2 sm:mt-0">
          <div class="max-w-lg rounded-md shadow-sm sm:max-w-xs">
            <select
              id="items"
              class="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
            >
              <option>10</option>
              <option>20</option>
              <option>50</option>
            </select>
          </div>
        </div>
      </div>
      <span class="inline-flex rounded-md shadow-sm">
        <router-link
          :to="{ name: 'PagesCreate' }"
          tag="button"
          class="focus:shadow-outline-gray inline-flex items-center rounded-md border border-transparent bg-gray-600 px-4 py-2 text-sm font-medium leading-5 text-white transition duration-150 ease-in-out hover:bg-gray-500 focus:border-gray-700 focus:outline-none active:bg-gray-700"
        >
          Create
        </router-link>
      </span>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import DataControls from '@/adminInterface/components/utilities/DataControls'
import Paginator from '@/adminInterface/components/utilities/Paginator'
export default {
  title: 'Pages - iKTA',
  name: 'PageIndex',
  components: { DataControls, Paginator },
  created() {
    this.$store.dispatch('AdminPages/getAll')
  },
  methods: {
    next() {
      if (this.to < this.totalPages) {
        this.$store.commit('AdminPages/nextPage')
        this.$store.dispatch('AdminPages/getAll')
      }
    },
    prev() {
      if (this.offset > 0) {
        this.$store.commit('AdminPages/prevPage')
        this.$store.dispatch('AdminPages/getAll')
      }
    }
  },
  computed: {
    ...mapGetters({
      page: 'AdminPages/page',
      offset: 'AdminPages/offset',
      errors: 'getErrors',
      totalPages: 'AdminPages/count',
      to: 'AdminPages/to',
      from: 'AdminPages/from'
    }),
    pages() {
      return this.$_.cloneDeep(this.$store.getters['AdminPages/get'])
    },
    itemsPerPage: {
      get() {
        return this.$store.getters['AdminPages/limit']
      },
      set(value) {
        this.$store.commit('AdminPages/setLimit', value)
      }
    },
    formatted() {
      return this.pages.map((page) => {
        page.created = this.$date.format(
          new Date(page.created),
          'MM/dd/yyyy h:mm a'
        )
        if (page.published)
          page.published = this.$date.format(
            new Date(page.published),
            'MM/dd/yyyy  h:mm a'
          )
        return page
      })
    }
  },
  watch: {
    itemsPerPage(value) {
      this.$store.commit('AdminPages/setLimit', value)
      this.$store.dispatch('AdminPages/getAll')
    }
  }
}
</script>
