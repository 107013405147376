<template>
  <div class="pt-2 pb-6 md:py-6">
    <div class="mx-auto mb-6 max-w-7xl px-4 sm:px-6 md:px-8">
      <h1 class="text-2xl font-semibold text-gray-900">{{ pageTitle }}</h1>
    </div>
    <div class="mx-auto max-w-7xl px-4 sm:px-6 md:px-8">
      <form @submit.prevent>
        <div>
          <Input
            class=""
            v-model="form.title"
            label="Title"
            :error="errors.title"
          />
          <div class="block lg:flex lg:space-x-3">
            <Select
              class="lg:w-2/3"
              v-model="form.department"
              :options="departments"
              label="Department"
            />
            <div class="flex w-full space-x-3 lg:w-1/3">
              <Input
                class="flex-1"
                v-model.number="form.payGrade"
                label="Pay Grade"
                :error="errors.payGrade"
              />
              <div class="">
                <label class="mb-1 block text-sm font-medium leading-5"
                  >Exempt Status</label
                >
                <button
                  type="button"
                  @click="form.exempt = !form.exempt"
                  class="relative mt-2 flex h-8 w-36 cursor-pointer items-center rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  :class="[form.exempt ? 'bg-indigo-600' : 'bg-gray-200']"
                  aria-pressed="false"
                >
                  <span
                    aria-hidden="true"
                    class="pointer-events-none relative z-10 inline-block h-7 w-7 translate-x-0 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                    :class="{ 'translate-x-28': form.exempt }"
                  ></span>
                  <span
                    class="relative transform px-2 text-sm uppercase transition duration-200 ease-in-out"
                    :class="{ 'text-white': form.exempt }"
                    v-text="form.exempt ? 'Exempt' : 'Non-Exempt'"
                  ></span>
                </button>
              </div>
            </div>
          </div>
          <div class="block lg:flex lg:space-x-3">
            <Input
              class="lg:w-1/3"
              v-model="form.supervisorTitle"
              label="Supervisor Title"
              :error="errors.supervisorTitle"
            />
            <Input
              class="lg:w-1/3"
              v-model="form.otherInputs"
              label="Other Inputs"
              :error="errors.otherInputs"
            />
            <Input
              class="lg:w-1/3"
              v-model="form.directReports"
              label="Direct Reports"
              :error="errors.directReports"
            />
          </div>
          <div class="mt-6">
            <label
              class="mb-1 block text-sm font-medium leading-5 text-gray-700"
              >Summary</label
            >
            <Wysiwyg
              aria-label="summary"
              v-model="form.summary"
              :error="errors.summary"
              :height="3"
            />
          </div>
          <div class="mt-6">
            <label
              class="mb-1 block text-sm font-medium leading-5 text-gray-700"
            >
              Content
            </label>
            <Wysiwyg
              aria-label="content"
              v-model="form.content"
              :error="errors.content"
              :height="10"
            />
          </div>
          <div class="mb-6 mt-6">
            <label
              class="mb-1 block text-sm font-medium leading-5 text-gray-700"
              >Position PDF</label
            >
            <input
              type="file"
              @change="onFileUpload"
              accept="application/pdf"
              aria-label="file"
              v-show="!form.pdf"
            />
            <div v-if="form.pdf">
              <button
                @click="getPdf"
                class="mr-4 text-kta-blue-600 underline hover:text-kta-blue-700"
              >
                {{ form.pdf }}
              </button>
              <button
                @click="removePdf"
                class="cursor-pointer text-sm text-red-600 underline hover:text-red-700"
              >
                Remove
              </button>
            </div>
          </div>
        </div>
        <div class="mt-8 border-t border-gray-200 pt-5">
          <div class="flex justify-between">
            <div class="flex items-center space-x-3" v-if="editing">
              <span class="inline-flex rounded-md shadow-sm">
                <button
                  type="submit"
                  class="focus:shadow-outline-red inline-flex justify-center rounded-md border border-transparent bg-red-600 py-2 px-4 text-sm font-medium leading-5 text-white transition duration-150 ease-in-out hover:bg-red-500 focus:border-red-700 focus:outline-none active:bg-red-700"
                  @click="remove"
                >
                  Delete
                </button>
              </span>
              <span class="space-x-3 text-sm italic">
                <span class="font-light text-gray-700" v-show="lastEdit"
                  >Last edited on {{ lastEdit }}</span
                >
                <span class="font-light text-gray-700" v-show="!lastEdit"
                  >This is the original version</span
                >
                <router-link
                  class="text-blue-700 underline"
                  :to="{ name: 'PositionHistory' }"
                  v-show="lastEdit"
                  >(Version History)</router-link
                >
              </span>
            </div>
            <div class="flex justify-between">
              <span class="inline-flex rounded-md shadow-sm">
                <button
                  type="button"
                  class="focus:shadow-outline-blue rounded-md border border-gray-300 py-2 px-4 text-sm font-medium leading-5 text-gray-700 transition duration-150 ease-in-out hover:text-gray-500 focus:border-blue-300 focus:outline-none active:bg-gray-50 active:text-gray-800"
                  @click="cancel"
                >
                  Cancel
                </button>
              </span>
              <span class="ml-3 inline-flex rounded-md shadow-sm">
                <button
                  type="submit"
                  class="focus:shadow-outline-gray inline-flex justify-center rounded-md border border-transparent bg-gray-600 py-2 px-4 text-sm font-medium leading-5 text-white transition duration-150 ease-in-out hover:bg-gray-500 focus:border-gray-700 focus:outline-none active:bg-gray-700"
                  @click="submit"
                  v-text="editing ? 'Update' : 'Save'"
                ></button>
              </span>
            </div>
          </div>
        </div>
      </form>
    </div>
    <ConfirmDialog :isOpen="confirm" @confirmed="confirmed" />
  </div>
</template>

<script>
import ConfirmDialog from '@/adminInterface/components/utilities/ConfirmDialog'
import Wysiwyg from '@/adminInterface/components/form/Wysiwyg'
export default {
  name: 'CreateEditPage',
  created() {
    if (this.$route.params.id) {
      this.pageTitle = 'Edit Position'
      this.loadData()
    }
  },
  data() {
    return {
      pageTitle: 'Create Position',
      confirm: false,
      form: {
        title: '',
        department: '',
        exempt: false,
        payGrade: null,
        supervisorTitle: '',
        otherInputs: '',
        directReports: '',
        summary: '',
        content: ''
      },
      history: []
    }
  },
  methods: {
    async getPdf() {
      const pdf = await this.$store.dispatch(
        'Positions/getPdf',
        this.$route.params.id
      )
      let fileLink = document.createElement('a')
      fileLink.href = pdf
      fileLink.setAttribute('download', this.form.pdf)
      document.body.appendChild(fileLink)

      fileLink.click()
    },
    removePdf() {
      this.form.pdf = ''
    },
    onFileUpload(event) {
      this.form.file = event.target.files[0]
      this.form.pdf = event.target.files[0].name
      this.$forceUpdate()
    },
    submit() {
      this.editing ? this.update() : this.create()
    },
    create() {
      const formData = new FormData()
      for (const prop in this.form) {
        formData.append(prop, this.form[prop])
      }
      this.$store.dispatch('AdminPositions/create', formData)
    },
    update() {
      const formData = new FormData()
      for (const prop in this.form) {
        formData.append(prop, this.form[prop])
      }
      this.$store.dispatch('AdminPositions/update', formData)
    },
    async confirmed(confirmed) {
      if (confirmed) {
        await this.$store.dispatch(
          'AdminPositions/delete',
          this.$route.params.id
        )
      }
      this.confirm = false
    },
    remove() {
      this.confirm = true
    },
    cancel() {
      this.$router.push({ name: 'PositionsIndex' })
    },
    async loadData() {
      if (this.editing) {
        const position = await this.$store.dispatch(
          'AdminPositions/get',
          this.$route.params.id
        )
        const { history, ...data } = position
        this.history = history
        this.form = data
      }
    }
  },
  computed: {
    positions() {
      return this.$store.getters['AdminPositions/get']
    },
    editing() {
      return !!this.$route.params.id
    },
    errors() {
      return this.$store.getters.getErrors
    },
    departments() {
      return this.$store.getters['AdminPositions/departments']
    },
    editCount() {
      return this.history.length
    },
    lastEdit() {
      if (this.history.length) {
        const lastEdit = this.$_.last(this.history)?.created
        return this.$date.format(new Date(lastEdit), 'MM/dd/yyyy')
      }
      return false
    }
  },
  watch: {
    pages(value) {
      this.loadData(value)
    }
  },
  components: { ConfirmDialog, Wysiwyg }
}
</script>
<style></style>
