/* eslint-disable no-unused-vars */
import { differenceInCalendarDays, format } from 'date-fns'

export default {
  loggedIn(state) {
    return (
      state.accessToken &&
      new Date(JSON.parse(atob(state.accessToken.split('.')[1])).exp * 1000) >
        new Date()
    )
  },
  profileOnly(state) {
    return !state.accessToken
      ? true
      : JSON.parse(atob(state.accessToken.split('.')[1])).status ===
          'terminated'
  },
  refreshable(state) {
    return (
      state.refreshToken &&
      new Date(JSON.parse(atob(state.refreshToken.split('.')[1])).exp * 1000) >
        new Date()
    )
  },
  accessToken(state) {
    return state.accessToken &&
      new Date(JSON.parse(atob(state.accessToken.split('.')[1])).exp * 1000) >
        new Date()
      ? state.accessToken
      : false
  },
  refreshToken(state) {
    return state.refreshToken &&
      new Date(JSON.parse(atob(state.accessToken.split('.')[1])).exp * 1000) >
        new Date()
      ? state.refreshToken
      : false
  },
  role(state) {
    return state.accessToken
      ? JSON.parse(atob(state.accessToken.split('.')[1])).role
      : null
  },
  permissions(state) {
    if (state.accessToken) {
      const permissions = JSON.parse(
        atob(state.accessToken.split('.')[1])
      ).permissions
      if (permissions) {
        const result = Object.entries(permissions).filter(
          ([key, value]) => value.length
        )
        return Object.fromEntries(result)
      } else {
        return null
      }
    }
    return null
  },
  id(state) {
    return state.accessToken
      ? JSON.parse(atob(state.accessToken.split('.')[1])).id
      : null
  },
  profile(state) {
    return state.user
  },
  isAdmin(state) {
    const role = state.accessToken
      ? JSON.parse(atob(state.accessToken.split('.')[1])).role
      : null
    if (role) {
      return ['superadmin', 'admin'].includes(role)
    }
    return false
  },
  config(state) {
    return {
      headers: {
        Authorization: `Bearer ${state.accessToken}`
      }
    }
  },
  paychecks(state) {
    const dates = state.paychecks
      ?.map((item) => format(new Date(item.DOCDAT), 'yyyy-MM-dd'))
      .sort()
      .reverse()
    const sortedDates = {}
    dates.forEach((date) => {
      const [year] = date.split('-')
      sortedDates[year]
        ? sortedDates[year].push(date)
        : (sortedDates[year] = [date])
    })
    return sortedDates
  },
  w2s(state) {
    const w2s = {}
    state.w2s?.forEach((item) => {
      w2s[item.YEAR] = format(new Date(item.DOCDAT), 'yyyy-MM-dd')
    })
    return w2s
  },
  contactOptions(state) {
    return state.contactOptions
  }
}
