<template>
  <nav class="flex items-center justify-between py-3" aria-label="Pagination">
    <div class="hidden sm:block">
      <p class="text-sm text-gray-700">
        Showing <span class="font-medium">{{ from }}</span>
        to
        <span class="font-medium">{{ to }}</span>
        of
        <span class="font-medium">{{ total }}</span>
        results
      </p>
    </div>
    <div class="flex flex-1 justify-between sm:justify-end" v-if="!hideButtons">
      <a
        href="#"
        @click="$emit('prev')"
        class="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
      >
        Previous
      </a>
      <a
        href="#"
        @click="$emit('next')"
        class="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
      >
        Next
      </a>
    </div>
  </nav>
</template>

<script>
export default {
  props: {
    from: {
      type: Number,
      required: true
    },
    to: {
      type: Number,
      required: true
    },
    total: {
      type: Number,
      required: true
    },
    hideButtons: {
      type: Boolean,
      required: false,
      default: false
    }
  }
}
</script>
