<template>
  <div class="pt-2 pb-6 md:py-6">
    <div class="mx-auto mb-6 max-w-7xl px-4 sm:px-6 md:px-8">
      <h1 class="text-2xl font-semibold text-gray-900">{{ pageTitle }}</h1>
    </div>
    <div class="mx-auto max-w-7xl px-4 sm:px-6 md:px-8">
      <form @submit.prevent>
        <div>
          <div class="lg:flex lg:space-x-3">
            <Select
              class="w-1/2"
              v-model="form.sectionId"
              label="Section Name"
              :options="sections"
              optionsValue="id"
              optionsKey="id"
              optionsDisplay="name"
              :error="errors.sectionName"
            />
            <div class="w-1/6 text-sm font-medium">
              <div>Section</div>
              <div
                class="mt-1 rounded-md border border-gray-300 bg-gray-200 px-3 py-2 font-normal text-gray-600 shadow-sm"
              >
                <span v-if="sectionId">{{ sectionId }}</span>
                <span class="block h-5" v-else>&nbsp;</span>
              </div>
            </div>
            <Input
              class="w-1/6"
              v-model="form.article"
              label="Article"
              :error="errors.article"
            />
            <Input
              class="w-1/6"
              v-model="form.paragraph"
              label="Paragraph"
              :error="errors.paragraph"
            />
          </div>
          <div class="block items-baseline lg:flex lg:space-x-3">
            <Input
              class="w-1/2"
              v-model="form.title"
              label="Title"
              :error="errors.title"
            />
            <Input
              class="w-1/4"
              v-model="form.approvedBy"
              label="Approved By"
              :error="errors.approvedBy"
            />
            <DateSelector
              class="w-1/4"
              v-model="form.effective"
              label="Effective Date"
            />
          </div>
          <div class="mt-6">
            <label class="mb-1 block text-sm font-medium leading-5"
              >Content</label
            >
            <Wysiwyg
              aria-label="content"
              v-model="form.content"
              :error="errors.content"
              :height="10"
            />
          </div>
        </div>
        <div class="mt-8 border-t border-gray-200 pt-5">
          <div class="flex justify-between">
            <div class="flex items-center space-x-3">
              <span class="inline-flex rounded-md shadow-sm" v-if="editing">
                <button
                  type="submit"
                  class="focus:shadow-outline-red inline-flex justify-center rounded-md border border-transparent bg-red-600 py-2 px-4 text-sm font-medium leading-5 text-white transition duration-150 ease-in-out hover:bg-red-500 focus:border-red-700 focus:outline-none active:bg-red-700"
                  @click="remove"
                >
                  Delete
                </button>
              </span>
              <span class="space-x-3 text-sm italic">
                <span class="font-light text-gray-700" v-show="lastEdit"
                  >Last edited on {{ lastEdit }}</span
                >
                <span class="font-light text-gray-700" v-show="!lastEdit"
                  >This is the original version</span
                >
                <router-link
                  class="text-blue-700 underline"
                  :to="{ name: 'PoliciesHistory' }"
                  >(Version History)</router-link
                >
              </span>
            </div>
            <div class="flex justify-between">
              <span class="inline-flex rounded-md shadow-sm">
                <button
                  type="button"
                  class="focus:shadow-outline-blue rounded-md border border-gray-300 py-2 px-4 text-sm font-medium leading-5 text-gray-700 transition duration-150 ease-in-out hover:text-gray-500 focus:border-blue-300 focus:outline-none active:bg-gray-50 active:text-gray-800"
                  @click="cancel"
                >
                  Cancel
                </button>
              </span>
              <span class="ml-3 inline-flex rounded-md shadow-sm">
                <button
                  type="submit"
                  class="focus:shadow-outline-gray inline-flex justify-center rounded-md border border-transparent bg-gray-600 py-2 px-4 text-sm font-medium leading-5 text-white transition duration-150 ease-in-out hover:bg-gray-500 focus:border-gray-700 focus:outline-none active:bg-gray-700"
                  @click="submit"
                  v-text="editing ? 'Update' : 'Save'"
                ></button>
              </span>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import Wysiwyg from '@/adminInterface/components/form/Wysiwyg'
export default {
  name: 'CreateEditPage',
  created() {
    if (!this.sections.length) this.$store.dispatch('AdminPolicies/getSections')
    if (this.$route.params.id) {
      this.pageTitle = 'Edit Policy'
      this.loadData()
    }
  },
  data() {
    return {
      pageTitle: 'Create Policy',
      form: {
        article: null,
        paragraph: null,
        title: '',
        approvedBy: 'Steve Hewitt',
        effective: '',
        content: '',
        sectionId: null
      },
      original: null
    }
  },
  methods: {
    submit() {
      this.editing ? this.update() : this.create()
    },
    async create() {
      // eslint-disable-next-line no-unused-vars
      const { section, ...form } = this.form
      const response = await this.$store.dispatch('AdminPolicies/create', form)
      if (response && response.status === 201) {
        await this.$store.dispatch('AdminPolicies/getAll')
        this.$emit('message', { title: 'Policy Created' })
        this.$router.push({ name: 'PoliciesIndex' })
      }
    },
    async update() {
      // eslint-disable-next-line no-unused-vars
      const { section, ...form } = this.form
      const updated = {}

      for (const prop in form) {
        if (prop !== section && form[prop] !== this.original[prop])
          updated[prop] = form[prop]
      }

      const response = await this.$store.dispatch('AdminPolicies/update', {
        id: this.form.id,
        data: updated
      })
      if (response && response.status === 200) {
        await this.$store.dispatch('AdminPolicies/getAll')
        this.$emit('message', { title: 'Policy Updated' })
        this.$router.push({ name: 'PoliciesIndex' })
      }
    },
    async remove() {
      // Update when adding policy manager
      // const selected = await this.$swal({
      //   position: 'center',
      //   icon: 'error',
      //   title: 'Delete Policy',
      //   text: 'Are you sure you want to delete?',
      //   confirmButtonColor: '#f27474',
      //   confirmButtonText: 'Delete',
      //   showConfirmButton: true,
      //   showCancelButton: true
      // })
      // if (selected.isConfirmed) {
      //   const response = await this.$store.dispatch(
      //     'AdminPolicies/delete',
      //     this.$route.params.id
      //   )
      //   this.$store.dispatch('AdminPolicies/getAll')
      //   if (response && response.status === 204) {
      //     this.$emit('message', { title: 'Policy Deleted' })
      //     this.$router.push({ name: 'PoliciesIndex', icon: 'error' })
      //   }
      // }
    },
    cancel() {
      this.$router.push({ name: 'PoliciesIndex' })
    },
    async loadData() {
      if (this.editing) {
        this.original = await this.$store.dispatch(
          'AdminPolicies/get',
          this.$route.params.id
        )
        this.form = this.$_.cloneDeep(this.original)
      }
    }
  },
  computed: {
    policies() {
      return this.$store.getters['AdminPolicies/get']
    },
    sections() {
      return this.$store.getters['AdminPolicies/sections']
    },
    editing() {
      return !!this.$route.params.id
    },
    errors() {
      return this.$store.getters.getErrors
    },
    sectionId() {
      return this.sections.length
        ? this.sections.find((section) => section.id === this.form.sectionId)
            ?.sectionId
        : ''
    },
    editCount() {
      return this.original.history.length
    },
    lastEdit() {
      if (this.original && this.original.history.length) {
        const lastEdit = this.$_.last(this.original.history)?.created
        return this.$date.format(new Date(lastEdit), 'MM/dd/yyyy')
      }
      return false
    }
  },
  watch: {
    form: {
      deep: true,
      handler(value) {
        if (this.errors) {
          for (const prop in this.errors) {
            if (
              value[prop] ||
              (typeof value[prop] == 'object' && value[prop].length > 0)
            ) {
              this.$store.commit('removeError', prop)
            }
          }
          if (value?.sectionId) this.$store.commit('removeError', 'sectionId')
        }
      }
    }
  },
  components: { Wysiwyg }
}
</script>
<style></style>
