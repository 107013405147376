<template>
  <div class="pt-2 pb-6 md:py-6">
    <div class="mx-auto mb-6 max-w-7xl px-4 sm:px-6 md:px-8">
      <h1 class="text-2xl font-semibold text-gray-900">{{ pageTitle }}</h1>
    </div>
    <div class="mx-auto max-w-7xl px-4 sm:px-6 md:px-8">
      <Input v-model="accordion.name" label="Name" :error="errors.name" />
      <button
        @click="addSection"
        type="button"
        class="inline-flex items-center rounded border border-transparent bg-kta-blue-600 px-2.5 py-1.5 text-xs font-medium text-white shadow-sm hover:bg-kta-blue-700 focus:outline-none focus:ring-2 focus:ring-kta-blue-500 focus:ring-offset-2"
      >
        Add Section
      </button>
      <div v-if="accordion.content.length" class="mt-6">
        <draggable
          handle=".handle"
          tag="div"
          :list="accordion.content"
          item-key="index"
        >
          <template #item="{ element, index }">
            <div class="mb-2 rounded-xl bg-gray-200 px-4 pt-5">
              <div class="flex items-start">
                <MenuIcon class="handle mt-3 mr-1.5 h-5 w-5 text-gray-500" />
                <Input
                  class="flex-1"
                  v-model="element.title"
                  :error="errors.title"
                />
                <button
                  @click="toggleActive(index)"
                  type="button"
                  class="mt-1 ml-1 inline-flex items-center rounded border border-transparent bg-gray-400 px-1.5 py-1.5 text-xs font-medium text-white shadow-sm hover:bg-gray-500 focus:outline-none"
                >
                  <ChevronUpIcon
                    :class="[
                      activeIndex === index ? 'rotate-180 transform' : '',
                      'h-6 w-6'
                    ]"
                  />
                </button>
                <button
                  @click="removeSection(index)"
                  type="button"
                  class="mt-1 ml-1 inline-flex items-center rounded border border-transparent bg-red-200 px-2 py-2 text-xs font-medium text-red-800 shadow-sm hover:bg-red-300 focus:outline-none"
                >
                  <TrashIcon class="h-5 w-5" />
                </button>
              </div>
              <div class="pb-5" v-show="activeIndex === index">
                <Wysiwyg v-model="element.body" :error="errors.body" />
              </div>
            </div>
          </template>
        </draggable>
      </div>
      <div class="mt-8 border-t border-gray-200 pt-5">
        <div class="flex justify-between">
          <span class="inline-flex rounded-md shadow-sm" v-if="editing">
            <button
              type="submit"
              class="focus:shadow-outline-red inline-flex justify-center rounded-md border border-transparent bg-red-600 py-2 px-4 text-sm font-medium leading-5 text-white transition duration-150 ease-in-out hover:bg-red-500 focus:border-red-700 focus:outline-none active:bg-red-700"
              @click="remove"
            >
              Delete
            </button>
          </span>
          <div class="flex justify-between">
            <span class="inline-flex rounded-md shadow-sm">
              <button
                type="button"
                class="focus:shadow-outline-blue rounded-md border border-gray-300 py-2 px-4 text-sm font-medium leading-5 text-gray-700 transition duration-150 ease-in-out hover:text-gray-500 focus:border-blue-300 focus:outline-none active:bg-gray-50 active:text-gray-800"
                @click="cancel"
              >
                Cancel
              </button>
            </span>
            <span class="ml-3 inline-flex rounded-md shadow-sm">
              <button
                type="submit"
                class="focus:shadow-outline-gray inline-flex justify-center rounded-md border border-transparent bg-gray-600 py-2 px-4 text-sm font-medium leading-5 text-white transition duration-150 ease-in-out hover:bg-gray-500 focus:border-gray-700 focus:outline-none active:bg-gray-700"
                @click="submit"
                v-text="editing ? 'Update' : 'Save'"
              ></button>
            </span>
          </div>
        </div>
      </div>
      <div class="mt-6" v-if="accordion.content.length">
        <div class="mb-4 font-bold">Preview</div>
        <CSSAccordion
          id="accordion"
          :items="accordion.content"
          labelProperty="title"
          contentProperty="body"
        />
      </div>
    </div>
    <ConfirmDialog :isOpen="confirm" @confirmed="confirmed" />
  </div>
</template>

<script>
import CSSAccordion from '@/clientInterface/components/utilities/CSSAccordion'
import { ChevronUpIcon, TrashIcon, MenuIcon } from '@heroicons/vue/solid'
import ConfirmDialog from '@/adminInterface/components/utilities/ConfirmDialog'
import Wysiwyg from '@/adminInterface/components/form/Wysiwyg'
import draggable from 'vuedraggable'

export default {
  name: 'CreateEditAccordion',
  created() {
    if (this.$route.params.id) {
      this.pageTitle = 'Edit Accordion'
      this.loadData()
    }
  },
  beforeRouteLeave() {
    this.$store.commit('AdminAccordions/setActive', {
      name: '',
      content: []
    })
  },
  data: () => ({
    pageTitle: 'Create Accordion',
    confirm: false,
    activeIndex: null
  }),
  methods: {
    getHTML() {
      const accordion = document.querySelector('#accordion')
      return accordion.outerHTML
    },
    addSection() {
      this.$store.commit('AdminAccordions/addSection')
      this.activeIndex = this.accordion.content.length - 1
    },
    removeSection(index) {
      this.$store.commit('AdminAccordions/removeSection', index)
      this.activeIndex = null
    },
    toggleActive(index) {
      this.activeIndex = this.activeIndex !== index ? index : null
    },
    submit() {
      if (this.editing) {
        let params = {}
        for (const prop in this.accordion) {
          if (
            !this.$_.isEqual(this.accordion[prop], this.original[prop]) &&
            prop !== 'html'
          ) {
            params[prop] = this.accordion[prop]
          }
        }
        if (params?.content) {
          params.html = this.getHTML()
        }
        if (Object.keys(params).length) {
          params.id = this.$route.params.id
          this.$store.dispatch('AdminAccordions/update', params)
        }
      } else {
        this.$store.dispatch('AdminAccordions/create', this.getHTML())
      }
    },
    confirmed(confirmed) {
      if (confirmed) {
        this.$store.dispatch('AdminAccordions/delete', this.$route.params.id)
      }
      this.confirm = false
    },
    async remove() {
      this.confirm = true
    },
    cancel() {
      this.$router.push({ name: 'AccordionsIndex' })
    },
    async loadData() {
      if (this.editing) {
        await this.$store.dispatch(
          'AdminAccordions/fetchOne',
          this.$route.params.id
        )
        this.original = this.$_.cloneDeep(this.accordion)
      }
    }
  },
  computed: {
    accordion() {
      return this.$store.getters['AdminAccordions/active']
    },
    editing() {
      return !!this.$route.params.id
    },
    errors() {
      return this.$store.getters.getErrors
    }
  },
  components: {
    ChevronUpIcon,
    ConfirmDialog,
    CSSAccordion,
    draggable,
    MenuIcon,
    TrashIcon,
    Wysiwyg
  }
}
</script>
