<template>
  <div class="mx-auto max-w-7xl px-4 pt-2 pb-6 sm:px-6 md:px-8 md:py-6">
    <h1 class="mb-6 text-2xl font-semibold text-gray-900">Menus</h1>
    <MenuSelector class="mb-4" />
    <Input v-model="active.name" label="Menu Name" v-if="active" />
    <div class="flex items-start">
      <MenuItemEditor
        class="w-1/3 rounded-md border bg-gray-600 text-white"
        v-if="showItemEditor"
        :mode="editorMode"
        @close="closeEditor"
      />
      <MenuEditor class="flex-1" @openEditor="openEditor" v-if="active" />
    </div>
  </div>
</template>

<script>
import MenuSelector from '@/adminInterface/components/menu/MenuSelector'
import MenuEditor from '@/adminInterface/components/menu/MenuEditor'
import MenuItemEditor from '@/adminInterface/components/menu/MenuItemEditor'
export default {
  created() {
    this.$store.dispatch('AdminPages/getAll')
    this.$store.dispatch('AdminMenus/getMenus')
  },
  title: 'Menus - iKTA',
  name: 'MenuManager',
  data: () => ({
    showItemEditor: false,
    editorMode: 'create'
  }),
  components: {
    MenuSelector,
    MenuItemEditor,
    MenuEditor
  },
  methods: {
    openEditor(value) {
      this.showItemEditor = true
      if (value) this.editorMode = value
    },
    closeEditor() {
      this.showItemEditor = false
    }
  },
  computed: {
    active() {
      return this.$store.getters['AdminMenus/getActive']
    },
    activeItem() {
      return this.$store.getters['AdminMenus/activeItem']
    }
  },
  watch: {
    activeItem(value) {
      if (value.menuType) {
        this.openEditor('edit')
      }
    }
  }
}
</script>
