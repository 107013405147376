<template>
  <div class="sticky top-4 my-16 mr-6">
    <div class="rounded-t-md bg-gray-800 p-4 font-bold">
      {{ actionType }} Menu Item
    </div>
    <div class="p-4">
      <Select
        v-model="activeItem.menuType"
        :options="menuTypes"
        optionsValue="key"
        optionsKey="key"
        optionsDisplay="value"
        label="Menu Type"
      />
      <Select
        v-model="activeItem.url"
        :options="options"
        :optionsValue="activeItem.menuType === 'page' ? 'slug' : 'url'"
        :optionsKey="activeItem.menuType === 'page' ? 'slug' : 'url'"
        :optionsDisplay="activeItem.menuType === 'page' ? 'title' : 'name'"
        :label="optionsLabel"
        v-if="['page', 'component'].includes(activeItem.menuType)"
      />
      <form @submit.prevent="handle">
        <Input
          v-model="activeItem.name"
          label="Display Name"
          aria-label="Display Name"
        />
        <Input
          v-if="activeItem.menuType !== 'text'"
          v-model="activeItem.url"
          label="Link URL"
          :readonly="['page', 'component'].includes(activeItem.menuType)"
        />
        <Checkbox
          v-model="activeItem.newTab"
          label="Open link in new tab"
          name="newItem"
          bgDark
        />
        <Checkbox
          class="mt-2"
          v-if="activeItem.menuType === 'link'"
          v-model="activeItem.external"
          label="External Link"
          name="external"
          bgDark
        />
        <div class="mt-4 flex justify-end">
          <button
            type="submit"
            class="inline-flex items-center rounded-md border border-transparent bg-gray-100 px-3 py-2 text-sm font-medium leading-4 text-gray-700 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
          >
            {{ mode === 'edit' ? 'Update' : 'Add' }}
          </button>
          <button
            @click.prevent="cancel"
            class="ml-2 text-sm italic text-red-400 underline hover:text-red-600 focus:outline-none"
          >
            Cancel
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MenuItemEditor',
  props: {
    mode: {
      type: String,
      required: false,
      default: 'create'
    }
  },
  data: () => ({
    components: [
      {
        name: 'Employee Opportunities',
        url: '/opportunities'
      },
      {
        name: 'Employee Manual',
        url: '/policies'
      },
      {
        name: 'Employee Directory',
        url: '/employee-directory'
      },
      {
        name: 'Employee Positions',
        url: '/employee-positions'
      }
    ],
    menuTypes: [
      {
        key: 'page',
        value: 'Pages'
      },
      {
        key: 'component',
        value: 'Components'
      },
      {
        key: 'link',
        value: 'Custom Links'
      },
      {
        key: 'text',
        value: 'Text Only'
      }
    ]
  }),
  methods: {
    cancel() {
      this.$emit('close')
      this.$store.commit('AdminMenus/cancelItem')
    },
    handle() {
      if (this.mode === 'create') {
        this.$store.commit('AdminMenus/addItem')
      } else {
        this.$store.commit('AdminMenus/updateItem')
      }
      this.$emit('close')
    }
  },
  computed: {
    optionsLabel() {
      if (this.activeItem.menuType === 'page') return 'Pages'
      if (this.activeItem.menuType === 'component') return 'Components'
      return ''
    },
    options() {
      if (this.activeItem.menuType === 'page') {
        return this.pages
      }
      if (this.activeItem.menuType === 'component') {
        return this.components
      }
      return []
    },
    pages() {
      return this.$store.getters['AdminPages/published']
      // .filter((page) => {
      //   const found = this.activeItems.find((item) => item.url === page.slug)
      //   if (page === this.page) return page
      //   if (!found) return page
      // })
    },
    activeItem() {
      return this.$store.getters['AdminMenus/activeItem']
    },
    actionType() {
      return this.mode === 'edit' ? 'Update' : 'Add'
    }
  }
}
</script>
