import actions from './actions'
import mutations from './mutations'
import getters from './getters'

export default {
  namespaced: true,
  state: () => ({
    accordions: [],
    active: {
      name: '',
      content: []
    }
    // count: 0,
    // offset: 0,
    // limit: 10,
    // filter: null,
    // filterOptions: [
    //   { key: 'published', value: 'Published' },
    //   { key: 'unpublished', value: 'Unpublished' }
    // ],
    // order: 'ASC',
    // orderBy: { key: 'title', value: 'Title', type: 'string' },
    // orderOptions: [
    //   { key: 'title', value: 'Title', type: 'string' },
    //   { key: 'published', value: 'Published', type: 'date' },
    //   { key: 'created', value: 'Created', type: 'date' }
    // ],
    // page: 1
  }),
  mutations,
  getters,
  actions
}
