import { createStore } from 'vuex'

import mutations from './mutations'
import getters from './getters'
import actions from './actions'

// Client Modules
import Directory from '@/clientInterface/modules/Directory'
import Opportunities from '@/clientInterface/modules/Opportunities'
import Pages from '@/clientInterface/modules/Pages'
import Policies from '@/clientInterface/modules/Policies'
import Positions from '@/clientInterface/modules/Positions'
import Posts from '@/clientInterface/modules/Posts'
import User from '@/clientInterface/modules/User'
import Menus from '@/clientInterface/modules/Menus'
import Tags from '@/clientInterface/modules/Tags'

// Admin Modules
import Media from '@/adminInterface/modules/Media'
import AdminAccordions from '@/adminInterface/modules/Accordions'
import AdminMenus from '@/adminInterface/modules/Menus'
import AdminPages from '@/adminInterface/modules/Pages'
import AdminPosts from '@/adminInterface/modules/Posts'
import AdminPolicies from '@/adminInterface/modules/Policies'
import AdminPositions from '@/adminInterface/modules/Positions'
import AdminTags from '@/adminInterface/modules/Tags'
import AdminUsers from '@/adminInterface/modules/Users'

export default createStore({
  state: {
    config: {
      latestNewsCount: 5,
      directory: {
        title: '',
        subtitle: '',
        ctaButtonText: '',
        ctaLink: '',
        image: ''
      },
      home: {
        title: '',
        subtitle: '',
        ctaButtonText: '',
        ctaLink: '',
        image: ''
      },
      opportunities: {
        title: '',
        subtitle: '',
        ctaButtonText: '',
        ctaLink: '',
        image: ''
      },
      positions: {
        title: '',
        subtitle: '',
        ctaButtonText: '',
        ctaLink: '',
        image: ''
      },
      mainNavMenu: ''
    },
    errors: null,
    menu: false,
    alert: {
      level: 'info',
      title: '',
      text: '',
      timer: null
    },
    headerInfo: {
      image: '',
      title: '',
      subtitle: '',
      ctaButtonText: '',
      ctaLink: ''
    },
    months: [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December'
    ]
  },
  getters,
  mutations,
  actions,
  modules: {
    Directory,
    Opportunities,
    Pages,
    Posts,
    Policies,
    Positions,
    User,
    Menus,
    Tags,
    Media,
    AdminAccordions,
    AdminMenus,
    AdminPages,
    AdminPosts,
    AdminPolicies,
    AdminPositions,
    AdminTags,
    AdminUsers
  }
})
