<template>
  <div :class="[inlineLabel ? 'flex items-center' : '', 'mb-5']">
    <label
      class="mb-1 mr-2 block whitespace-nowrap text-sm font-medium leading-5"
      v-if="label"
      >{{ label }}</label
    >
    <div class="relative mr-4 w-full rounded-md shadow-sm">
      <select
        v-model="selectOption"
        class="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base text-gray-700 focus:outline-none sm:text-sm"
        :class="[
          error
            ? 'rounded-md border-red-300 pr-10 text-red-900 placeholder-red-300 focus:border-red-500 focus:outline-none focus:ring-red-500'
            : 'focus:border-blue-500 focus:ring-blue-500'
        ]"
      >
        <option :value="null || false || ''" disabled selected>
          Select Option
        </option>
        <option
          v-for="(option, index) in options"
          :value="option[optionsValue] || option"
          :key="index"
        >
          {{ option[optionsDisplay] || option }}
        </option>
      </select>
      <div
        class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3"
        v-if="error"
      >
        <svg
          class="h-5 w-5 text-red-500"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
          aria-hidden="true"
        >
          <path
            fill-rule="evenodd"
            d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
            clip-rule="evenodd"
          />
        </svg>
      </div>
    </div>
    <p class="mt-1 text-sm text-red-600" v-if="error">{{ error }}</p>
  </div>
</template>

<script>
export default {
  name: 'Select',
  props: {
    modelValue: {
      type: [String, Number, Object]
    },
    inlineLabel: {
      type: Boolean,
      required: false,
      default: false
    },
    options: {
      type: Array,
      required: true
    },
    label: {
      type: String,
      required: false
    },
    optionsValue: {
      type: String,
      required: false
    },
    optionsKey: {
      type: String,
      required: false
    },
    optionsDisplay: {
      type: String,
      required: false
    },
    error: {
      type: String
    }
  },
  computed: {
    selectOption: {
      get() {
        return this.modelValue
      },
      set(value) {
        this.$emit('update:modelValue', value)
      }
    }
  }
}
</script>
