import { format } from 'date-fns'

export default {
  get(state) {
    return state.pages.map((page) => {
      page.created = format(new Date(page.created), 'MM/dd/yyyy h:mm a')
      page.updated = format(new Date(page.updated), 'MM/dd/yyyy h:mm a')
      if (page.published)
        page.published = format(new Date(page.published), 'MM/dd/yyyy h:mm a')
      return page
    })
  },
  count(state) {
    return state.count
  },
  offset(state) {
    return state.offset
  },
  limit(state) {
    return state.limit
  },
  page(state) {
    return state.page
  },
  from(state) {
    return state.offset + 1
  },
  to(state) {
    return state.limit > state.count
      ? state.count
      : state.limit * state.page > state.count
      ? state.count
      : state.limit * state.page
  },
  filter(state) {
    return state.filter
  },
  filterOptions(state) {
    return state.filterOptions
  },
  order(state) {
    return state.order
  },
  orderBy(state) {
    return state.orderBy
  },
  orderOptions(state) {
    return state.orderOptions
  },
  published(state) {
    return state.pages.filter((page) => page.published)
  }
}
