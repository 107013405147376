<template>
  <div class="pt-2 pb-6 md:py-6">
    <div class="mx-auto mb-6 max-w-7xl px-4 sm:px-6 md:px-8">
      <h1 class="text-2xl font-semibold text-gray-900">Policies</h1>
    </div>
    <DataControls view="AdminPolicies" />
    <div class="mx-auto max-w-7xl px-4 sm:px-6 md:px-8">
      <div class="mb-4 flex items-center justify-between">
        <Paginator
          :from="from"
          :to="to"
          :total="totalPolicies"
          position="top"
          @next="next"
          @prev="prev"
          hideButtons
          v-show="totalPolicies"
        />
        <div class="flex items-center">
          <SectionManager />
          <span class="ml-2 inline-flex rounded-md shadow-sm">
            <router-link
              :to="{ name: 'PoliciesCreate' }"
              tag="button"
              class="focus:shadow-outline-gray inline-flex items-center rounded-md border border-transparent bg-gray-600 px-4 py-2 text-sm font-medium leading-5 text-white transition duration-150 ease-in-out hover:bg-gray-500 focus:border-gray-700 focus:outline-none active:bg-gray-700"
            >
              Create
            </router-link>
          </span>
        </div>
      </div>

      <Policies :policies="formatted" />
      <Paginator
        class="border-b border-gray-200"
        :from="from"
        :to="to"
        :total="totalPolicies"
        @next="next"
        @prev="prev"
        v-show="totalPolicies"
      />
    </div>
    <div
      class="mx-auto my-4 flex max-w-7xl justify-between px-4 sm:px-6 md:px-8"
    >
      <div class="flex items-center">
        <label
          for="items"
          class="mr-2 block text-sm font-medium leading-5 text-gray-700 sm:mt-px"
        >
          items
        </label>
        <div class="mt-1 sm:col-span-2 sm:mt-0">
          <div class="max-w-lg rounded-md shadow-sm sm:max-w-xs">
            <select
              id="items"
              class="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
            >
              <option>10</option>
              <option>20</option>
              <option>50</option>
            </select>
          </div>
        </div>
      </div>
      <div class="flex items-center space-x-3">
        <SectionManager />
        <span class="inline-flex rounded-md shadow-sm">
          <router-link
            :to="{ name: 'PoliciesCreate' }"
            tag="button"
            class="focus:shadow-outline-gray inline-flex items-center rounded-md border border-transparent bg-gray-600 px-4 py-2 text-sm font-medium leading-5 text-white transition duration-150 ease-in-out hover:bg-gray-500 focus:border-gray-700 focus:outline-none active:bg-gray-700"
          >
            Create
          </router-link>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import DataControls from '@/adminInterface/components/utilities/DataControls'
import Paginator from '@/adminInterface/components/utilities/Paginator'
import Policies from '@/adminInterface/components/policies/Policies'
import SectionManager from '@/adminInterface/components/policies/SectionManager'
export default {
  title: 'Policies - iKTA',
  name: 'PoliciesIndex',
  components: { DataControls, Paginator, Policies, SectionManager },
  created() {
    if (!this.$store.getters['AdminPolicies/get'].length)
      this.$store.dispatch('AdminPolicies/getAll')
  },
  methods: {
    update(value) {
      this.results = value
    },
    next() {
      if (this.to < this.totalPolicies) {
        this.$store.commit('AdminPolicies/nextPage')
        this.$store.dispatch('AdminPolicies/getAll')
      }
    },
    prev() {
      if (this.offset > 0) {
        this.$store.commit('AdminPolicies/prevPage')
        this.$store.dispatch('AdminPolicies/getAll')
      }
    }
  },
  computed: {
    ...mapGetters({
      page: 'AdminPolicies/page',
      offset: 'AdminPolicies/offset',
      errors: 'getErrors',
      totalPolicies: 'AdminPolicies/count',
      to: 'AdminPolicies/to',
      from: 'AdminPolicies/from',
      currentFilter: 'AdminPolicies/filter'
    }),
    policies() {
      return this.$store.getters['AdminPolicies/get']
    },
    itemsPerPage: {
      get() {
        return this.$store.getters['AdminPolicies/limit']
      },
      set(value) {
        this.$store.commit('AdminPolicies/setLimit', value)
      }
    },
    formatted() {
      return this.policies.map((policy) => {
        policy.effective = this.$date.format(
          new Date(policy.effective),
          'MM/dd/yyyy'
        )
        return policy
      })
    }
  },
  watch: {
    itemsPerPage(value) {
      this.$store.commit('AdminPolicies/setLimit', value)
      this.$store.dispatch('AdminPolicies/getAll')
    }
  }
}
</script>
