import actions from './actions'
import mutations from './mutations'
import getters from './getters'

export default {
  namespaced: true,
  state: () => ({
    pinned: null,
    latest: [],
    news: {
      posts: [],
      count: 0,
      offset: 0,
      limit: 6,
      page: 1
    },
    archives: [],
    archive: []
  }),
  mutations,
  getters,
  actions
}
