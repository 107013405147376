<template>
  <HeaderNav :header="headerRef" v-if="!isAdmin && loggedIn" />
  <ProfileMenu class="fixed top-0 right-0 z-30 mr-3 mt-3" />
  <Header @headerRef="setHeader" v-if="!isAdmin" />
  <router-view />
  <Footer v-if="!isAdmin" />
  <Alert />
</template>
<script>
import Alert from '@/utilities/Alert'
import ProfileMenu from '@/utilities/ProfileMenu'
import Footer from '@/clientInterface/components/layout/Footer'
import Header from '@/clientInterface/components/layout/Header'
import HeaderNav from '@/clientInterface/components/layout/HeaderNav'

export default {
  async created() {
    this.$store.dispatch('getConfig')
  },
  data: () => ({
    headerRef: null
  }),
  components: { Alert, Footer, Header, HeaderNav, ProfileMenu },
  computed: {
    config() {
      return this.$store.getters.config
    },
    loggedIn() {
      return this.$store.getters['User/loggedIn']
    },
    accessToken() {
      return this.$store.getters['User/accessToken']
    },
    refreshToken() {
      return this.$store.getters['User/refreshToken']
    },
    userId() {
      return this.$store.getters['User/id']
    },
    isAdmin() {
      return this.$route.matched[0]?.name === 'Admin'
    }
  },
  methods: {
    setHeader(value) {
      this.headerRef = value
    }
  }
}
</script>
<style>
img {
  image-rendering: -webkit-optimize-contrast;
}

.accordion div:last-of-type .box-title {
  margin-bottom: -1px;
}

.box-content > p {
  margin-bottom: 1rem;
}

/* .box-content ul {
  list-style: none;
  margin-bottom: 1rem;
  margin-left: 1rem;
} */

input:checked + .box .box-content,
input:checked + .box .box-close {
  display: inline-block;
}

input:checked + section.box .box-title .arrow {
  transform: rotate(90deg);
}

.page-body > p {
  margin-bottom: 1rem;
}

.content {
  --link-text-color: rgba(33, 94, 159);
  --link-text-color-hover: rgba(36, 69, 130);
}

.content h1 {
  font-family: 'Cabin', sans-serif;
  line-height: 1;
  font-weight: bold;
  font-size: 1.375rem;
  margin-bottom: 0.5rem;
}
.content h2 {
  line-height: 1;
  margin-bottom: 0.5rem;
  font-weight: bold;
  font-size: 1rem;
}
.content h3 {
  font-family: 'Arimo', sans-serif;
  line-height: 1;
  margin-bottom: 0.5rem;
  font-size: 0.8125rem;
  text-transform: uppercase;
}
.content h4 {
  line-height: 1;
  font-weight: bold;
  margin-bottom: 0.5rem;
  font-size: 0.8125rem;
}
.content h5 {
  line-height: 1;
  font-weight: bold;
  margin-bottom: 0.5rem;
  font-size: 0.75rem;
}
.content h6 {
  line-height: 1;
  font-weight: bold;
  margin-bottom: 0.5rem;
  font-size: 0.625rem;
}

.content a {
  color: var(--link-text-color);
  text-decoration: underline;
}
.content a:hover {
  color: var(--link-text-color-hover);
}

.content > p {
  margin-bottom: 1rem;
}

.content ol {
  list-style: decimal;
  margin-left: 2rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.content ul {
  list-style-type: disc;
  margin-left: 2rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
.content li p {
  position: inline;
}

.content table {
  font-size: 0.9rem;
}

.content table td {
  border: 1px solid #ccc;
  min-width: 5rem;
  padding: 0.25rem 0.5rem;
}
.content table th {
  border: 1px solid #333333;
  font-weight: 600;
  min-width: 5rem;
  padding: 0.25rem 0.5rem;
}
</style>
