import axios from 'axios'

export default {
  async getAll({ commit, rootGetters }) {
    try {
      const { data } = await axios.get(
        `${process.env.VUE_APP_API_URL}/tags/published/`,
        rootGetters['User/config']
      )
      commit('set', data)
      return data
    } catch (error) {
      commit('handleErrors', error.response.data, { root: true })
    }
  },
  async getTag({ state, commit, rootGetters }, tag) {
    commit('setCurrentPage', { component: 'tag' })
    try {
      const query = state.tag.offset
        ? `&limit=${state.tag.limit}&offset=${state.tag.offset}`
        : `&limit=${state.tag.limit}`

      const { data } = await axios.get(
        `${process.env.VUE_APP_API_URL}/tags/posts?tag=${tag}${query}`,
        rootGetters['User/config']
      )
      commit('setTag', data)
      return data
    } catch (error) {
      commit('handleErrors', error.response.data, { root: true })
    }
  }
}
