<template>
  <div
    class="flex flex-1 items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8"
  >
    <div class="w-full max-w-md space-y-8">
      <div>
        <h2 class="mt-6 text-center text-3xl font-extrabold text-kta-blue-900">
          Create New Account
        </h2>
        <div class="mt-6 flex items-center justify-center">
          <div class="relative flex w-1/2 flex-col items-center">
            <span
              class="absolute top-5 left-12 w-full border-t-2 border-gray-400"
            ></span>
            <div
              class="relative mb-2 flex h-10 w-10 items-center justify-center rounded-full text-white ring-8 ring-gray-50 transition duration-200"
              :class="[verified ? 'bg-green-500' : 'bg-kta-blue-700']"
            >
              <span v-if="!verified">1</span>
              <span class="h-5 w-5" v-else>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M5 13l4 4L19 7"
                  />
                </svg>
              </span>
            </div>
            <div
              class="font-semibold"
              :class="[verified ? 'text-gray-400' : 'text-gray-700']"
            >
              Employee Verification
            </div>
          </div>
          <div class="relative flex w-1/2 flex-col items-center">
            <span
              class="absolute top-5 right-12 w-full border-t-2 border-gray-400"
            ></span>
            <div
              class="relative mb-2 flex h-10 w-10 items-center justify-center rounded-full text-white ring-8 ring-gray-50 transition duration-200"
              :class="[verified ? 'bg-kta-blue-700' : 'bg-gray-400']"
            >
              2
            </div>
            <div
              class="font-semibold"
              :class="[verified ? 'text-gray-700' : 'text-gray-400']"
            >
              Account Registration
            </div>
          </div>
        </div>
      </div>
      <transition
        enter-active-class="transition ease-out duration-200"
        enter-from-class="opacity-0"
        enter-to-class="opacity-100"
        leave-active-class="transition ease-in duration-200"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0"
        mode="out-in"
      >
        <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
            <form
              id="verification"
              class="space-y-4"
              @submit.prevent="verify"
              v-if="!verified"
            >
              <Input
                v-model="form.id"
                label="Employee ID"
                :error="errors.id"
                errorKey="id"
              />
              <Input
                v-model="form.dob"
                helpText="YYYY-MM-DD"
                :mask="'####-##-##'"
                label="Birth Date"
                :error="errors.dob"
                errorKey="dob"
              />
              <div>
                <button
                  :disabled="disabled"
                  type="submit"
                  class="flex w-full justify-center rounded-md border border-transparent py-2 px-4 text-sm font-medium text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-kta-blue-500 focus:ring-offset-2"
                  :class="[
                    disabled
                      ? 'cursor-not-allowed bg-gray-400'
                      : 'bg-kta-blue-600 hover:bg-kta-blue-700'
                  ]"
                >
                  Verify
                </button>
              </div>
            </form>
            <form
              id="registration"
              class="mt-8 space-y-6"
              @submit.prevent="register"
              v-else
            >
              <div>
                <label class="mb-1 block text-sm font-medium text-gray-700"
                  >Employee ID</label
                >
                <div
                  class="relative block w-full cursor-not-allowed rounded-md border border-gray-300 bg-gray-100 px-3 py-2 shadow-sm sm:text-sm"
                >
                  <span>{{ form.id }}</span>
                </div>
              </div>
              <Input
                v-model="form.password"
                type="password"
                label="Password"
                :error="errors.password"
                errorKey="password"
              />
              <Input
                v-model="form.confirmPassword"
                type="password"
                label="Confirm Password"
                :error="errors.confirmPassword"
                errorKey="confirmPassword"
              />
              <div class="flex items-center justify-between space-x-3">
                <Input
                  class="flex-1"
                  v-model="form.firstName"
                  label="First Name"
                  :error="errors.firstName"
                  errorKey="firstName"
                />
                <Input
                  class="flex-1"
                  v-model="form.lastName"
                  label="Last Name"
                  :error="errors.lastName"
                  errorKey="lastName"
                />
              </div>
              <Input
                v-model="form.ktaEmail"
                label="Work Email"
                readonly
                :error="errors.ktaEmail"
                v-if="form.ktaEmail"
                errorKey="ktaEmail"
              />
              <Input
                v-model="form.personalEmail"
                :label="form.ktaEmail ? 'Personal Email (optional)' : 'Email'"
                :error="errors.personalEmail"
                errorKey="personalEmail"
              />
              <div class="flex items-start justify-between space-x-3">
                <Input
                  class="flex-1"
                  v-model="form.ktaMobile"
                  readonly
                  label="Work Cell"
                  :error="errors.ktaMobile"
                  mask="(###)###-####"
                  errorKey="ktaMobile"
                />
                <Input
                  class="flex-1"
                  v-model="form.personalMobile"
                  :label="
                    form.ktaMobile
                      ? 'Personal Cell (optional)'
                      : 'Cell (Optional)'
                  "
                  mask="(###)###-####"
                  :error="errors.personalMobile"
                  errorKey="personalMobile"
                />
              </div>
              <div>
                <button
                  :disabled="disabled"
                  type="submit"
                  class="flex w-full justify-center rounded-md border border-transparent py-2 px-4 text-sm font-medium text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-kta-blue-500 focus:ring-offset-2"
                  :class="[
                    disabled
                      ? 'cursor-not-allowed bg-gray-400'
                      : 'bg-kta-blue-600 hover:bg-kta-blue-700'
                  ]"
                >
                  Register
                </button>
              </div>
            </form>
            <div class="mt-6 text-center text-sm">
              Already registered?
              <router-link
                :to="{ name: 'Login' }"
                class="font-medium text-kta-blue-600 hover:text-kta-blue-500"
                >Log In</router-link
              >
            </div>
            <div class="mt-4 text-center text-xs text-gray-800">
              <p class="mb-2">
                Your employee ID can be found on your KTA badge. No badge?
                Please contact your supervisor for your ID.
              </p>
              <p>
                Issues registering or logging in?
                <a
                  class="font-medium text-kta-blue-600 hover:text-kta-blue-500"
                  href="mailto:iktasupport@ksturnpike.com"
                  target="_blank"
                  >Report an issue</a
                >
              </p>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Registration',
  data() {
    return {
      form: {
        id: '',
        dob: '',
        password: '',
        firstName: '',
        lastName: '',
        ktaEmail: '',
        ktaMobile: '',
        personalEmail: '',
        personalMobile: ''
      },
      verified: false,
      activeRequest: false
    }
  },
  computed: {
    disabled() {
      return false
    },
    errors() {
      return this.$store.getters.getErrors
    }
  },
  methods: {
    async verify() {
      this.activeRequest = true
      const response = await this.$store.dispatch('User/verify', {
        id: this.form.id,
        dob: this.form.dob
      })
      if (response) {
        if (response.ktaEmail) {
          this.form.ktaEmail = response.ktaEmail
        }
        if (response.ktaMobile) {
          this.form.ktaMobile = response.ktaMobile
        }
        this.form.firstName = response.firstName
        this.form.lastName = response.lastName
        this.verified = true
      }
      this.activeRequest = false
    },
    async register() {
      this.activeRequest = true
      const form = {}
      for (const prop in this.form) {
        if (this.form[prop]) {
          form[prop] = prop.includes('Mobile')
            ? this.form[prop].replace(/[()-]/g, '')
            : this.form[prop]
        }
      }
      const response = await this.$store.dispatch('User/register', form)
      if (response) {
        this.resetForm()
        this.$router.push({ name: 'Login' })
        this.$store.commit('displayAlert', {
          position: 'top',
          level: 'info',
          text: 'Registration Successful',
          timer: 3000
        })
      }
      this.activeRequest = false
    },
    resetForm() {
      this.form = {
        id: '',
        dob: '',
        password: '',
        firstName: '',
        lastName: '',
        ktaEmail: '',
        ktaMobile: '',
        personalEmail: '',
        personalMobile: ''
      }
    }
  },
  watch: {
    errors: {
      handler(value) {
        if (value.message) {
          this.$store.commit('displayAlert', {
            position: 'top',
            level: 'error',
            text: value.message,
            timer: 3000
          })
        }
      },
      deep: true
    }
  }
}
</script>
