<template>
  <div class="relative mr-4 flex flex-1 items-center">
    <label class="mr-2 text-sm font-medium leading-5">Search</label>
    <input
      type="search"
      v-model="searchInput"
      class="relative z-0 block w-full rounded-md border-gray-300 shadow-sm focus:border-transparent focus:ring-2 focus:ring-kta-blue-500 sm:text-sm"
      aria-label="Search"
      @input="checkActive"
      @blur="deactivate"
      @keydown.exact.prevent.up="scrollUp"
      @keydown.exact.prevent.down="scrollDown"
      @keydown.exact.prevent.enter="selectItem"
      @keyup.exact.escape="deactivate"
    />
    <div
      class="absolute top-12 left-14 right-0 max-h-128 overflow-x-scroll rounded-md border border-gray-300 bg-white text-sm"
      v-if="active"
    >
      <div v-for="(section, sectionIdx) in sections" :key="sectionIdx">
        <div class="bg-kta-blue-600 px-3 py-1 text-white">
          {{ upperFirst(sectionIdx) }}
        </div>
        <ul>
          <li
            v-for="(item, itemIdx) in sections[sectionIdx]"
            :key="item.id"
            :class="[
              activeItem.section === sectionIdx &&
              activeItem.index === itemIdx &&
              activeItem.id === item.id
                ? 'cursor-pointer bg-gray-200'
                : '',
              'flex items-center justify-between px-3 leading-8'
            ]"
            @mousedown="goto(item)"
            @mouseover="
              activeItem = { section: sectionIdx, id: item.id, index: itemIdx }
            "
          >
            <span>{{ item.firstName }} {{ item.lastName }}</span>
            <span>{{ item.title }}</span> <span>{{ item.location }}</span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { upperFirst } from 'lodash'
export default {
  name: 'DirectorySearch',
  props: {
    items: {
      type: Array,
      required: true
    }
  },
  data: () => ({
    searchInput: '',
    active: false,
    activeItem: {
      section: null,
      id: null,
      index: null
    }
  }),
  computed: {
    name() {
      return this.items
        .filter((item) => {
          return `${item.firstName} ${item.lastName}`
            .toLowerCase()
            .includes(this.searchInput.toLowerCase())
            ? true
            : false
        })
        .slice(0, 10)
    },
    location() {
      return this.items
        .filter((item) => {
          return item.location
            .toLowerCase()
            .includes(this.searchInput.toLowerCase())
            ? true
            : false
        })
        .slice(0, 10)
    },
    title() {
      return this.items
        .filter((item) => {
          return item.title
            .toLowerCase()
            .includes(this.searchInput.toLowerCase())
            ? true
            : false
        })
        .slice(0, 10)
    },
    sections() {
      let obj = {}
      if (this.name.length) obj.name = this.name
      if (this.location.length) obj.location = this.location
      if (this.title.length) obj.title = this.title
      return obj
    }
  },
  methods: {
    upperFirst(value) {
      return upperFirst(value)
    },
    goto(item) {
      this.$emit('goto', item.id)
      this.$nextTick(() => {
        this.deactivate()
      })
    },
    selectItem() {
      this.$emit('goto', this.activeItem.id)
      this.active = false
    },
    scrollUp() {
      if (this.active) {
        if (
          (!this.activeItem.index && !this.activeItem.section) ||
          this.activeItem.index === 0
        ) {
          this.activeItem.section = this.prevSection()
          this.activeItem.index =
            this.sections[this.activeItem.section].length - 1
          return
        }
        if (this.activeItem.index > 0) {
          this.activeItem.index--
        }
      } else {
        this.checkActive()
      }
    },
    scrollDown() {
      if (this.active) {
        if (
          (!this.activeItem.index && !this.activeItem.section) ||
          this.activeItem.index ===
            this.sections[this.activeItem.section].length - 1
        ) {
          this.activeItem.index = 0
          this.activeItem.section = this.nextSection()
          return
        }

        if (
          this.activeItem.index <
          this.sections[this.activeItem.section].length - 1
        ) {
          this.activeItem.index++
        }
      } else {
        this.checkActive()
      }
    },
    nextSection() {
      const sections = Object.keys(this.sections)
      if (!this.activeItem.section) {
        return sections[0]
      }
      const sectionIndex = sections.findIndex(
        (section) => section === this.activeItem.section
      )
      return sectionIndex < sections.length - 1
        ? sections[sectionIndex + 1]
        : sections[0]
    },
    prevSection() {
      const sections = Object.keys(this.sections)
      if (!this.activeItem.section) {
        return sections[sections.length - 1]
      }
      const sectionIndex = sections.findIndex(
        (section) => section === this.activeItem.section
      )
      return sectionIndex === 0
        ? sections[sections.length - 1]
        : sections[sectionIndex - 1]
    },
    deactivate() {
      this.active = false
    },
    checkActive() {
      if (this.searchInput) {
        this.active =
          this.name.length || this.location.length || this.title.length
            ? true
            : false
      }
    }
  },
  watch: {
    activeItem: {
      deep: true,
      handler(value) {
        this.activeItem.id = this.sections[value.section][value.index].id
      }
    }
  }
}
</script>
