<template>
  <div
    class="flex flex-1 items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8"
  >
    <div class="w-full max-w-md space-y-8">
      <h2 class="mt-6 text-center text-3xl font-extrabold text-kta-blue-900">
        Reset Password
      </h2>
      <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div
          class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10"
          v-show="$_.isEmpty(contactOptions)"
        >
          <form @submit.prevent="requestContactOptions">
            <Input
              v-model="id"
              label="Employee ID"
              :error="errors.id"
              errorKey="id"
            />
            <button
              type="submit"
              class="relative w-full rounded-md border border-transparent bg-kta-blue-700 py-2 px-4 text-center text-sm font-medium text-white hover:bg-kta-blue-800 focus:outline-none focus:ring-2 focus:ring-kta-blue-500 focus:ring-offset-2"
            >
              Submit
            </button>
          </form>
          <div class="mt-4 text-center text-xs text-gray-800">
            <p class="mb-2">
              Your employee ID can be found on your KTA badge. No badge? Please
              contact your supervisor for your ID.
            </p>
            <p>
              Issues registering or logging in?
              <a
                class="font-medium text-kta-blue-600 hover:text-kta-blue-500"
                href="mailto:iktasupport@ksturnpike.com"
                target="_blank"
                >Report an issue</a
              >
            </p>
          </div>
        </div>
        <div
          class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10"
          v-show="!$_.isEmpty(contactOptions) && !verificationSent"
        >
          <form @submit.prevent="sendVerification">
            <RadioGroup v-model="verificationMethod" class="mb-4">
              <RadioGroupLabel class="mb-1 block text-sm font-medium leading-5">
                Verification Delivery Options
              </RadioGroupLabel>
              <div class="space-y-4">
                <RadioGroupOption
                  as="template"
                  v-for="(option, key) in contactOptions"
                  :key="key"
                  :value="key"
                  v-slot="{ active, checked }"
                >
                  <div
                    :class="[
                      active ? 'ring-1 ring-kta-blue-500 ring-offset-2' : '',
                      'relative block cursor-pointer rounded-lg border border-gray-300 bg-gray-50 px-6 py-4 shadow-sm hover:border-gray-400 focus:outline-none sm:flex sm:justify-between'
                    ]"
                  >
                    <div class="text-sm">
                      <RadioGroupLabel as="p" class="font-medium text-gray-900">
                        {{ option }}
                      </RadioGroupLabel>
                      <RadioGroupDescription as="div" class="text-gray-500">
                        {{ option.includes('@') ? 'Email' : 'Text Message' }}
                      </RadioGroupDescription>
                    </div>
                    <div
                      :class="[
                        checked ? 'border-kta-blue-500' : 'border-transparent',
                        'pointer-events-none absolute -inset-px rounded-lg border-2'
                      ]"
                      aria-hidden="true"
                    />
                  </div>
                </RadioGroupOption>
              </div>
            </RadioGroup>
            <button
              type="submit"
              class="relative w-full rounded-md border border-transparent bg-kta-blue-700 py-2 px-4 text-center text-sm font-medium text-white hover:bg-kta-blue-800 focus:outline-none focus:ring-2 focus:ring-kta-blue-500 focus:ring-offset-2"
            >
              Send
            </button>
          </form>
        </div>
        <div
          class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10"
          v-show="verificationSent"
        >
          <form @submit.prevent="updatePassword">
            <Input
              v-model="verificationCode"
              label="Verification Code"
              :error="errors.verificationCode"
              errorKey="id"
            />
            <Input
              v-model="password"
              label="New Password"
              type="password"
              :error="errors.password"
              errorKey="password"
            />
            <Input
              v-model="confirmPassword"
              label="Confirm Password"
              type="password"
              :error="errors.confirmPassword"
              errorKey="confirmPassword"
            />
            <button
              type="submit"
              class="relative w-full rounded-md border border-transparent bg-kta-blue-700 py-2 px-4 text-center text-sm font-medium text-white hover:bg-kta-blue-800 focus:outline-none focus:ring-2 focus:ring-kta-blue-500 focus:ring-offset-2"
            >
              Submit
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  RadioGroup,
  RadioGroupDescription,
  RadioGroupLabel,
  RadioGroupOption
} from '@headlessui/vue'

export default {
  name: 'PasswordReset',
  title() {
    return 'KTA - Password Reset'
  },
  beforeRouteLeave(to, from, next) {
    this.$store.commit('User/clearPasswordReset')
    next()
  },
  data: () => ({
    id: '',
    verificationSent: false,
    verificationMethod: null,
    verificationCode: '',
    password: '',
    confirmPassword: ''
  }),
  components: {
    RadioGroup,
    RadioGroupDescription,
    RadioGroupLabel,
    RadioGroupOption
  },
  computed: {
    errors() {
      return this.$store.getters.getErrors
    },
    contactOptions() {
      return this.$store.getters['User/contactOptions']
    }
  },
  methods: {
    requestContactOptions() {
      this.$store.dispatch('User/fetchResetContactOptions', this.id)
    },
    async sendVerification() {
      this.verificationSent = await this.$store.dispatch(
        'User/sendVerificationCode',
        {
          id: this.id,
          contactMethod: this.verificationMethod
        }
      )
    },
    async updatePassword() {
      await this.$store.dispatch('User/resetPassword', {
        id: this.id.toString(),
        verificationCode: this.verificationCode,
        password: this.password,
        confirmPassword: this.confirmPassword
      })
    }
  },
  watch: {
    errors: {
      handler(value) {
        if (value.message) {
          this.$store.commit('displayAlert', {
            position: 'top',
            level: 'error',
            text: value.message,
            timer: 3000
          })
        }
      },
      deep: true
    }
  }
}
</script>
